import Component from "@glimmer/component";
import { action } from "@ember/object";
import { Task, task } from "ember-concurrency";
import { service } from "@ember/service";
import { arg } from "ember-arg-types";
import { instanceOf, shape } from "prop-types";
import server from "glesys-controlpanel/models/server";

export default class ModalsCreateManualBackup extends Component {
  @service modal;
  @service ajax;
  @service cost;

  @arg(
    shape({
      server: instanceOf(server).isRequired,
      task: instanceOf(Task).isRequired,
    }).isRequired,
  )
  params;

  get server() {
    return this.params.server;
  }

  get task() {
    return this.params.task;
  }

  get backupCost() {
    return this.cost.forManualBackup(this.server.disksize);
  }

  @action
  createBackup() {
    this.createBackupTask.perform();
  }

  createBackupTask = task(async () => {
    await this.task.perform(this.server);
    this.modal.close();
  });
}

import Component from "@glimmer/component";
import { action } from "@ember/object";
import { tracked } from "@glimmer/tracking";

export default class ColorPicker extends Component {
  colors = [
    "skyblue",
    "midnightblue",
    "lightcoral",
    "silver",
    "mediumaquamarine",
    "sandybrown",
    "lemonchiffon",
    "rebeccapurple",
  ];

  @tracked color = this.args.color || "silver";

  get onChange() {
    return this.args.onChange;
  }

  @action
  setColor(color) {
    this.color = color;
    this.onChange(color);
  }
}

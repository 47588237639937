import Model, { attr, belongsTo, hasMany } from "@ember-data/model";

export default class LoadBalancerModel extends Model {
  @attr cost;
  @attr datacenter;
  @attr managedhosting;
  @attr name;
  @attr ipv4;
  @attr ipv6;
  @attr ipaddress;

  @belongsTo("project", { async: false, inverse: "loadBalancers" }) project;
  @hasMany("load-balancer-certificate", { async: true, inverse: "loadBalancer" }) certificates;
  @hasMany("load-balancer-error", { async: true, inverse: "loadBalancer" }) loadBalancerErrors;
  @hasMany("reserved-ip", { async: true, inverse: null }) ips;
  @hasMany("load-balancer-listener", { async: false, inverse: "loadBalancer" }) listeners;

  get isManagedHosting() {
    return this.managedhosting === "yes";
  }

  get dirtyListeners() {
    return this.hasMany("listeners")
      .value()
      ?.filter((listener) => listener.isDirty);
  }

  get hasDirtyListeners() {
    return this.dirtyListeners?.length > 0;
  }

  get hasLegacyIp() {
    return Boolean(
      this.hasMany("ips")
        .value()
        ?.some((ip) => ip.platforms?.includes("OpenVZ") || ip.platforms?.includes("VMware")),
    );
  }
}

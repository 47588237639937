import { helper } from "@ember/component/helper";

export function includes([obj, el]) {
  if (typeof obj == "undefined" || obj == {} || obj == []) {
    return false;
  } else if (Array.isArray(obj)) {
    let arr = obj;
    return arr.includes(el);
  } else if (obj.constructor.name == "Set") {
    let set = obj;
    return set.has(el);
  } else {
    return false;
  }
}

export default helper(includes);

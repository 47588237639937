import Component from "@glimmer/component";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { arrayOf, number, shape } from "prop-types";
import { stringable } from "glesys-controlpanel/utils/prop-types";

let totalPropType = shape({
  total: shape({ withtax: number, withouttax: number }),
});

let itemPropType = shape({
  artno: stringable,
  costperunit: number,
  discount: shape({ amount: number, unit: stringable }),
  quantity: number,
  tax: shape({ amount: number, unit: stringable }),
  text: stringable,
  total: totalPropType,
  unit: stringable,
});

@forbidExtraArgs
export default class ServerCostSummary extends Component {
  @arg(
    shape({
      currency: stringable,
      price: number,
      discount: number,
      total: number,
    }),
  )
  billing;

  @arg(
    shape({
      currency: stringable,
      extra: shape({ items: arrayOf(itemPropType), total: totalPropType }),
      license: shape({ items: arrayOf(itemPropType), total: totalPropType }),
      server: shape({ items: arrayOf(itemPropType), total: totalPropType }),
      total: totalPropType,
    }),
  )
  costs;

  get serverDiscount() {
    let items = this.costs?.server?.items;
    if (items) {
      let discount = this.getDiscountFromItems(items);
      return discount > 0 ? discount : null;
    }
    return null;
  }

  getDiscountFromItems(items) {
    let discount = 0;
    items.forEach((i) => {
      if (i.discount.amount > 0) {
        discount = i.discount.amount;
      }
    });
    return discount;
  }
}

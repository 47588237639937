import Component from "@glimmer/component";
import { action } from "@ember/object";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { stringable } from "glesys-controlpanel/utils/prop-types";
import { bool, func } from "prop-types";

@forbidExtraArgs
export default class AtomDeleteOption extends Component {
  @arg(stringable.isRequired) key;
  @arg(stringable) label;
  @arg(bool) selected = false;
  @arg(func.isRequired) onChange;

  isSelected = this.selected;

  @action
  didInsert() {
    this.onChange(this.key, this.selected);
  }

  @action
  change(event) {
    this.onChange(this.key, event.target.checked);
  }
}

import Component from "@glimmer/component";
import { task } from "ember-concurrency";
import { service } from "@ember/service";

export default class ModalsEmailAccountCreate extends Component {
  @service current;
  @service("cost") costService;

  get domain() {
    return this.args.params.domain;
  }

  get task() {
    return this.args.params.task;
  }

  email;

  get cost() {
    return this.costService.forEmailAccount();
  }

  submitTask = task(async () => this.task.perform(this.email));
}

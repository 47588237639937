import { hash } from "rsvp";
import Route from "@ember/routing/route";

export default class ServersCloneRoute extends Route {
  model(params) {
    let { servers } = this.modelFor("servers");
    let server = servers.find((server) => server.id === params.server_id);

    return hash({
      server,
      platformArguments: server.get("platformArguments"),
    });
  }

  setupController(controller, model) {
    super.setupController(controller, model);

    controller.resetProperties();
  }
}

import Model, { attr, belongsTo } from "@ember-data/model";
import { isNone } from "@ember/utils";

export default class ArchiveModel extends Model {
  @attr username;
  @attr description;
  @attr node;

  @attr sizefree;
  @attr sizetotal;
  @attr size;

  @attr cost;
  @attr locked;

  @attr password;

  @belongsTo("project", { async: false, inverse: "archives" }) project;

  get usage() {
    let max = this.sizetotal;
    let free = this.sizefree;
    let unit = "GB";

    let usage = "-";
    if (!isNone(free)) {
      usage = max - free;
      usage = parseFloat(usage.toFixed(2));
    }

    return { usage, max, unit };
  }
}

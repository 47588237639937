import Controller from "@ember/controller";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { task } from "ember-concurrency";

export default class VpnController extends Controller {
  @service intl;
  @service modal;
  @service store;

  get project() {
    return this.model.project;
  }

  get vpnUsers() {
    return this.model.vpnUsers;
  }

  get vpnUsersFiltered() {
    return this.vpnUsers.filter((user) => !user.isNew);
  }

  createVpnUserTask = task(async (username, password) => {
    let project = this.project;
    let projectNumber = project.get("accountname");

    username = `${projectNumber}_${username}`;

    let vpnUser = this.store.createRecord("vpn-user", {
      username,
      password,
      project,
      enabled: true,
    });

    try {
      await vpnUser.save();
    } catch (error) {
      vpnUser.unloadRecord();
      throw error;
    }
  });

  changeVpnUserPasswordTask = task(async (vpnUser, password) => {
    vpnUser.set("password", password);
    await vpnUser.save();
  });

  deleteVpnUser = task(async (vpnUser) => {
    await vpnUser.destroyRecord();
  });

  @action
  toggleEnabled(vpnUser) {
    vpnUser.set("enabled", !vpnUser.enabled);
    vpnUser.save();
  }

  @action
  openChangePasswordModal(vpnUser) {
    let task = this.changeVpnUserPasswordTask;
    this.modal.open("modals/vpn-user-change-password", { task, vpnUser });
  }
}

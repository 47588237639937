import Inflector from "ember-inflector";

export function initialize(/* container, application */) {
  Inflector.inflector.uncountable("email-quota");
}

export default {
  name: "main",
  initialize,
};

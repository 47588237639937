import Component from "@glimmer/component";
import { isPresent } from "@ember/utils";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { stringable } from "glesys-controlpanel/utils/prop-types";
import { bool, number, shape } from "prop-types";

@forbidExtraArgs
export default class ServerUsage extends Component {
  @arg(stringable) displayUnit;
  @arg(stringable) label;
  @arg(bool) showUsage = true;
  @arg(bool) showUsageBar = true;

  @arg(
    shape({
      max: number,
      usage: number,
      unit: stringable,
    }),
  )
  usage;

  @arg(number) configuration;

  displayUsage(data, showUnit = true) {
    let displayUnit = this.displayUnit;
    let realUnit = this.usage?.unit;
    let usage = data;

    if (realUnit && isPresent(usage)) {
      if (displayUnit === "GB" && realUnit === "MB") {
        usage = Math.round(usage / 1024);
      }
      if (showUnit) {
        return `${usage} ${displayUnit}`;
      }
      return `${Math.round(usage * 10) / 10}`;
    }

    return "-";
  }

  get maxDisplay() {
    return this.displayUsage(this.usage?.max);
  }

  get usageDisplay() {
    return this.displayUsage(this.usage?.usage, false);
  }

  get configurationDisplay() {
    return this.displayUsage(this.configuration);
  }
}

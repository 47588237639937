import { helper as buildHelper } from "@ember/component/helper";
import { htmlSafe } from "@ember/template";

const _humanFileSize = function (bytes, displayUnit) {
  let threshold = 1024;

  if (Math.abs(bytes) == 0) {
    return htmlSafe(`0 <span>${displayUnit}</span>`);
  } else if (Math.abs(bytes) < threshold) {
    return htmlSafe(`${bytes} <span>B</span>`);
  }

  let units = ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];

  let unitIndex = -1;
  do {
    bytes /= threshold;
    ++unitIndex;
  } while (Math.abs(bytes) >= threshold && unitIndex < units.length - 1);

  return htmlSafe(`${bytes.toFixed(1)} <span>${units[unitIndex]}</span>`);
};

export function humanFileSize([size, unit = "B"]) {
  switch (unit) {
    case "GiB":
      size *= 1024 * 1024 * 1024;
      break;
    case "MiB":
      size *= 1024 * 1024;
      break;
  }

  return _humanFileSize(size, unit);
}

export default buildHelper(humanFileSize);

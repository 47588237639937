import Route from "@ember/routing/route";
import RSVP from "rsvp";
import { service } from "@ember/service";

export default class OrganizationCollaboratorsDetailsRoute extends Route {
  @service router;

  model(params) {
    let { organization, collaborators } = this.modelFor("organization.collaborators");

    let collaborator = collaborators.find((collaborator) => collaborator.id === params.collaborator_id);
    let projects = organization.get("projects");

    return RSVP.hash({ collaborator, organization, projects });
  }

  redirect({ collaborator }) {
    // Don't allow editing legacy collaborators or collaborators
    // pending invite accept.
    if (!collaborator.isInviteAccepted || collaborator.isLegacyUser) {
      this.router.transitionTo("organization.collaborators");
    }
  }
}

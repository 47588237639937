import Component from "@glimmer/component";
import { task } from "ember-concurrency";
import { service } from "@ember/service";
import { action } from "@ember/object";

export default class ModalsVpnUserCreate extends Component {
  @service modal;
  @service cost;

  get project() {
    return this.args.params.project;
  }

  get task() {
    return this.args.params.task;
  }

  get vpnCost() {
    return this.cost.forVpnUser();
  }

  username;
  password;

  @action
  onFormChange({ password }) {
    this.password = password;
  }

  submitTask = task(async () => {
    await this.task.perform(this.username, this.password);

    this.modal.close();
  });
}

import Controller from "@ember/controller";
import { once } from "@ember/runloop";
import { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";
import ENV from "glesys-controlpanel/config/environment";
import { action } from "@ember/object";

export default class ApplicationController extends Controller {
  queryParams = ["locale", "utm_source", "utm_medium", "utm_content", "utm_campaign"];

  @tracked locale = null;

  @tracked utm_source = null;
  @tracked utm_medium = null;
  @tracked utm_content = null;
  @tracked utm_campaign = null;

  @service current;
  @service intl;
  @service updateNotify;
  @service notification;
  @service router;

  constructor() {
    super(...arguments);
    this.updateNotify.check();
  }

  get environment() {
    let { environment } = ENV;
    return environment !== "production" ? `(${environment})` : "";
  }

  get pageTitle() {
    return `${this.environment} GleSYS Cloud`.trim();
  }

  clearParams() {
    this.router.replaceWith({
      queryParams: {
        locale: null,
        utm_source: null,
        utm_medium: null,
        utm_content: null,
        utm_campaign: null,
      },
    });
  }

  @action
  didUpdateQueryParams() {
    if (this.locale) {
      this.intl.setLocaleIfValid(this.locale);
      once(this, this.clearParams);
    }

    if (this.utm_source) {
      this.current.set("utm", {
        source: this.utm_source,
        medium: this.utm_medium,
        content: this.utm_content,
        campaign: this.utm_campaign,
      });
      once(this, this.clearParams);
    }
  }
}

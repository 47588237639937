import { service } from "@ember/service";
import { action } from "@ember/object";
import Controller from "@ember/controller";
import { task, timeout, waitForProperty } from "ember-concurrency";
import { tracked } from "@glimmer/tracking";
import ENV from "glesys-controlpanel/config/environment";

export default class FileStorageVolumesDetailsController extends Controller {
  @service ajax;
  @service metrics;
  @service modal;
  @service router;
  @service store;
  @service storage;

  get volume() {
    return this.model.fileStorageVolume;
  }

  get plans() {
    return this.model.plans;
  }

  get currentPlan() {
    return this.volume.plan;
  }

  get currentName() {
    return this.volume.name;
  }

  get project() {
    return this.model.project;
  }

  get servers() {
    return this.model.servers;
  }

  get accessList() {
    return this.volume.accessList;
  }

  get accessListOfHostnames() {
    let servers = this.servers;
    let accessList = this.accessList;

    return servers.map((server) => {
      if (accessList.includes(server.id)) {
        return server.hostname;
      }
    });
  }

  get usageStatistics() {
    return this.statistics?.usage.map((item) => {
      return {
        key: item.timestamp,
        value: item.value,
      };
    });
  }

  get loadStatistics() {
    return this.statistics?.load;
  }

  @tracked statistics;
  @tracked showUsageGuide = false;
  @tracked selectedUsageGuideOS = "linux";

  get deleteButtonStyle() {
    if (this.volume.isLocked) {
      return "bg-gray-light cursor-not-allowed";
    } else {
      return "bg-red hover:bg-red-dark";
    }
  }

  deleteFileStorageVolume = task(async () => {
    let volume = this.volume;
    await volume.destroyRecord().catch((error) => {
      volume.rollbackAttributes();
      this.router.transitionTo("file-storage-volumes.details", volume.id);
      throw error;
    });

    this.metrics.trackEvent("Intercom", {
      event: "deleted-file-storage-volume",
    });

    this.router.transitionTo("file-storage-volumes.overview");
  });

  serverAccessListTask = task(async (accessList) => {
    let volume = this.volume;
    volume.accessList = accessList ? accessList : [];

    await volume.save().catch((error) => {
      volume.rollbackAttributes();
      throw error;
    });
  });

  createServerTask = task(async () => {
    await this.router.transitionTo("servers.create");
  });

  updateSettingsTask = task(async (newPlan, newAutoPromote) => {
    let { volume } = this;
    volume.hasAutoPromotion = newAutoPromote;
    volume.plan = newPlan ? newPlan : this.volume.plan;

    await volume.save().catch((error) => {
      volume.rollbackAttributes();
      throw error;
    });
  });

  updateDetailsTask = task(async (newDetails) => {
    let { volume } = this;
    let name = volume.name;
    volume.name = newDetails ? newDetails : name;

    await volume.save().catch((error) => {
      volume.rollbackAttributes();
      throw error;
    });
  });

  fetchVolumeLoopTask = task(async () => {
    if (ENV.environment === "test") {
      return;
    }

    // eslint-disable-next-line no-constant-condition
    while (true) {
      if (this.volume.get("currentState.stateName") === "root.loaded.saved") {
        await this.store.queryRecord("file-storage-volume", {
          id: this.volume.get("id"),
          project: this.project.get("id"),
        });
      }

      let interval = this.volume.get("status") === "ready" ? 10000 : 2000;
      await timeout(interval);
    }
  });

  fetchVolumeStatisticsLoopTask = task(async () => {
    if (ENV.environment === "test") {
      return;
    }

    // eslint-disable-next-line no-constant-condition
    while (true) {
      this.statistics = await this.ajax.request(
        `/io.php/projects/${this.project.id}/file-storage-volumes/${this.volume.id}/statistics`,
      );

      await timeout(10 * 1000);
    }
  });

  @action
  didInsert() {
    this.fetchVolumeStatisticsLoopTask.perform();
    this.fetchVolumeLoopTask.perform();

    waitForProperty(this, "statistics").then(() => {
      let showUsageGuide = this.storage.get("glesys:show-file-storage-volume-usage-guide");

      if (showUsageGuide === null) {
        showUsageGuide = this.statistics?.usage.length === 0;
      } else {
        showUsageGuide = showUsageGuide === "true";
      }
      this.showUsageGuide = showUsageGuide;
    });
  }

  @action
  willDestroy() {
    this.fetchVolumeStatisticsLoopTask.cancelAll();
    this.fetchVolumeLoopTask.cancelAll();
  }

  @action
  openFileStorageVolumeUpdateDetailsModal() {
    let properties = {
      currentName: this.currentName,
      updateDetailsTask: this.updateDetailsTask,
    };
    this.modal.open("modals/file-storage-volumes-update-details", properties);
  }

  @action
  openFileStorageVolumeUpdateSettingsModal() {
    let properties = {
      currentPlan: this.currentPlan,
      plans: this.plans,
      updateSettingsTask: this.updateSettingsTask,
      volume: this.volume,
    };
    this.modal.open("modals/file-storage-volumes-update-settings", properties);
  }

  @action
  openFileStorageVolumeUpdateAccessModal() {
    let properties = {
      project: this.project,
      servers: this.servers,
      accessList: this.accessList,
      serverAccessListTask: this.serverAccessListTask,
      createServerTask: this.createServerTask,
      volumeId: this.volume.id,
    };

    this.modal.open("modals/file-storage-volumes-update-access", properties);
  }

  @action
  setUsageGuideOS(os) {
    this.selectedUsageGuideOS = os;
  }

  @action
  toggleUsageGuide() {
    this.showUsageGuide = !this.showUsageGuide;
    this.storage.set("glesys:show-file-storage-volume-usage-guide", this.showUsageGuide);
  }
}

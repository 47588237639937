import { hash } from "rsvp";
import Route from "@ember/routing/route";

export default class ServersApplianceRoute extends Route {
  model() {
    let { project } = this.modelFor("organization");
    return hash({
      project,
    });
  }
}

import ApplicationSerializer from "./application";

export default class FileStorageVolumeSerializer extends ApplicationSerializer {
  primaryKey = "volumeid";

  attrs = {
    accessList: "accesslist",
    dataCenter: "datacenter",
    hasAutoPromotion: "autopromotion",
    isLocked: "islocked",
  };

  normalize(modelClass, resourceHash, prop) {
    resourceHash.maintenanceWindow = {
      weekDay: resourceHash.maintenancewindow.weekday,
      startTime: resourceHash.maintenancewindow.starttime,
      durationInMinutes: resourceHash.maintenancewindow.durationinminutes,
    };

    return super.normalize(modelClass, resourceHash, prop);
  }

  serialize() {
    let json = super.serialize(...arguments);

    json.planid = json.plan.planid;
    json.accesslist = json.accesslist?.join(",");

    return json;
  }
}

import { template } from "@ember/template-compiler";
import GModalHeader from "../../g-modal/header";
import GModalBody from "../../g-modal/body";
import GModalFooter from "../../g-modal/footer";
import MarkdownToHtml from "ember-cli-showdown/components/markdown-to-html";
import GButton from "../../g-button";
import { on } from "@ember/modifier";
import modalClose from "glesys-controlpanel/helpers/modal-close";
import { t } from "ember-intl";
import styles from "./styles.css";
export default template(`
  <GModalHeader data-test-modals-help-topic-header>
    {{@params.topic.title}}
  </GModalHeader>
  <GModalBody>
    <div class="{{styles.root}}" data-test-modals-help-topic-content>
      <MarkdownToHtml @markdown={{@params.topic.body}} @extensions="targetblank" />
    </div>
  </GModalBody>
  <GModalFooter>
    <GButton @color="gray" {{on "click" (modalClose)}}>
      {{t "button.close"}}
    </GButton>
  </GModalFooter>
`, {
    eval () {
        return eval(arguments[0]);
    }
});

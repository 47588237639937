import { service } from "@ember/service";
import { scheduleOnce } from "@ember/runloop";
import EmberRouter from "@ember/routing/router";
import config from "glesys-controlpanel/config/environment";

export default class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;

  @service metrics;
  @service router;

  constructor() {
    super(...arguments);
    this.on("routeDidChange", () => {
      scheduleOnce("afterRender", this, this.afterRender);
    });
  }

  afterRender() {
    let page = this.router.currentRouteName;
    this.metrics.trackPage("GoogleAnalytics", { page });
    if (window.Intercom) {
      window.Intercom("update"); // eslint-disable-line new-cap
    }
  }
}

const resetNamespace = true;

Router.map(function () {
  this.route("login");
  this.route("signup");
  this.route("recover", function () {
    this.route("request", { path: "/" });
    this.route("confirm", { path: "/:token" });
  });

  this.route("confirm", { path: "confirm/:token" });

  this.route("dashboard", { path: "/" }, function () {
    this.route("not-found", { path: "*path", resetNamespace });

    this.route("home", { path: "/" });

    this.route("api-access");

    this.route("support-challenge");

    this.route("organizations", { resetNamespace }, function () {
      this.route("create");
    });

    this.route("settings");

    this.route("legacy-user");

    this.route("invite", { path: "invite/:invite_code" });

    this.route("organization", { path: "/:organization_id", resetNamespace }, function () {
      this.route("overview", { path: "/" }, function () {
        this.route("dashboard", { path: "/" });
        this.route("networks", { resetNamespace });

        this.route("servers", { resetNamespace }, function () {
          this.route("overview", { path: "/" });

          this.route("create");
          this.route("appliance", { path: "/create/appliance" }, function () {
            this.route("overview", { path: "/" });
            this.route("creating", { path: "/creating/:server_id/type/:type" });

            this.route("jitsi");
            this.route("wordpress");
            this.route("gitlab");
            this.route("plesk");
          });

          this.route("clone", { path: "/clone/:server_id" });

          this.route("server", { path: "/:server_id", resetNamespace }, function () {
            this.route("details", { path: "/" });

            this.route("disks");
            this.route("backup");
            this.route("iso");
            this.route("network-adapters");
            this.route("statistics");
          });
        });

        this.route("ips", { resetNamespace }, function () {
          this.route("overview", { path: "/" });
          this.route("reserve");
          this.route("cost");
        });

        this.route("load-balancers", { resetNamespace }, function () {
          this.route("overview", { path: "/" });

          this.route("load-balancer", { path: "/:load_balancer_id", resetNamespace }, function () {
            this.route("details", { path: "/" });

            this.route("advanced");
            this.route("errors");
            this.route("statistics");
          });
        });

        this.route("dns", { resetNamespace }, function () {
          this.route("overview", { path: "/" });

          this.route("register");

          this.route("domain", { path: "/:dns_domain_id" });
        });

        this.route("email", { resetNamespace }, function () {
          this.route("overview", { path: "/" });

          this.route("domain", { path: "/:email_domain_id" }, function () {
            this.route("details", { path: "/" });

            this.route("account", { path: "/:email_account_id" });
          });
        });

        this.route("file-storage-volumes", { resetNamespace }, function () {
          this.route("overview", { path: "/" });
          this.route("create");
          this.route("details", { path: "/:file_storage_volume_id" });
        });

        this.route("private-networks", { resetNamespace }, function () {
          this.route("overview", { path: "/" });
          this.route("details", { path: "/:private_network_id" });
        });

        this.route("network-circuits", { resetNamespace });

        this.route("archives", { resetNamespace }, function () {
          this.route("overview", { path: "/" });

          this.route("archive", { path: "/:archive_id", resetNamespace });
        });

        this.route("vpn", { resetNamespace });

        this.route("object-storage", { resetNamespace });
      });
      this.route("add-payment-method");
      this.route("settings");
      this.route("invoices", function () {
        this.route("overview", { path: "/" });
        this.route("next-invoice");
      });
      this.route("remote-hands");

      this.route("projects", { resetNamespace }, function () {
        this.route("create");
      });

      this.route("collaborators", function () {
        this.route("overview", { path: "/" });
        this.route("details", { path: ":collaborator_id" });
      });
    });
  });
});

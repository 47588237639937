import Component from "@glimmer/component";
import { action } from "@ember/object";
import { arg, forbidExtraArgs } from "ember-arg-types";

@forbidExtraArgs
export default class LoadBalancerListener extends Component {
  @arg listener;
  @arg certificates;

  get certificateItems() {
    return [{ name: "-", id: "none" }].concat(this.certificates?.slice() ?? []);
  }

  get selectedCertificate() {
    if (this.port.certificate === "none") {
      return "-";
    }
    return this.port.certificate || "-";
  }

  // TODO: Remove this, use `listener` instead
  get port() {
    return this.listener;
  }

  portSelections = Object.freeze([{ value: "http" }, { value: "tcp" }]);

  get loadBalancer() {
    return this.listener.loadBalancer;
  }

  sessionOptions = Object.freeze([
    { value: true, label: "yes" },
    { value: false, label: "no" },
  ]);

  get sessionSelection() {
    let value = this.listener.stickysessions;
    return { value, label: value ? "yes" : "no" };
  }

  @action
  portSelected(port) {
    this.port.mode = port.value;
  }

  @action
  certificateSelected(certificate) {
    this.port.certificate = certificate.id;
  }

  @action
  stickySessionChange(option) {
    this.listener.stickysessions = option.value;
  }

  @action
  deletePort() {
    let port = this.port;

    if (port.get("isDeleted")) {
      port.rollbackAttributes();
    } else {
      port.deleteRecord();
    }
  }
}

import Component from "@glimmer/component";
import { service } from "@ember/service";
import { dropTask, keepLatestTask } from "ember-concurrency";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";

export default class ModalsServerReconfigureKvm extends Component {
  @service ajax;
  @service modal;

  @tracked serverCost;
  @tracked cpuValue = this.server.cpucores;
  @tracked memoryValue = this.server.memorysize;
  @tracked diskValue = this.server.disksize;

  get project() {
    return this.args.params.project;
  }

  get server() {
    return this.args.params.server;
  }

  get task() {
    return this.args.params.task;
  }

  get cpuValues() {
    return this.server.platformArguments.get("cpuCores").map((core) => core.value);
  }

  get memoryValues() {
    return this.server.platformArguments.get("memorySizes").map((size) => size.value);
  }

  get diskValues() {
    return this.server.platformArguments.get("diskSizes").map((size) => size.value);
  }

  get filteredDiskValues() {
    let initialDiskSize = this.diskHasChanged ? this.server.disksize : this.diskValue;
    return this.diskValues.filter((value) => initialDiskSize <= value);
  }

  get supportsEditMemorySize() {
    return this.server.supportsEditMemorySize;
  }

  get supportsEditDiskSize() {
    return this.server.supportsEditDiskSize;
  }

  get shouldNotShowStorage() {
    return this.filteredDiskValues.length === 1;
  }

  get diskHasChanged() {
    return this.server.disksize !== this.diskValue;
  }

  get memoryHasChanged() {
    return this.server.memorysize !== this.memoryValue;
  }

  get cpuHasChanged() {
    return this.server.cpucores !== this.cpuValue;
  }

  get serverAttributesHasChanged() {
    return this.diskHasChanged || this.memoryHasChanged || this.cpuHasChanged;
  }

  getKVMServerCostTask = keepLatestTask(async () => {
    let projectId = this.project?.get("id");
    let url = `/io.php/projects/${projectId}/vps/servers/server/costs`;
    let data = {
      config: {
        serverid: this.server.id,
        cpucores: this.cpuValue,
        disksize: this.diskValue,
        memorysize: this.memoryValue,
      },
    };

    try {
      let response = await this.ajax.post(url, { data });
      this.serverCost = {
        price: response.billing.price,
        total: response.billing.total,
      };
    } catch (_) {
      this.serverCost = {
        price: null,
      };
    }
  });

  saveServerReconfigurationTask = dropTask(async () => {
    let server = this.server;
    let config = {
      disksize: this.diskValue,
      memorysize: this.memoryValue,
      cpucores: this.cpuValue,
    };
    server.setProperties(config);
    await server.save();

    this.modal.close();
  });

  @action
  cpuValueChange(value) {
    this.cpuValue = value;
    this.getKVMServerCostTask.perform();
  }

  @action
  memoryValueChange(value) {
    this.memoryValue = value;
    this.getKVMServerCostTask.perform();
  }

  @action
  diskValueChange(value) {
    this.diskValue = value;
    this.getKVMServerCostTask.perform();
  }
}

import Controller from "@ember/controller";
import { task, dropTask } from "ember-concurrency";
import { service } from "@ember/service";

export default class EmailDomainDetailsController extends Controller {
  @service store;

  get domain() {
    return this.model.domain;
  }

  usedQuota(usedQouta) {
    return typeof usedQouta === "undefined" ? "-" : usedQouta;
  }

  createAccountTask = dropTask(async (prefix) => {
    let domain = this.domain;
    let emailaccount = `${prefix}@${domain.get("domainname")}`;

    let account = this.store.createRecord("email-account", {
      domain,
      emailaccount,
      usedQuota: 0,
    });

    return await account.save().catch((reason) => {
      account.unloadRecord();
      throw reason;
    });
  });

  createAliasTask = task(async (prefix, goto) => {
    let domain = this.domain;
    let emailalias = `${prefix}@${domain.get("domainname")}`;

    let alias = this.store.createRecord("email-alias", {
      domain,
      emailalias,
      goto,
    });

    await alias.save().catch((reason) => {
      alias.unloadRecord();
      throw reason;
    });
  });

  deleteAliasTask = task(async (alias) => {
    await alias.destroyRecord();
  });

  updateAliasTask = task(async (alias) => {
    return alias.save().catch((error) => {
      alias.rollbackAttributes();
      throw error;
    });
  });
}

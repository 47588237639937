import Service, { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";
import topics from "./_help-topics";

export default class HelpService extends Service {
  @service ajax;
  @service intl;
  @service router;

  @tracked data;

  constructor() {
    super(...arguments);

    this.data = topics;
  }

  get topicsForCurrentRoute() {
    let currentRouteName = this.router.currentRouteName;
    let locale = this.intl.primaryLocale;
    let data = this.data;

    if (data && data[currentRouteName]) {
      return data[currentRouteName].map((topic) => {
        return {
          key: currentRouteName, // NOTE: Only used for tracking events in help modal
          title: topic.title[locale] ?? topic.title["en"],
          body: topic.body[locale] ?? topic.body["en"],
        };
      });
    }

    return [];
  }
}

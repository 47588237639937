import Model, { attr, belongsTo } from "@ember-data/model";

export default class LoadBalancerTargetModel extends Model {
  @attr("yesno") active;
  @attr ip;
  @attr name;
  @attr port;
  @attr status;
  @attr weight;

  @belongsTo("load-balancer-listener", { async: false, inverse: "targets" }) listener;

  get isDirty() {
    return this.hasDirtyAttributes || this.isDeleted;
  }
}

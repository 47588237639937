import Component from "@glimmer/component";
import { arg } from "ember-arg-types";
import { array, func, object, oneOfType } from "prop-types";
import { Task, restartableTask } from "ember-concurrency";
import { modifier } from "ember-modifier";

export default class DataLoader extends Component {
  @arg(oneOfType([func, array, object]).isRequired) load;

  get isLoading() {
    return this.task.isRunning || (this.load instanceof Task && this.load.isRunning);
  }

  loadData = modifier(async () => {
    this.task.perform(this.load);

    return () => this.task.cancelAll();
  });

  task = restartableTask(async (promise) => {
    switch (true) {
      case promise instanceof Task:
        return await promise.linked().perform();
      case typeof promise === "function":
        return await promise();
      case Array.isArray(promise):
        return await Promise.all(promise);
      default:
        return await promise;
    }
  });
}

import hbs from "htmlbars-inline-precompile";
import { action } from "@storybook/addon-actions";

export default {
  title: "CountrySelect",
  component: "CountrySelect",
};

export const Default = (args) => ({
  template: hbs`
    <CountrySelect
      @countries={{this.countries}}
      @value={{this.value}}
      @onChange={{this.onChange}}
    />
  `,
  context: args,
});

Default.args = {
  countries: [
    { code: "FI", name: "Finland" },
    { code: "SE", name: "Sweden" },
  ],
  value: "Sweden",
  onChange: action("Country selected"),
};

import { template } from "@ember/template-compiler";
import { gButtonColor } from "glesys-controlpanel/helpers/g-button-color";
import { LinkComponent } from "@ember/legacy-built-in-components";
import Content from "./content";
export default class GButtonLink extends LinkComponent {
    classNames = [
        "flex",
        "items-center",
        "relative",
        "rounded",
        "text-sm"
    ];
    classNameBindings = [
        "stateClasses",
        "colorClasses",
        "displayClasses"
    ];
    loading = false;
    color = null;
    display = null;
    get stateClasses() {
        return this.disabled || this.loading ? "cursor-not-allowed pointer-events-none" : "cursor-pointer";
    }
    get colorClasses() {
        return gButtonColor([
            this.color
        ]);
    }
    get displayClasses() {
        return this.display === "compact" ? "px-2 py-1" : "px-4 py-2";
    }
    static{
        template(`
    <Content @loading={{this.loading}} @color={{this.color}}>
      {{yield}}
    </Content>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}

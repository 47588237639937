import IntlBaseService from "ember-intl/services/intl";
import Bugsnag from "@bugsnag/js";

export default class IntlService extends IntlBaseService {
  t(key, options) {
    return super.t(key, Object.assign({ htmlSafe: true }, options));
  }

  setLocaleIfValid(locale) {
    if (["en", "sv", "fi"].includes(locale)) {
      this.setLocale(locale);
    }
  }

  onMissingTranslation(key, locales) {
    let localeNames = locales.join(", ");
    let message = `Missing translation "${key}" for locale "${localeNames}"`;

    Bugsnag.notify(new Error(message));

    return message;
  }
}

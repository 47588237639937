import Transform from "@ember-data/serializer/transform";

export default class YesNoTransform extends Transform {
  deserialize(serialized) {
    return serialized === "yes";
  }

  serialize(deserialized) {
    if (deserialized === undefined) {
      return;
    }
    if (typeof deserialized === "boolean") {
      return deserialized ? "yes" : "no";
    }
    let type = typeof deserialized;
    throw new Error(`Invalid datatype for yesno: ${type}, ${deserialized}`);
  }
}

import ApplicationSerializer from "./application";

export default class PrivateNetworkSerializer extends ApplicationSerializer {
  attrs = {
    project: "account",
  };

  normalize(modelClass, resourceHash, prop) {
    resourceHash.links = {
      segments: `/io.php/privatenetwork/listsegments?privatenetworkid=${resourceHash.id}&includeadapters=1`,
    };

    return super.normalize(modelClass, resourceHash, prop);
  }
}

export function initialize(appInstance) {
  let errorHandler = appInstance.lookup("service:error-handler");
  errorHandler.setupErrorHandling();
}

export default {
  name: "error-handling",
  after: "bugsnag",
  initialize,
};

import ApplicationAdapter, { projectChildMixin } from "./application";
import { dasherize } from "@ember/string";
import { pluralize } from "ember-inflector";

export default class FileStorageVolumeAdapter extends ApplicationAdapter.extend(projectChildMixin) {
  uriSuffix = "file-storage-volumes";

  buildURL(modelName, id, snapshot, requestType, query) {
    switch (requestType) {
      case "query":
        return this.urlForQuery(query, modelName);
      case "queryRecord":
        return this.urlForQueryRecord(query, modelName);
      case "createRecord":
        return this.urlForCreateRecord(modelName, snapshot);
    }
    return super.buildURL(modelName, id, snapshot, requestType, query);
  }

  baseUriProject(snapshot) {
    if (snapshot) {
      let baseUri = this.baseUri();
      let projectId = snapshot.belongsTo("project").id;

      return `${baseUri}/projects/${projectId}`;
    }
  }

  pathForType(type) {
    return pluralize(dasherize(type));
  }

  urlForCreateRecord(modelName, snapshot) {
    return `${this.baseUriProject(snapshot)}/${this.pathForType(modelName)}`;
  }

  urlForQueryRecord(query) {
    let url = `${this.baseUri()}/projects/${query.project}/file-storage-volumes/${query.id}`;
    return url;
  }
}

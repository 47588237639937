import { registerDeprecationHandler } from "@ember/debug";
import config from "glesys-controlpanel/config/environment";

const SHOULD_THROW = () => config.environment !== "production";

export const deprecations = {
  "ember-data:deprecate-legacy-imports": "silence",
  "ember-data:deprecate-non-strict-id": "throw",
  "ember-test-waiters-legacy-module-name": "silence",
};

export const handler = (message, options, next) => {
  let deprecation = deprecations[options.id];

  switch (deprecation) {
    case "silence":
      return;
    case "throw":
      if (SHOULD_THROW()) {
        let error = new Error(message);
        error.name = message;
        throw error;
      }
      break;
    case "log":
    default:
      next(message, options);
  }
};

registerDeprecationHandler(handler);

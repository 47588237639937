import Controller from "@ember/controller";
import { action } from "@ember/object";
import { task } from "ember-concurrency";
import { service } from "@ember/service";

export default class LoadBalancerAdvancedController extends Controller {
  @service notification;
  @service intl;

  get loadBalancer() {
    return this.model.loadBalancer;
  }

  get listeners() {
    return this.loadBalancer.listeners;
  }

  get concurrentConnectionsOptions() {
    let options = [];
    for (let i = 1000; i <= 1000 * 20; i += 1000) {
      options.push(i);
    }
    for (let i = 30000; i <= 1000 * 200; i += 10000) {
      options.push(i);
    }

    return options.sort((a, b) => {
      return a - b;
    });
  }

  saveListenerTask = task(async (listener) => {
    let listeners = await this.listeners;
    let sumConcurrentConnections = listeners.reduce((accumulator, current) => accumulator + current.maxConnections, 0);

    if (sumConcurrentConnections > 800_000) {
      this.notification.add(
        "error",
        this.intl.t("load-balancer.notifications.load-balancer-not-updated.title"),
        this.intl.t("load-balancer.notifications.load-balancer-not-updated.text"),
      );
    } else {
      await listener.save();

      this.notification.add(
        "success",
        this.intl.t("load-balancer.notifications.load-balancer-updated.title"),
        this.intl.t("load-balancer.notifications.load-balancer-updated.text"),
      );
    }
  });

  @action
  setMaxConnections(listener, maxConnections) {
    listener.maxConnections = maxConnections;
  }
}

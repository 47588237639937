import hbs from "htmlbars-inline-precompile";

export default {
  title: "BackTo",
  component: "BackTo",
};

export const Default = (args) => ({
  template: hbs`<BackTo @route={{this.route}}>{{this.content}}</BackTo>`,
  context: args,
});

Default.args = {
  route: "home",
  content: "Back to",
};

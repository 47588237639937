import Model, { attr, belongsTo } from "@ember-data/model";

export default class CollaboratorModel extends Model {
  @attr name;
  @attr username;
  @attr("yesno") isOwner;
  @attr() inviteStatus;
  @attr() projectAccess;
  @attr("yesno") hasTwoFactorEnabled;

  @belongsTo("organization", { async: false, inverse: "collaborators" }) organization;

  get displayName() {
    let { email, name } = this;
    return name || email;
  }

  get isInviteAccepted() {
    return this.inviteStatus === "accepted";
  }

  get isInviteDeclined() {
    return this.inviteStatus === "declined";
  }

  get isInvitePending() {
    return ["pending", "sent"].includes(this.inviteStatus);
  }

  get isLegacyUser() {
    return !this.username.includes("@");
  }
}

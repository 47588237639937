import Component from "@glimmer/component";
import { action } from "@ember/object";
import { tracked } from "@glimmer/tracking";
import { later, next } from "@ember/runloop";
import { service } from "@ember/service";

export default class GModal extends Component {
  @service modal;
  @service keyboard;

  @tracked isOpen = this.args.isOpen || false;

  get animationClasses() {
    return this.isOpen ? "opacity-75" : "opacity-0";
  }

  get animationClassesForContent() {
    return this.isOpen ? "scale-100 opacity-100" : "scale-90 opacity-0";
  }

  get animationDuration() {
    return 300;
  }

  get onClose() {
    return this.args.onClose;
  }

  @action
  registerListener() {
    this.keyboard.listenFor({ key: "Escape" }, this.close);
  }

  @action
  unregisterListener() {
    this.keyboard.stopListenFor({ key: "Escape" });
  }

  @action
  close() {
    this.isOpen = false;
    later(() => {
      this.onClose?.();
    }, this.animationDuration);
  }

  @action
  animateIn() {
    next(() => (this.isOpen = true));
  }

  @action
  didUpdateArgs() {
    next(() => {
      this.isOpen = this.args.isOpen;
      if (!this.isOpen) {
        this.close();
      }
    });
  }
}

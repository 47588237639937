import hbs from "htmlbars-inline-precompile";

export default {
  title: "ThemeSelector",
  component: "ThemeSelector",
};

export const Default = (args) => ({
  template: hbs`<ThemeSelector />`,
  context: args,
});

import ApplicationSerializer from "./application";

export default class OrganizationSerializer extends ApplicationSerializer {
  fakeRestFormat = true;

  attrs = {
    isOwner: "isowner",

    identityNumber: "nationalidnumber",
    phone: "phonenumber",
    zip: "zipcode",
    invoiceReference: "invoicereference",
    invoiceDeliveryMethod: "invoicedeliverymethod",
    billingEmailOverrides: "billingemailoverrides",

    separateInvoiceAddress: "separateinvoiceaddress",

    invoiceAddress: "invoiceaddress",
    invoiceZip: "invoicezipcode",
    invoiceCity: "invoicecity",
    invoiceCountry: "invoicecountry",
    paymentTermsNetDays: "paymenttermsnetdays",

    canAccessBilling: "canaccessbilling",

    canPayInvoicesManuallyUsingPaypal: "canpayinvoicesmanuallyusingpaypal",

    hasExpiredInvoices: "expiredinvoices",
    hasUnpaidInvoices: "unpaidinvoices",

    paymentMethod: "paymentmethod",
    availablePaymentMethods: "allowedpaymentmethods",

    paymentCard: "paymentcard",

    currentBillingEmails: "currentbillingemails",
    vatNumber: "vatnumber",

    slaLevel: "slalevel",
    slaPinCode: "slapincode",
    slaPhoneNumber: "slaphonenumber",
    slaCost: "slacost",
    featureFlags: "featureflags",
  };

  normalize(modelClass, resourceHash, prop) {
    resourceHash.links = {
      projects: "projects",
      collaborators: "collaborators",
      invoices: "invoices",
    };

    return super.normalize(modelClass, resourceHash, prop);
  }
}

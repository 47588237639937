import ApplicationSerializer from "./application";

export default class CollaboratorSerializer extends ApplicationSerializer {
  attrs = {
    isOwner: "isowner",
    hasTwoFactorEnabled: "hastwofactorenabled",
    projectAccess: "projectaccess",
  };

  normalize(modelClass, resourceHash, prop) {
    resourceHash.username = resourceHash.username || resourceHash.recipient;
    resourceHash.inviteStatus = resourceHash.invitestatus || resourceHash.status;

    return super.normalize(modelClass, resourceHash, prop);
  }

  serializeAttribute(snapshot, json, key, attributes) {
    if (snapshot.record.get("isNew") || snapshot.changedAttributes()[key]) {
      super.serializeAttribute(snapshot, json, key, attributes);
    }
  }

  serialize(...args) {
    let json = super.serialize(...args);

    // API expects "isowner" to be a boolean, not "yes" or "no"...
    json.isowner = json.isowner === "yes";

    return json;
  }
}

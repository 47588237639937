import Helper from "@ember/component/helper";
import { service } from "@ember/service";

export default class IsCurrentRouteHelper extends Helper {
  @service router;

  compute([routeName, ...models]) {
    return this.router.isActive(routeName, ...models);
  }
}

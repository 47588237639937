import Component from "@glimmer/component";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { instanceOf } from "prop-types";
import server from "glesys-controlpanel/models/server";
import { action } from "@ember/object";

@forbidExtraArgs
export default class NetworkAdapters extends Component {
  @arg(instanceOf(server)) server;

  @action
  async loadData() {
    let networkAdapters = await this.server.networkAdapters;
    return networkAdapters?.slice().sort((a, b) => a.name?.localeCompare(b.name));
  }
}

import Controller from "@ember/controller";
import { service } from "@ember/service";
import { task, keepLatestTask } from "ember-concurrency";
import { tracked } from "@glimmer/tracking";
import incrementName from "glesys-controlpanel/helpers/increment-name";

export default class ServerDisksController extends Controller {
  @service ajax;

  @tracked disks;

  get server() {
    return this.model.server;
  }

  get organization() {
    return this.model.organization;
  }

  get limits() {
    return this.model.limits.limits;
  }

  get projectKey() {
    return this.server.project.id;
  }

  get currentNumberOfDisks() {
    return this.disks.length;
  }

  get minSize() {
    return this.limits.minsizeingib;
  }

  get maxSize() {
    return this.limits.maxsizeingib;
  }

  get sizes() {
    let range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

    let roundedMin = Math.ceil(this.minSize / 10) * 10;
    let upTo100 = range(roundedMin, Math.min(100, this.maxSize), 10);
    let rest = range(Math.max(100, this.minSize), this.maxSize, 50);

    return [...new Set([this.minSize, ...upTo100, ...rest, this.maxSize])];
  }

  get defaultDiskName() {
    return incrementName(
      this.disks.map((item) => item.name),
      "Additional disk",
    );
  }

  editNameTask = task(async (disk, name) => {
    let result = await this.ajax.post("/io.php/serverdisk/updatename", {
      data: {
        context: this.projectKey,
        id: disk.id,
        name,
      },
    });

    this.disks = this.disks.map((item) => (item.id === disk.id ? { ...disk, name: result.disk.name } : item));
  });

  editSizeTask = task(async (disk, sizeingib) => {
    let result = await this.ajax.post("/io.php/serverdisk/reconfigure", {
      data: {
        context: this.projectKey,
        id: disk.id,
        sizeingib,
      },
    });

    this.disks = this.disks.map((item) => (item.id === disk.id ? { ...disk, sizeingib: result.disk.sizeingib } : item));
  });

  addDiskTask = task(async (name, sizeingib) => {
    let result = await this.ajax.post("/io.php/serverdisk/create", {
      data: {
        context: this.projectKey,
        serverid: this.server.id,
        name,
        sizeingib,
      },
    });

    let newDisk = result.disk;
    this.disks = [...this.disks, newDisk];
  });

  getServerDiskCostTask = keepLatestTask(async (size) => {
    let serverId = this.server.id;
    let context = this.projectKey;
    let url = "/io.php/serverdisk/estimatedcost";
    let data = {
      context: context,
      serverid: serverId,
      additionaldiskingibdelta: size,
    };

    return await this.ajax.post(url, { data }).catch(() => {});
  });

  deleteDisk = task(async (item) => {
    await this.ajax.post("/io.php/serverdisk/delete", {
      data: {
        context: this.projectKey,
        id: item.id,
      },
    });

    this.disks = this.disks.filter((disk) => disk.id !== item.id);
  });
}

import ApplicationSerializer from "./application";

export default class PlatformArgumentSerializer extends ApplicationSerializer {
  normalize(modelClass, resourceHash, prop) {
    resourceHash.externalBandwidthOptions = resourceHash.bandwidth;
    resourceHash.internalBandwidthOptions = resourceHash.internalbandwidth;
    resourceHash.cpuCores = resourceHash.cpucores;
    resourceHash.dataCenters = resourceHash.datacenter;
    resourceHash.diskSizes = resourceHash.disksize;
    resourceHash.memorySizes = resourceHash.memorysize;
    resourceHash.veeam = resourceHash.backup_veeam;
    resourceHash.idera = resourceHash.backup_idera;
    resourceHash.bacula = resourceHash.backup_bacula;

    if (resourceHash.templateinfo) {
      let templates = [];

      Object.keys(resourceHash.templateinfo).forEach((key) => {
        let templateInfo = resourceHash.templateinfo[key];

        templates.push({
          templateId: templateInfo.id,
          value: templateInfo.name,
          basecost: templateInfo.instancecost.amount,
          licensecost: templateInfo.licensecost.amount,
          minimumDiskSize: templateInfo.minimumdisksize,
          minimumMemorySize: templateInfo.minimummemorysize,
          bootstrapMethod: templateInfo.bootstrapmethod,
          supportsCloudInitSshKeys: templateInfo.supportscloudinitsshkeys,
        });
      });

      resourceHash.templates = templates;
    }

    return super.normalize(modelClass, resourceHash, prop);
  }
}

import Controller from "@ember/controller";
import { service } from "@ember/service";

export default class extends Controller {
  @service intl;

  get tabs() {
    return [
      { name: this.intl.t("servers.create.tabs.distribution"), route: "servers.create" },
      { name: this.intl.t("servers.create.tabs.one-click-installer"), route: "servers.appliance" },
    ];
  }
}

import Component from "@glimmer/component";
import { action } from "@ember/object";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { array, bool, number, object, oneOf, oneOfType, string } from "prop-types";
import zoomPlugin from "chartjs-plugin-zoom";
import annotationPlugin from "chartjs-plugin-annotation";

import { Chart as ChartJS, _adapters, registerables } from "chart.js";
import dajysAdapter from "./chartjs-adapter-dayjs-3";

ChartJS.register(...registerables);
ChartJS.register(zoomPlugin);
ChartJS.register(annotationPlugin);

_adapters._date.override(dajysAdapter);

@forbidExtraArgs
export default class Chart extends Component {
  @arg(oneOf[("line", "bar", "radar", "polarArea", "pie", "doughnut")]) type = "line";

  @arg(oneOfType([array, object]).isRequired) data = [];

  @arg(object) options;

  @arg(string) unit = "";

  @arg(number) max;

  @arg(bool) zoomable = false;
  @arg(bool) panable = false;

  chart;

  get chartPlugins() {
    let zoom = {
      pan: { enabled: this.panable },
      zoom: {
        wheel: { enabled: this.zoomable },
        pinch: { enabled: this.zoomable },
        drag: { enabled: false },
      },
    };

    return {
      zoom,
      legend: {
        display: false,
      },
      tooltip: {
        mode: "nearest",
        axis: "x",
        intersect: false,
        bodyFont: {
          family: "Averta",
        },
        displayColors: this.data?.datasets?.length > 1,
        callbacks: {
          title: (context) => context[0]?.label,
          label: (context) => `${context.dataset.label || ""} ${context.formattedValue} ${this.unit}`.trim(),
        },
      },
      ...this.options?.plugins,
    };
  }

  get chartOptions() {
    return {
      plugins: this.chartPlugins,
      responsive: true,
      maintainAspectRatio: true,
      elements: {
        point: {
          radius: 0,
        },
      },
      datasets: {
        line: {
          borderColor: "#6EB3F0",
          backgroundColor: "#6EB3F0",
          fill: false,
          borderWidth: 2,
        },
      },
      scales: {
        y: {
          grid: {
            ...this.options?.scales?.y?.grid,
          },
          suggestedMax: this.max,
          suggestedMin: 0,
          ticks: {
            maxTicksLimit: 10,
            stepSize: 0.01,
            font: {
              family: "Averta",
            },
            padding: 20,
          },
          bounds: "data",
        },

        x: {
          grid: {
            display: false,
            ...this.options?.scales?.x?.grid,
          },
          stacked: this.options?.scales?.x?.stacked || false,
          type: this.options?.scales?.x?.type || "time",
          time: {
            unit: "hour",
            displayFormats: {
              hour: "HH:mm",
              day: "YYYY-MM-DD",
            },
            tooltipFormat: "YYYY-MM-DD HH:mm",
            ...this.options?.scales?.x?.time,
          },
        },
      },
    };
  }

  @action
  drawChart(element) {
    this.chart = new ChartJS(element, {
      type: this.type,
      data: this.data,
      options: this.chartOptions,
      plugins: this.chartPlugins,
    });
  }

  @action
  updateChart() {
    if (!this.chart) {
      return;
    }

    this.chart.data = this.data;
    this.chart.options = this.chartOptions;
    this.chart.plugins = this.chartPlugins;

    this.chart.update();
  }

  @action
  destroyChart() {
    this.chart?.destroy();
  }
}

import Route from "@ember/routing/route";
import RSVP from "rsvp";

export default class IpsOverviewRoute extends Route {
  buildRouteInfoMetadata() {
    return { jumpable: true };
  }

  model() {
    let { project } = this.modelFor("organization");

    return RSVP.hash({
      project,
      reservedIps: project.get("reservedIps").reload(),
      servers: project.get("servers"),
    });
  }
}

/**
  @public
  @module glesys-controlpanel
*/

import { helper as buildHelper } from "@ember/component/helper";

/**
  Transform and return the upper-case representation of a string.

  @public
  @method array-join
  @for helpers
  @param array {array}
  @param delimiter {string}

  @example

  `{{array-join ["foo", "bar", "baz"] " "}}` returns `foo bar baz`
*/
export function arrayJoin([array, delimiter] /* , hash*/) {
  return array.join(delimiter);
}

export default buildHelper(arrayJoin);

import Component from "@glimmer/component";

export default class Pill extends Component {
  get text() {
    return this.args.text || null;
  }
  get color() {
    return this.args.color || "gray";
  }
}

import { dasherize } from "@ember/string";
import JSONAPIAdapter from "@ember-data/adapter/json-api";
import { adapterConfig } from "./application";

export default class AvailableIpAdapter extends JSONAPIAdapter.extend(adapterConfig) {
  sortQueryParams(data) {
    let filter = {};
    for (let key in data) {
      filter[dasherize(key)] = data[key];
    }
    return { filter };
  }
}

import { service } from "@ember/service";
import Model, { attr, belongsTo, hasMany } from "@ember-data/model";

export default class ProjectModel extends Model {
  @service intl;

  @attr accountname;
  @attr access;
  @attr apiEnabled;
  @attr currency;
  @attr description;
  @attr("string") lockedReason;
  @attr name;
  @attr("string") color;
  @attr("boolean") unpaidInvoices;

  @hasMany("api-key", { async: true, inverse: "project" }) apiKeys;
  @hasMany("archive", { async: true, inverse: "project" }) archives;
  @belongsTo("archive-argument", { async: true, inverse: "project" }) archiveArguments;
  @hasMany("dns-domain", { async: true, inverse: "project" }) dnsDomains;
  @hasMany("email-domain", { async: true, inverse: "project" }) emailDomains;
  @hasMany("file-storage-volume", { async: true, inverse: "project" }) fileStorageVolumes;
  @hasMany("private-network", { async: true, inverse: "project" }) privateNetworks;
  @hasMany("network-circuit", { async: true, inverse: "project" }) networkCircuits;
  @hasMany("load-balancer", { async: true, inverse: "project" }) loadBalancers;
  @hasMany("network", { async: true, inverse: "project" }) networks;
  @belongsTo("organization", { async: false, inverse: "projects" }) organization;
  @hasMany("platform-argument", { async: true, inverse: "project" }) platformArguments;
  @hasMany("reserved-ip", { async: true, inverse: "project" }) reservedIps;
  @hasMany("server", { async: true, inverse: "project" }) servers;
  @hasMany("vpn-user", { async: true, inverse: "project" }) vpnUsers;
  @hasMany("ssh-key", { async: true, inverse: "project" }) sshKeys;

  /* COMPUTED PROPERTIES */

  get locked() {
    return Boolean(this.lockedReason);
  }

  get hasReadAccess() {
    return this.access === "read";
  }

  get hasWriteAccess() {
    return !this.hasReadAccess;
  }

  get displayName() {
    let { intl, name } = this;
    let key = this.accountname;

    if (name) {
      return name;
    }

    return intl.t("account.display-name", { key: key.toUpperCase() }).toString();
  }

  get apiKeysCount() {
    let apiKeys = this.hasMany("apiKeys").value();
    if (apiKeys) {
      return apiKeys.length;
    }
    return 0;
  }
}

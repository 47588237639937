import { helper } from "@ember/component/helper";

export function accentColor(args) {
  switch (args[0]) {
    case "green":
      return args[1] == "dark" ? "text-green-dark" : "text-green-lighter";
    case "powder":
      return args[1] == "dark" ? "text-powder-blue-dark" : "text-powder-blue-lighter";
    case "blue":
      return args[1] == "dark" ? "text-glesys-blue-darker" : "text-gray-lighter";
    case "gray":
    default:
      return args[1] == "dark" ? "text-gray-dark" : "text-gray-lighter";
  }
}

export default helper(accentColor);

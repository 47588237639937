import Component from "@glimmer/component";
import { action } from "@ember/object";

export default class GInput extends Component {
  @action
  didInsert(element) {
    if (element.hasAttribute("autofocus")) {
      element.focus();
    }
  }
}

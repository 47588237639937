import Component from "@glimmer/component";
import { task } from "ember-concurrency";
import { service } from "@ember/service";
import { action } from "@ember/object";

export default class ModalsProjectSetColor extends Component {
  @service modal;

  get project() {
    return this.args.params.project;
  }

  get task() {
    return this.args.params.task;
  }

  color = this.project.color;

  setColorTask = task(async () => {
    await this.task.perform(this.color);

    this.modal.close();
  });

  @action
  onChange(color) {
    this.color = color;
  }
}

import Component from "@glimmer/component";
import md5 from "md5";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { number, string, bool } from "prop-types";

@forbidExtraArgs
export default class GravatarImage extends Component {
  @arg(string) email;

  @arg(bool) dark;

  @arg(string) fallback = "blank";

  @arg(number) size = 32;

  get src() {
    return `//www.gravatar.com/avatar/${md5(this.email || "")}?d=${encodeURIComponent(this.fallback)}&s=${this.size}`;
  }

  get fallbackStyle() {
    if (this.fallback !== "blank") {
      return "";
    }

    if (this.dark) {
      return `bg-[url('/assets/images/user-dark.svg')]`;
    }

    return `bg-[url('/assets/images/user.svg')]`;
  }
}

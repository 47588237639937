import { service } from "@ember/service";
import { hash } from "rsvp";
import Route from "@ember/routing/route";

export default class OrganizationInvoicesRoute extends Route {
  @service session;

  buildRouteInfoMetadata() {
    return { jumpable: true };
  }

  model() {
    let { organization } = this.modelFor("organization");
    return hash({
      invoices: organization.get("invoices"),
      organization,
    });
  }

  setupController(controller, model) {
    super.setupController(controller, model);

    controller.set("selectedInvoices", []);
  }
}

import Component from "@glimmer/component";
import { action } from "@ember/object";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { arrayOf, func, oneOfType, shape, string } from "prop-types";
import { countryCodeEmoji } from "country-code-emoji";

@forbidExtraArgs
export default class CountrySelect extends Component {
  @arg(
    arrayOf(
      shape({
        code: string.isRequired,
        name: string.isRequired,
      }),
    ).isRequired,
  )
  countries;

  @arg(oneOfType([string, shape({ code: string, name: string })])) value;

  @arg(func.isRequired) onChange;

  @action
  countrySelected(value) {
    this.onChange(value);
  }

  countryFlagFor(country) {
    try {
      return countryCodeEmoji(country);
    } catch (e) {
      return "";
    }
  }
}

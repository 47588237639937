import hbs from "htmlbars-inline-precompile";
import { action } from "@storybook/addon-actions";

export default {
  title: "GButton",
  component: "GButton",
  argTypes: {
    type: {
      options: ["button", "submit"],
      control: {
        type: "select",
      },
    },
    color: {
      options: ["green", "blue", "red", "outline", "link", "gray-dark", "gray"],
      control: {
        type: "select",
      },
    },
  },
};

export const Default = (args) => ({
  template: hbs`
    <div class="p-16">
      <GButton
        @type={{this.type}}
        @color={{this.color}}
        @disabled={{this.disabled}}
        @loading={{this.loading}}
        {{on "click" this.handleClick}}
      >
        {{this.text}}
      </GButton>
    </div>
  `,
  context: args,
});

Default.args = {
  handleClick: action("Button clicked!"),
  type: "button",
  color: null,
  text: "Click me!",
  disabled: false,
  loading: false,
};

export const Icon = (args) => ({
  template: hbs`
    <div class="p-16">
      <GButton::Icon
        @type={{this.type}}
        @color={{this.color}}
        @disabled={{this.disabled}}
        @loading={{this.loading}}
        {{on "click" this.handleClick}}
      >
        <Icons::Plus class="w-6" />
        {{this.text}}
      </GButton::Icon>
    </div>
  `,
  context: args,
});

Icon.args = {
  handleClick: action("Button clicked!"),
  type: "button",
  color: null,
  text: "Click me!",
  disabled: false,
  loading: false,
};

export const Edit = (args) => ({
  template: hbs`
    <div class="p-16">
      <GButton::Edit
        @type={{this.type}}
        @disabled={{this.disabled}}
        @loading={{this.loading}}
        {{on "click" this.handleClick}}
      >
        {{this.text}}
      </GButton::Edit>
    </div>
  `,
  context: args,
});

Edit.args = {
  handleClick: action("Button clicked!"),
  type: "button",
  color: null,
  text: "Click me!",
  disabled: false,
  loading: false,
};

export const Create = (args) => ({
  template: hbs`
    <div class="p-16">
      <GButton::Create
        @type={{this.type}}
        @disabled={{this.disabled}}
        @loading={{this.loading}}
        {{on "click" this.handleClick}}
      >
        {{this.text}}
      </GButton::Create>
    </div>
  `,
  context: args,
});

Create.args = {
  handleClick: action("Button clicked!"),
  type: "button",
  color: null,
  text: "Click me!",
  disabled: false,
  loading: false,
};

export const Download = (args) => ({
  template: hbs`
    <div class="p-16">
      <GButton::Download
        @type={{this.type}}
        @disabled={{this.disabled}}
        @loading={{this.loading}}
        {{on "click" this.handleClick}}
      >
        {{this.text}}
      </GButton::Download>
    </div>
  `,
  context: args,
});

Download.args = {
  handleClick: action("Button clicked!"),
  type: "button",
  color: null,
  text: "Click me!",
  disabled: false,
  loading: false,
};

export const Upload = (args) => ({
  template: hbs`
    <div class="p-16">
      <GButton::Upload
        @type={{this.type}}
        @disabled={{this.disabled}}
        @loading={{this.loading}}
        {{on "click" this.handleClick}}
      >
        {{this.text}}
      </GButton::Upload>
    </div>
  `,
  context: args,
});

Upload.args = {
  handleClick: action("Button clicked!"),
  type: "button",
  color: null,
  text: "Click me!",
  disabled: false,
  loading: false,
};

import hbs from "htmlbars-inline-precompile";

export default {
  title: "Chart",
  component: "Chart",
  parameters: {
    docs: {
      description: {
        component: `See [Chart.js docs](https://www.chartjs.org/docs) for documentation`,
      },
    },
  },
};

export const Default = (args) => ({
  template: hbs`
    <Chart @data={{this.data}} @options={{this.options}} @unit={{this.unit}} @max={{this.max}} />
  `,
  context: args,
});

Default.args = {
  data: {
    datasets: [
      {
        label: "SEK",
        data: [
          { x: new Date("2020-01-01 12:00"), y: 10 },
          { x: new Date("2020-01-02 12:00"), y: 15 },
          { x: new Date("2020-01-03 12:00"), y: 25 },
          { x: new Date("2020-01-04 12:00"), y: 5 },
        ],
      },
      {
        label: "EUR",
        borderColor: "red",
        backgroundColor: "red",
        data: [
          { x: new Date("2020-01-01 12:00"), y: 1 },
          { x: new Date("2020-01-02 12:00"), y: 1.5 },
          { x: new Date("2020-01-03 12:00"), y: 2.5 },
          { x: new Date("2020-01-04 12:00"), y: 0.5 },
        ],
      },
    ],
  },
  options: {},
  unit: "price",
  max: 30,
  zoomable: true,
  panable: true,
};

import hbs from "htmlbars-inline-precompile";

export default {
  title: "DiffView",
  component: "DiffView",
  argTypes: {
    theme: {
      options: ["dark", "light"],
      control: {
        type: "select",
      },
    },
  },
};

export const Default = (args) => ({
  template: hbs`
    {{! template-lint-disable no-bare-strings }}
    new: <pre class="bg-gray-light border p-4">{{this.new}}</pre>
    old: <pre class="bg-gray-light border p-4">{{this.old}}</pre>

    Diff:
    <DiffView @theme={{this.theme}} @showLineNumbers={{this.showLineNumbers}} @new={{this.new}} @old={{this.old}} />
  `,
  context: args,
});

Default.args = {
  theme: "light",
  showLineNumbers: false,
  new: [1, 2, 2.5, 3, 4],
  old: [1, 2, 3, 4, 5],
};

import Service, { service } from "@ember/service";
import { isNone } from "@ember/utils";
import { tracked } from "@glimmer/tracking";
import { registerDestructor } from "@ember/destroyable";
import { action } from "@ember/object";

export default class ThemeService extends Service {
  @service abilities;
  @service session;
  @service storage;

  @tracked theme = "light";

  constructor() {
    super(...arguments);

    this.setup();

    let matchMedia = window.matchMedia("(prefers-color-scheme: dark)");
    matchMedia.addEventListener("change", this.onChange);

    registerDestructor(this, () => matchMedia.removeEventListener("change", this.onChange));
  }

  get canAccessThemes() {
    return this.abilities.can("access theme selector for user", this.session.currentUser);
  }

  get current() {
    return this.theme;
  }

  get preferred() {
    return window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";
  }

  setup() {
    if (!this.canAccessThemes) {
      this.activate("light");
      return;
    }

    let theme = this.storage.get("glesys:theme");
    if (!isNone(theme)) {
      this.theme = theme;
    }
  }

  activate(theme) {
    document.documentElement.classList.remove("dark", "light");
    this.theme = theme;

    document.documentElement.classList.add(this.theme === "auto" ? this.preferred : this.theme);
    this.storage.set("glesys:theme", this.theme);
  }

  @action
  onChange(event) {
    this.canAccessThemes && this.activate(event.matches ? "dark" : "light");
  }
}

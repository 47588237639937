import Component from "@glimmer/component";
import { action } from "@ember/object";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { arrayOf, func, number, shape } from "prop-types";
import { stringable } from "glesys-controlpanel/utils/prop-types";

let planPropType = shape({
  id: stringable,
  cost: shape({ currency: stringable, price: number, total: number }),
  quota: number,
});
@forbidExtraArgs
export default class FileStorageVolumePlanSelect extends Component {
  @arg(arrayOf(planPropType)) plans;
  @arg(planPropType) selectedPlan;
  @arg(func.isRequired) onSelectedPlan;

  get plansWithCost() {
    return this.plans.filter((plan) => plan.cost !== null);
  }

  @action
  togglePlan(plan) {
    this.onSelectedPlan(plan);
  }
}

import Controller from "@ember/controller";
import { service } from "@ember/service";
import { task } from "ember-concurrency";
import { action } from "@ember/object";
import { tracked } from "@glimmer/tracking";

export default class extends Controller {
  @service ajax;

  @tracked email = null;

  @action
  submitForm(event) {
    event.preventDefault();
    this.requestToConvertToEmailUser.perform();
  }

  requestToConvertToEmailUser = task(async () => {
    await this.ajax.post("/io.php/legacyuser/convert", {
      data: {
        email: this.email,
      },
    });

    this.email = null;
  });
}

import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { task } from "ember-concurrency";

export default class extends Component {
  @service router;

  //eslint-disable-next-line ember/no-tracked-properties-from-args
  @tracked server = this.args.server;
  project = this.args.project;

  @tracked backups;

  @action
  goToBackupRoute() {
    this.router.transitionTo("server.backup");
  }

  translations = {
    daily: "server.details.backup.schedules.daily",
    weekly: "server.details.backup.schedules.weekly",
    monthly: "server.details.backup.schedules.monthly",
    manual: "server.details.backup.schedules.manual",
  };

  get manualBackups() {
    return this.backups?.filter((backups) => backups.frequency == null);
  }

  get schedulesAndManualBackupsWithTranslationString() {
    return this.server.backup?.schedules
      ?.map((schedule) => ({
        ...schedule,
        translationString: this.translations[schedule.frequency] || "server.details.backup.schedules.custom",
        backups: this.backups?.filter((backup) => {
          return backup.frequency == schedule.frequency;
        }),
      }))
      .concat(
        this.manualBackups?.length
          ? [
              {
                numberofimagestokeep: this.manualBackups.length,
                translationString: this.translations["manual"],
                backups: this.manualBackups,
              },
            ]
          : [],
      );
  }

  loadBackups = task(async () => {
    this.backups = (await this.server.backups).slice().sort((a, b) => b.createdAt - a.createdAt);
  });
}

import hbs from "htmlbars-inline-precompile";

export default {
  title: "Progress Bar",
  component: "ProgressBar",
  argTypes: {
    size: {
      options: ["small", "large"],
      control: {
        type: "select",
      },
    },
  },
};

export const Default = (args) => ({
  template: hbs`
    <div class="p-16">
      <ProgressBar
        @text={{this.text}}
        @max={{this.max}}
        @value={{this.value}}
        @showPercentText={{this.showPercentText}}
        @size={{this.size}}
        @colors={{this.colors}}
      />
    </div>
  `,
  context: args,
});

Default.args = {
  text: "Text",
  max: 100,
  value: 50,
  showPercentText: false,
  size: "small",
  colors: false,
};

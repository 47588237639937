import { service } from "@ember/service";
import Controller from "@ember/controller";
import { action } from "@ember/object";
import { tracked } from "@glimmer/tracking";
import { task, dropTask, timeout } from "ember-concurrency";
import ENV from "glesys-controlpanel/config/environment";

export default class ApiAccessController extends Controller {
  @service ajax;
  @service session;
  @service store;

  @tracked wantsToDelete = false;

  get projects() {
    return this.model.projects;
  }

  get organizations() {
    return this.model.organizations;
  }

  get apiKeysCount() {
    let count = 0;
    this.projects.forEach((project) => (count += project.get("apiKeysCount")));
    return count;
  }

  createKeyTask = task(async (project, description) => {
    let key = this.store.createRecord("api-key", {
      project,
      description,
    });

    await key.save().catch((e) => {
      key.destroyRecord();
      throw e;
    });

    // make nonsical update to key to refresh the model with permissions.
    await this.updateApiKeyTask.perform(key, "id", key.id);
  });

  updateApiKeyTask = task(async (apiKey, property, value) => {
    try {
      await apiKey.set(property, value);
      await apiKey.save();
    } catch (error) {
      apiKey.rollbackAttributes();
      throw error;
    }
  });

  deleteApiKeyTask = task(async (apiKey) => {
    await apiKey.destroyRecord();
  });

  thinkAboutDeletingTheApiKey = dropTask(async (ms) => {
    /* istanbul ignore if */
    if (ENV.environment !== "test") {
      await timeout(ms);
    }
    this.wantsToDelete = false;
  });

  @action
  deleteApiKey(apiKey, event) {
    if (this.wantsToDelete) {
      this.deleteApiKeyTask
        .perform(apiKey)
        .then(() => this.thinkAboutDeletingTheApiKey.cancelAll())
        .finally(() => (this.wantsToDelete = false));
      event.stopPropagation();
    } else {
      this.thinkAboutDeletingTheApiKey.perform(3000);
      event.stopPropagation();
      this.wantsToDelete = true;
    }
  }
}

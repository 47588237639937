import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";

export default class SidebarOrganization extends Component {
  @service storage;

  defaultSettings = Object.freeze({
    compute: { open: true },
    bareMetal: { open: true },
    storage: { open: true },
    domains: { open: true },
    networking: { open: true },
  });

  @tracked settings;

  constructor() {
    super(...arguments);

    try {
      this.settings = JSON.parse(this.storage.get("glesys:sidebar")) ?? this.defaultSettings;
    } catch (e) {
      this.settings = this.defaultSettings;
    }
  }

  @action
  isOpen(category) {
    return this.settings[category]?.open ?? true;
  }

  @action
  toggle(category) {
    this.settings = {
      ...this.settings,
      [category]: {
        open: !this.isOpen(category),
      },
    };

    this.storage.set("glesys:sidebar", JSON.stringify(this.settings));
  }
}

import Component from "@glimmer/component";
import { service } from "@ember/service";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { bool } from "prop-types";

@forbidExtraArgs
export default class DashboardContent extends Component {
  @service modal;
  @service tour;

  @arg(bool) isLoading = false;
}

const CHARS = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz";

export const NUMERALS = "0123456789";
export const SPECIAL_CHARS = "!@#$%^&*?_~";
export const UPPERCASE_ALPHABETICALS = "ABCDEFGHIJKLMNOPQRSTUVWXTZ";
export const LOWERCASE_ALPHABETICALS = "abcdefghijklmnopqrstuvwxtz";

// returns an array of unrepresented character sets
export const unrepresentedCharacters = (password, arrayOfCharacters) => {
  return arrayOfCharacters
    .map((cs) =>
      [...cs].map((c) => {
        return password.includes(c);
      }),
    )
    .flatMap((x, idx) => (x.includes(true) ? [] : arrayOfCharacters[idx]))
    .filter((x) => x);
};

export const generatePasswordFromSets = (length, arrayOfCharacterSets) => {
  const charset = arrayOfCharacterSets.join("");
  let password = "";

  for (let i = 0; i < length; i++) {
    let random = Math.floor(Math.random() * charset.length);
    password += charset.substring(random, random + 1);
  }

  // regenerates password if representation is missing from some set.
  if (unrepresentedCharacters(password, arrayOfCharacterSets).length) {
    return generatePasswordFromSets(length, arrayOfCharacterSets);
  } else {
    return password;
  }
};

export default function generatePassword(length = 10, strength = "") {
  if (strength === "") {
    return generatePasswordFromSets(length, [CHARS]);
  }

  if (strength === "strong") {
    return generatePasswordFromSets(length, [
      NUMERALS,
      LOWERCASE_ALPHABETICALS,
      UPPERCASE_ALPHABETICALS,
      SPECIAL_CHARS,
    ]);
  }
}

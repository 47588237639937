import ApplicationSerializer from "./application";
import { EmbeddedRecordsMixin } from "@ember-data/serializer/rest";
import DasherizedMixin from "./_dasherized-mixin";

export default class LoadBalancerListenerSerializer extends ApplicationSerializer.extend(
  EmbeddedRecordsMixin,
  DasherizedMixin,
) {
  attrs = {
    targets: {
      embedded: "always",
    },
  };
}

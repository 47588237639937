import Route from "@ember/routing/route";
import { service } from "@ember/service";

export default class extends Route {
  @service ajax;

  model({ token }) {
    return this.ajax
      .post(`/io.php/recover/confirm`, { data: { token } })
      .then(({ users }) => {
        return { users, token };
      })
      .catch(() => {
        return { users: [] };
      });
  }
}

import { Ability } from "ember-can";
import { camelize } from "@ember/string";

export default class DataCenterAbility extends Ability {
  get dataCenter() {
    return this.model;
  }

  get canReserveIp() {
    if (!this.platform) {
      return false;
    }

    let propertyName = camelize(`has ${this.platform} support`.toLowerCase());
    return this.dataCenter.get(propertyName);
  }
}

import hbs from "htmlbars-inline-precompile";
import { action } from "@storybook/addon-actions";

export default {
  title: "KvmGenerateUser",
  component: "KvmGenerateUser",
};

export const Default = (args) => ({
  template: hbs`
      <form class="ui form">
        <KvmGenerateUser
          @username={{this.username}}
          @password={{this.password}}
          @sshKey={{this.sshKey}}
          @onChange={{this.onUserChange}}
        />
      </form>
    `,
  context: args,
});

Default.args = {
  username: "Name",
  password: "",
  sshKey: "",
  onUserChange: action("Changed"),
};

import { service } from "@ember/service";
import Controller from "@ember/controller";
import { compare } from "@ember/utils";
import { restartableTask, timeout } from "ember-concurrency";
import { action } from "@ember/object";
import { tracked } from "@glimmer/tracking";
import ENV from "glesys-controlpanel/config/environment";

export default class EmailOverviewController extends Controller {
  @service ajax;

  get project() {
    return this.model.project;
  }

  get domainsTaskResult() {
    return this.model.domainsTaskResult;
  }

  get quotaTaskResult() {
    return this.model.quotaTaskResult;
  }

  get domains() {
    return this.domainsTaskResult;
  }

  get pricelist() {
    return this.model.pricelist;
  }

  get quota() {
    return this.quotaTaskResult;
  }

  get meta() {
    return this.domains.meta;
  }

  get pagination() {
    return {
      page: this.meta.page,
      total: this.meta.total,
      perPage: this.meta.perpage,
    };
  }

  queryParams = ["page", "filter"];
  @tracked page = 1;
  @tracked filter = "";

  get sortedDomains() {
    return this.domains.slice().sort((a, b) => {
      let aValue = `${a.get("hasAccountsOrAliases") ? 0 : 1}~${a.get("domainname")}`;
      let bValue = `${b.get("hasAccountsOrAliases") ? 0 : 1}~${b.get("domainname")}`;

      return compare(aValue, bValue);
    });
  }

  get maxPage() {
    let meta = this.meta;
    if (meta) {
      return Math.ceil(meta.total / meta.perpage);
    }
    return 1;
  }

  filterTask = restartableTask(async (query) => {
    if (ENV.environment !== "test") {
      await timeout(300);
    }
    this.filter = query;
    this.page = 1;
  });

  get showEmptyState() {
    let { domains, filter } = this;

    return filter === "" && domains.length === 0;
  }

  @action
  changeFilter(event) {
    this.filterTask.perform(event.target.value);
  }

  @action
  onPageChange(page) {
    this.page = page;
  }
}

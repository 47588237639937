import { isNone } from "@ember/utils";
import { action } from "@ember/object";
import { service } from "@ember/service";
import Controller from "@ember/controller";
import { task } from "ember-concurrency";
import { tracked } from "@glimmer/tracking";

export default class ServerIsoController extends Controller {
  @service ajax;
  @service intl;

  get project() {
    return this.model.project;
  }

  get server() {
    return this.model.server;
  }

  get files() {
    return this.model.iso.isofiles;
  }

  @tracked currentISO = this.server.isofile;

  get isoOptions() {
    let files = this.files;
    if (isNone(files)) {
      return [];
    }

    let groupNames = [];
    let groups = [{ value: "", label: this.intl.t("label.none") }];

    files.forEach((file) => {
      let [part] = file.split("/");
      if (groupNames.filter((g) => g === part).length === 0) {
        groupNames.push(part);
      }
    });

    groupNames.forEach((name) => {
      let obj = {
        groupName: name,
        items: files
          .filter((file) => {
            return file.indexOf(name) === 0;
          })
          .map((file) => {
            return {
              value: file,
              label: file.split("/").slice(1).join("/"),
            };
          }),
      };
      groups.push(obj);
    });

    return groups;
  }

  resetProperties() {
    this.currentISO = this.server.isofile;
  }

  mountISOTask = task(async () => {
    let { project, currentISO, server } = this;

    let url = `/io.php/projects/${project.id}/vps/servers/${server.id}/iso`;

    await this.ajax.post(url, {
      data: {
        currentISO,
      },
    });
  });

  @action
  selectISO(iso) {
    this.currentISO = iso.value;
  }
}

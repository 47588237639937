import { helper } from "@ember/component/helper";

export function projectColorBackgroundClass(color) {
  switch (color[0]) {
    case "skyblue":
      return "bg-powder-blue-lightest";
    case "midnightblue":
      return "bg-midnight-blue-lightest";
    case "lightcoral":
      return "bg-red-lightest";
    case "mediumaquamarine":
      return "bg-ocean-green-lightest";
    case "sandybrown":
      return "bg-orange-lightest";
    case "lemonchiffon":
      return "bg-yellow-lightest";
    case "rebeccapurple":
      return "bg-rebeccapurple-lightest";
    case "silver":
    default:
      return "bg-gray-lightest";
  }
}

export default helper(projectColorBackgroundClass);

import Component from "@glimmer/component";
import { task } from "ember-concurrency";
import { service } from "@ember/service";

export default class ModalsEmailAliasCreate extends Component {
  @service modal;
  @service current;

  get domain() {
    return this.args.params.domain;
  }

  get task() {
    return this.args.params.task;
  }

  email;
  recipient;

  submitTask = task(async () => {
    let task = this.task;
    let email = this.email;
    let recipient = this.recipient;

    await task.perform(email, recipient);

    this.modal.close();
  });
}

import Component from "@glimmer/component";
import { action } from "@ember/object";
import { dropTask, keepLatestTask } from "ember-concurrency";
import { tracked } from "@glimmer/tracking";
import { service } from "@ember/service";

export default class extends Component {
  @service ajax;
  @service notification;
  @service intl;
  @service modal;

  get server() {
    return this.args.params.server;
  }

  get project() {
    return this.args.params.project;
  }

  @tracked backupSchedules = [];

  @action
  updateSchedule(backupSchedules) {
    this.backupSchedules = backupSchedules;
    this.getKVMServerBackupCostTask.perform();
  }

  getKVMServerBackupCostTask = keepLatestTask(async () => {
    let projectId = this.project?.id;
    let url = `/io.php/projects/${projectId}/vps/servers/server/costs`;

    let numberOfBackupImagesFromSchedules = this.backupSchedules
      .filter((schedule) => schedule.enabled)
      .reduce((totalPrice, schedule) => totalPrice + schedule.numberofimagestokeep, 0);

    let data = {
      config: {
        serverid: this.server.id,
        cpucores: this.server.cpucores,
        disksize: this.server.disksize,
        memorysize: this.server.memorysize,
        numberofbackupimagestokeep: numberOfBackupImagesFromSchedules,
      },
    };

    try {
      let response = await this.ajax.post(url, { data });
      return {
        price: response.billing.price,
        total: response.billing.total,
      };
    } catch (_) {
      return {
        price: null,
      };
    }
  });

  configureBackupTask = dropTask(async () => {
    let { server } = this;

    const schedules = this.backupSchedules
      .filter((schedule) => schedule.enabled)
      .map(({ frequency, numberofimagestokeep }) => ({ frequency, numberofimagestokeep }));

    let config = {
      backup: {
        enabled: schedules.length > 0,
        schedules,
      },
    };

    server.setProperties(config);
    await server.save();

    this.modal.close();
    this.notification.add(
      "success",
      this.intl.t("server.details.backup.updated.title"),
      this.intl.t("server.details.backup.updated.content"),
    );
  });
}

import Component from "@glimmer/component";
import { action } from "@ember/object";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { bool, func, oneOf } from "prop-types";
import { tracked } from "@glimmer/tracking";

@forbidExtraArgs
export default class AtomDelete extends Component {
  @arg(func.isRequired) onDelete;
  @arg(bool) disabled = false;
  @arg(bool) requiresConfirmation = true;
  @arg(oneOf[("icon", "button")]) type = "icon";

  optionValues = {};
  @tracked showConfirm = false;

  get buttonClass() {
    let base = "size-6 inline-flex items-center";

    return this.disabled ? `${base} text-gray-light cursor-not-allowed` : `${base} text-red-light hover:text-red-dark`;
  }

  @action
  changeOption(option, value) {
    this.optionValues[option] = value;
  }

  @action
  toggleConfirm() {
    this.showConfirm = !this.showConfirm;
  }

  @action
  hideConfirm() {
    this.showConfirm = false;
  }

  @action
  confirm() {
    let options = this.optionValues;
    this.onDelete(options);
    this.showConfirm = false;
  }

  @action
  delete() {
    let options = this.optionValues;
    this.onDelete(options);
  }
}

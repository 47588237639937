import Component from "@glimmer/component";
import { Task, dropTask } from "ember-concurrency";
import { service } from "@ember/service";
import { arg } from "ember-arg-types";
import { instanceOf, shape } from "prop-types";
import { stringable } from "glesys-controlpanel/utils/prop-types";

export default class ModalsConfirmDelete extends Component {
  @service modal;

  @arg(
    shape({
      task: instanceOf(Task).isRequired,
      text: stringable,
    }),
  )
  params;

  get task() {
    return this.params.task;
  }

  get text() {
    return this.params.text;
  }

  submitTask = dropTask(async () => {
    await this.task.perform();
    this.modal.close();
  });
}

import { service } from "@ember/service";
import { hash } from "rsvp";
import Route from "@ember/routing/route";

export default class OrganizationSettingsRoute extends Route {
  @service ajax;
  @service abilities;
  @service router;

  buildRouteInfoMetadata() {
    return { jumpable: true };
  }

  model() {
    let { organization } = this.modelFor("organization");

    return hash({
      organization,
    });
  }

  redirect({ organization }) {
    if (this.abilities.cannot("access settings for organization", organization)) {
      this.router.transitionTo("organization");
    }
  }
}

import { service } from "@ember/service";
import Model, { attr, belongsTo, hasMany } from "@ember-data/model";
import dayjs from "dayjs";

export default class OrganizationModel extends Model {
  @service intl;

  @attr("yesno") isOwner;

  @attr("yesno") canAccessBilling;
  @attr("boolean") canPayInvoicesManuallyUsingPaypal;

  @attr("string") paymentMethod;

  get hasPaymentMethod() {
    return Boolean(this.paymentMethod);
  }

  get hasNoPaymentMethod() {
    return !this.hasPaymentMethod;
  }

  @attr availablePaymentMethods;
  @attr paymentCard;

  @attr("number") paymentTermsNetDays;
  @attr("string") vatNumber;

  get paymentCardLastFour() {
    return this.paymentCard?.lastfourdigits;
  }

  get paymentCardExpiryDate() {
    return this.paymentCard?.expirydate;
  }

  get hasExpiredPaymentCard() {
    return dayjs().isAfter(dayjs(this.paymentCardExpiryDate).endOf("day"));
  }

  // Contact
  @attr identityNumber;
  @attr name;
  @attr address;
  @attr zip;
  @attr city;
  @attr country;
  @attr phone;
  @attr email;

  @belongsTo("user", { async: false, inverse: "organizations" }) user;
  @hasMany("collaborator", { async: true, inverse: "organization" }) collaborators;
  @hasMany("invoice", { async: true, inverse: "organization" }) invoices;
  @hasMany("project", { async: true, inverse: "organization" }) projects;

  // Billing
  @attr("string") currentBillingEmails;
  @attr("string") billingEmailOverrides;

  // Invoice
  @attr("string") invoiceDeliveryMethod;
  @attr invoiceReference;
  @attr("yesno") separateInvoiceAddress;
  @attr invoiceAddress;
  @attr invoiceZip;
  @attr invoiceCity;
  @attr invoiceCountry;

  // State
  @attr("string") state;

  get isRestricted() {
    return this.state === "restricted";
  }

  get isActive() {
    return this.state === "active";
  }

  @attr verification;

  get isVerified() {
    return this.verification === "verified";
  }

  get isUnverified() {
    return !this.isVerified;
  }

  @attr("yesno") hasExpiredInvoices;
  @attr("yesno") hasUnpaidInvoices;

  @attr slaCost;
  @attr("string") slaLevel; // TODO: This should be implemented as a relationship
  @attr("string") slaPhoneNumber;
  @attr("string") slaPinCode;

  @attr("string") type;

  get isBusiness() {
    return this.type === "business";
  }

  get isPersonal() {
    return this.type === "personal";
  }

  @attr featureFlags;

  get displayName() {
    let name = this.name;
    let number = this.id;

    if (name) {
      return name;
    }

    return this.intl.t("organization.display-name", { number });
  }

  get slaIsExtended() {
    let slaLevel = this.slaLevel;
    return slaLevel && slaLevel !== "base";
  }

  get slaLevelTranslationString() {
    switch (this.slaLevel) {
      case "bronze":
        return this.intl.t("organization.sla.level.bronze");
      case "gold":
        return this.intl.t("organization.sla.level.gold");
      case "platinum":
        return this.intl.t("organization.sla.level.platinum");
      default:
        return "";
    }
  }

  get hasNotices() {
    if (this.hasNoPaymentMethod) {
      return true;
    }

    if (this.hasExpiredInvoices) {
      return true;
    }

    if (this.paymentMethod === "card" && this.hasExpiredPaymentCard) {
      return true;
    }

    return false;
  }
}

import Route from "@ember/routing/route";
export default class PrivateNetworksOverviewRoute extends Route {
  buildRouteInfoMetadata() {
    return { jumpable: true };
  }

  async model() {
    let { project } = this.modelFor("organization");
    let privateNetworks = await project.get("privateNetworks");

    return {
      privateNetworks,
      project,
    };
  }
}

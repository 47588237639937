import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";

export default class extends Component {
  server = this.args.server || null;

  costTask = this.args.costTask;

  project = this.args.project;

  onChange = this.args.onChange;

  get validSchedules() {
    return [
      {
        frequency: "daily",
        defaultNumberOfImagesToKeep: 7,
        defaultEnabled: false,
        defaultAvailableOptions: [...Array(33).keys()].slice(1),
      },
      {
        frequency: "weekly",
        defaultNumberOfImagesToKeep: 4,
        defaultEnabled: false,
        defaultAvailableOptions: [...Array(65).keys()].slice(1),
      },
    ];
  }

  get shouldShowPrice() {
    return false;
  }

  @tracked backupSchedules = [];

  @action
  getBackupSchedules() {
    const translations = {
      daily: "server.details.backup.schedules.daily",
      weekly: "server.details.backup.schedules.weekly",
      monthly: "server.details.backup.schedules.monthly",
    };

    this.backupSchedules = this.validSchedules
      .map((schedule) => {
        let sch = this.server?.backup.schedules.find((s) => s.frequency === schedule.frequency);
        return {
          frequency: schedule.frequency,
          enabled: sch ? Boolean(sch) : schedule.defaultEnabled,
          editable: true,
          numberofimagestokeep: sch?.numberofimagestokeep || schedule.defaultNumberOfImagesToKeep,
          translationString: translations[schedule.frequency] || "server.details.backup.schedules.custom",
          availableOptions: schedule.defaultAvailableOptions,
        };
      })
      .concat(
        this.server?.backup.schedules
          .filter((x) => !this.validSchedules.find((el) => el.frequency === x.frequency))
          .map((schedule) => {
            return {
              enabled: true,
              editable: false,
              translationString: translations[schedule.frequency] || "server.details.backup.schedules.custom",
              availableOptions: schedule.defaultAvailableOptions,
              ...schedule,
            };
          }),
      )
      .filter((x) => x);

    this.args.onChange && this.args.onChange(this.backupSchedules);
  }

  @action
  toggleSchedule(updateSchedule, event) {
    updateSchedule.enabled = event.target.checked;
    this.updateSchedule(updateSchedule, updateSchedule.numberofimagestokeep);
  }

  @action
  updateSchedule(updateSchedule, numberOfImagesToKeep) {
    this.backupSchedules = this.backupSchedules.map((schedule) => {
      if (schedule.frequency === updateSchedule.frequency) {
        return {
          ...updateSchedule,
          numberofimagestokeep: numberOfImagesToKeep,
        };
      }

      return schedule;
    });

    this.args.onChange && this.args.onChange(this.backupSchedules);
  }
}

import Component from "@glimmer/component";
import arrayDiff from "glesys-controlpanel/utils/array-diff";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { array, bool, oneOf } from "prop-types";
import { cached } from "@glimmer/tracking";

@forbidExtraArgs
export default class DiffView extends Component {
  @arg(array.isRequired) new;

  @arg(array.isRequired) old;

  @arg(oneOf(["light", "dark"])) theme = "light";

  @arg(bool) showLineNumbers = false;

  @cached
  get diff() {
    return arrayDiff(this.new, this.old);
  }
}

import Controller from "@ember/controller";
import { action } from "@ember/object";
import { all, task } from "ember-concurrency";
import { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";

export default class EmailDomainAccountController extends Controller {
  @service router;
  @service cost;
  @service ajax;

  @tracked quota = this.account.quotaingib;

  get account() {
    return this.model.account;
  }

  get autoRespondEmailWillBeDiscarded() {
    return !this.account.autorespondsaveemail;
  }

  get quotaValues() {
    const r = []
      .concat([this.account.quotaingib])
      .concat([...Array(101).keys()].slice(1))
      .sort((a, b) => a - b);

    return [...new Set(r)];
  }

  get accountCost() {
    return this.cost.forEmailAccount(this.quota);
  }

  updateAccountTask = task(async () => {
    let account = this.account;
    return account.save().catch((error) => {
      account.rollbackAttributes();
      throw error;
    });
  });

  updateQuotaTask = task(async () => {
    // We don't want to save all other changed attributes, so we need to rollback them first,
    // and put them back as soon as we haved kicked of the request to the backend.
    let changedAttributes = this.account.changedAttributes();
    this.account.rollbackAttributes();
    let currentQuota = this.account.quotaingib;
    this.account.quotaingib = this.quota;

    let request = this.account.save().catch((error) => {
      this.quota = currentQuota;
      this.account.quotaingib = this.quota;
      throw error;
    });

    Object.keys(changedAttributes).forEach((key) => this.account.set(key, changedAttributes[key][1]));

    await all([request]);
  });

  deleteEmailAccountTask = task(async (emailAccount) => {
    let domainId = this.account.get("domain.id");
    return emailAccount
      .destroyRecord()
      .then(() => {
        this.router.transitionTo("email.domain", domainId);
      })
      .catch((error) => {
        emailAccount.rollbackAttributes();
        throw error;
      });
  });

  resetPasswordTask = task(async () => {
    let domain = await this.account.get("domain");
    let context = domain.get("account.id");
    return this.ajax.post("/io.php/email/resetpassword", {
      data: {
        emailaccount: this.account.id,
        context,
      },
    });
  });

  @action
  updateQuota(value) {
    this.quota = value;
  }

  @action
  deleteAccount() {
    let account = this.account;
    let domain = account.get("domain");
    account.deleteRecord();
    account
      .save()
      .then(() => {
        this.router.transitionTo("email.domain", domain.get("id"));
      })
      .catch((error) => {
        account.rollbackAttributes();
        throw error;
      });
  }

  @action
  onFormChange({ autorespondmessage, antivirus, rejectspam, autorespond }) {
    this.account.autorespondmessage = autorespondmessage;
    this.account.antivirus = Boolean(antivirus);
    this.account.rejectspam = Boolean(rejectspam);
    this.account.autorespond = Boolean(autorespond);
  }

  @action
  antispamlevelChange(level) {
    this.account.antispamlevel = level;
  }

  setupController() {
    this.quota = this.account.quotaingib;
    this.resetPasswordTask.cancelAll({ resetState: true });
  }
}

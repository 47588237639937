export default (arr, key) => {
  return arr.reduce((acc, curr) => {
    let group = acc.find((g) => g.value === curr[key]);
    if (!group) {
      group = { property: key, value: curr[key], items: [] };
      acc.push(group);
    }
    group.items.push(curr);

    return acc;
  }, []);
};

import Controller from "@ember/controller";
import { service } from "@ember/service";

export default class IpsController extends Controller {
  @service intl;

  get tabs() {
    return [
      { name: this.intl.t("ips.tabs.overview"), route: "ips.overview" },
      { name: this.intl.t("ips.tabs.reserve"), route: "ips.reserve" },
      { name: this.intl.t("ips.tabs.cost"), route: "ips.cost" },
    ];
  }
}

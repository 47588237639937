import Route from "@ember/routing/route";
export default class PrivateNetworksDetailsRoute extends Route {
  async model(params) {
    let { project } = this.modelFor("organization");
    let privateNetworks = await project.get("privateNetworks");

    return {
      privateNetwork: privateNetworks.find((privateNetwork) => privateNetwork.id === params.private_network_id),
    };
  }
}

import hbs from "htmlbars-inline-precompile";
import { action } from "@storybook/addon-actions";

export default {
  title: "Checkbox",
  component: "Checkbox",
};

export const Default = (args) => ({
  template: hbs`
    <Checkbox checked={{this.checked}} disabled={{this.disabled}} {{on "change" this.onChange}}>
      {{this.label}}
    </Checkbox>
  `,
  context: args,
});

Default.args = {
  checked: false,
  disabled: false,
  label: "Check me!",
  onChange: action("Changed"),
};

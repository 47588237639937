import Model, { attr, belongsTo } from "@ember-data/model";

export default class EmailAliasModel extends Model {
  @attr("string") emailalias;
  @attr displayname;
  @attr goto;

  @belongsTo("email-domain", { async: false, inverse: "aliases" }) domain;

  get recipients() {
    let goto = this.goto;
    if (goto) {
      return goto.split(",");
    }
    return [];
  }
}

import { hash } from "rsvp";
import Route from "@ember/routing/route";
import ENV from "glesys-controlpanel/config/environment";
import { task, timeout, all } from "ember-concurrency";

export default class ServerNetworkAdaptersRoute extends Route {
  model() {
    let { project, server } = this.modelFor("server");
    let privateNetworks = project.get("privateNetworks");

    return hash({
      networks: project.get("networks"),
      server,
      privateNetworks,
    });
  }

  setupController() {
    super.setupController(...arguments);

    this.updateAdapterTask.perform();
  }

  resetController() {
    super.resetController(...arguments);

    this.updateAdapterTask.cancelAll();
  }

  /* istanbul ignore next */
  updateAdapterTask = task(async () => {
    if (ENV.environment === "test") {
      return;
    }

    let { server } = this.modelFor(this.routeName);
    // eslint-disable-next-line no-constant-condition
    while (true) {
      let networkAdapters = await server.networkAdapters;
      let lockedAdapters = networkAdapters.filter((networkAdapter) => !networkAdapter.isReady);
      let interval = lockedAdapters.length > 0 ? 4 : 6;
      await timeout(interval * 1000);
      await all(
        lockedAdapters.map((adapter) => {
          return adapter.reload();
        }),
      );
    }
  });
}

import Controller from "@ember/controller";
import { task } from "ember-concurrency";
import { service } from "@ember/service";

export default class LoadBalancersOverviewController extends Controller {
  @service router;
  @service store;

  get project() {
    return this.model.project;
  }

  get loadBalancers() {
    return this.model.loadBalancers;
  }

  createLoadBalancerTask = task(async (name, dataCenter) => {
    let project = this.project;

    let loadBalancer = this.store.createRecord("load-balancer", {
      project,
      name,
      datacenter: dataCenter,
    });

    await loadBalancer.save().catch((error) => {
      loadBalancer.unloadRecord();
      throw error;
    });

    this.router.transitionTo("load-balancer", loadBalancer.id);
  });
}

import Component from "@glimmer/component";
import { service } from "@ember/service";
import { task } from "ember-concurrency";

export default class ModalsGenerateAuthCode extends Component {
  @service modal;
  @service ajax;

  get domain() {
    return this.args.params.domain;
  }

  generateAuthCode = task(async () => {
    return await this.ajax.post("/io.php/domain/generateauthcode", {
      data: { domainname: this.domain.domainname },
    });
  });
}

import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { bool } from "prop-types";

@forbidExtraArgs
export default class Box extends Component {
  @arg(bool) collapsable = false;

  @tracked open = false;

  get isOpen() {
    return !this.collapsable || this.open;
  }

  @action
  toggle() {
    this.open = !this.open;
  }
}

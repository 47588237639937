import { Ability } from "ember-can";

export default class DnsDomainAbility extends Ability {
  get dnsDomain() {
    return this.model;
  }

  get canChangeNameservers() {
    let state = this.dnsDomain.registrarinfo?.state;
    let validStates = ["OK", "EXPIRING60", "EXPIRING30", "EXPIRING5", "CHANGENAMESERVERSFAIL"];
    return !!this.dnsDomain.registrarinfo && validStates.includes(state);
  }
}

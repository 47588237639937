import ApplicationSerializer from "./application";

export default class ArchiveSerializer extends ApplicationSerializer {
  primaryKey = "username";

  normalize(modelClass, resourceHash, prop) {
    resourceHash.sizetotal = parseInt(resourceHash.sizetotal);
    resourceHash.size = resourceHash.sizetotal;

    if (resourceHash.sizefree) {
      resourceHash.sizefree = parseFloat(resourceHash.sizefree);
      resourceHash.free = resourceHash.sizefree;
    }

    return super.normalize(modelClass, resourceHash, prop);
  }
}

import Controller from "@ember/controller";
import { service } from "@ember/service";
import { task } from "ember-concurrency";
import { action, set } from "@ember/object";
import { tracked } from "@glimmer/tracking";

export default class extends Controller {
  @service intl;
  @service ajax;
  @service cost;

  get organizationId() {
    return this.model.organization.id;
  }

  get organizationName() {
    return this.model.organization.name;
  }

  get username() {
    return this.model.user.username;
  }

  get remoteHandsCost() {
    return this.cost.forRemoteHands();
  }

  @tracked billingReference = null;
  @tracked machineId = null;
  @tracked location = null;

  @tracked workCategories = {
    other: true,
    diskreplacement: false,
    rackmounting: false,
    installation: false,
  };

  @tracked message = null;

  @action
  onFormChange({ message }) {
    this.message = message;
  }

  @action
  setWorkCategories(category) {
    set(this.workCategories, category, !this.workCategories[category]);
  }

  requestForRemoteHands = task(async () => {
    await this.ajax.post(`/io.php/organizations/${this.organizationId}/remotehands`, {
      data: {
        organization: `${this.organizationId} - ${this.organizationName}`,
        billingreference: this.billingReference,
        machine: this.machineId,
        location: this.location,
        workcategories: Object.keys(this.workCategories)
          .filter((category) => this.workCategories[category])
          .join(", "),
        message: this.message,
      },
    });
    this.billingReference = null;
    this.machineId = null;
    this.location = null;
    this.workCategories = {
      other: true,
      diskreplacement: false,
      rackmounting: false,
      installation: false,
    };
    this.message = null;
  });
}

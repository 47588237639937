import Route from "@ember/routing/route";
import { hash } from "rsvp";

export default class GitLabRoute extends Route {
  buildRouteInfoMetadata() {
    return { jumpable: true };
  }

  model() {
    let { project } = this.modelFor("organization");
    return hash({ project });
  }

  setupController(controller) {
    super.setupController(...arguments);

    controller.submit.cancelAll({ resetState: true });
    controller.resetProperties();
  }
}

class ArrayDiff {
  newArray = [];
  oldArray = [];
  diff = [];

  constructor(newArray, oldArray) {
    this.newArray = newArray;
    this.oldArray = oldArray;
  }

  newLinesAdded(before) {
    return this.diff.filter((x) => x.line < before && x.type === "added").length;
  }

  newLinesRemoved(before) {
    return this.diff.filter((x) => x.line < before && x.type === "removed").length;
  }

  isNewLine(line) {
    let value = line.newValue;

    if (value === undefined) {
      return false;
    }

    let alreadyAdded = this.diff.filter((x) => x.value === value && x.type === "added").length;
    let newOccurrencesOfValue = this.newArray.filter((x) => x === value).length - alreadyAdded;
    let oldOcurrencesOfValue = this.oldArray.filter((x) => x === value).length;

    return newOccurrencesOfValue > oldOcurrencesOfValue;
  }

  isRemovedLine(line) {
    let value = line.oldValue;
    if (value === undefined) {
      return false;
    }

    let alreadyRemoved = this.diff.filter((x) => x.value === value && x.type === "removed").length;
    let newOccurrencesOfValue = this.newArray.filter((x) => x === value).length;
    let oldOcurrencesOfValue = this.oldArray.filter((x) => x === value).length - alreadyRemoved;

    return newOccurrencesOfValue < oldOcurrencesOfValue;
  }

  findOldLine(line) {
    return this.oldArray.findIndex((x) => x === line.newValue);
  }

  getLineDiffBetweenLines(line) {
    let oldLineNewIndex = this.findOldLine(line) + this.newLinesAdded(line.line);
    let newLine = line.line;
    let newLineNewIndex = newLine + this.newLinesRemoved(line.line);

    return Math.abs(oldLineNewIndex - newLineNewIndex);
  }

  isMovedLine(line) {
    let newLine = line.line;
    let newLineNewIndex = newLine + this.newLinesAdded(line.line);
    let isNotLastLineInNewArray = newLineNewIndex < this.newArray.length - 1;
    return this.getLineDiffBetweenLines(line) > 0 && isNotLastLineInNewArray;
  }

  findOldLinesNewLine(line) {
    return this.newArray.findIndex((x) => x === line.oldValue);
  }

  isOldLineMoved(line) {
    let previousLinesNewLine = this.findOldLinesNewLine(line);
    let lineDiff = this.getLineDiffBetweenLines(line);
    return lineDiff < previousLinesNewLine;
  }

  isAlreadyMarked(line) {
    return this.diff.find((x) => x.line === line.line && x.value === line.newValue);
  }

  markLine(line, options = {}) {
    return this.diff.push({
      line: line.line,
      value: line.newValue,
      type: "unchanged",
      ...options,
    });
  }

  check() {
    let nrOfLines = Math.max(this.newArray.length, this.oldArray.length);

    Array.from(Array(nrOfLines).keys())
      .map((line) => {
        let newValue = this.newArray[line];
        let oldValue = this.oldArray[line];

        return {
          line,
          newValue,
          oldValue,
          changed: newValue !== oldValue,
        };
      })
      .forEach((line) => {
        if (!line.changed) {
          return this.markLine(line, { type: "unchanged" });
        }

        if (this.isNewLine(line)) {
          this.markLine(line, { type: "added" });
        }

        if (this.isRemovedLine(line)) {
          this.markLine(line, { type: "removed", value: line.oldValue });

          if (!this.isAlreadyMarked(line) && line.newValue !== undefined) {
            this.markLine(line, { type: "unchanged" });
          }

          return;
        }

        if (this.isAlreadyMarked(line)) {
          return;
        }

        if (line.newValue === undefined) {
          return;
        }

        if (this.isMovedLine(line)) {
          let lines = [];
          if (this.isOldLineMoved(line)) {
            let previousLinesNewLine = this.findOldLinesNewLine(line);
            lines.push({ line, options: { type: "added", line: previousLinesNewLine, value: line.oldValue } });
            lines.push({ line, options: { type: "removed", value: line.oldValue } });
            lines.push({ line, options: { type: "unchanged" } });
          } else {
            let oldLine = this.findOldLine(line);
            lines.push({ line, options: { type: "added" } });
            lines.push({ line, options: { type: "removed", line: oldLine } });
          }
          lines.forEach((line) => this.markLine(line.line, line.options));

          return;
        }

        this.markLine(line, { type: "unchanged" });
      });

    return this.diff.sort((a, b) => {
      if (a.line === b.line) {
        switch (true) {
          case a.type === "added":
            return -1;
          case b.type === "added":
            return 1;
          case a.line === 0 && a.type === "removed":
            return -1;
          case b.line === 0 && b.type === "removed":
            return 1;
          case a.type === "removed":
            return 1;
          case b.type === "removed":
            return -1;
        }
      }
      return a.line - b.line;
    });
  }
}

export default function arrayDiff(newArray, oldArray) {
  return new ArrayDiff(newArray, oldArray).check();
}

import { template } from "@ember/template-compiler";
import GButton from "./";
import mergeClasses from "glesys-controlpanel/modifiers/merge-classes";
import IconsPlus from "../icons/plus";
export default template(`
  <GButton
    @type={{@type}}
    @color="outline"
    @disabled={{@disabled}}
    @loading={{@loading}}
    @display={{if @display @display "default"}}
    class="h-10"
    {{mergeClasses}}
    ...attributes
  >
    <div class="flex items-center">
      <IconsPlus class="w-5 mr-1 -ml-1.5 -mt-px text-ocean-green" />
      <span>{{yield}}</span>
    </div>
  </GButton>
`, {
    eval () {
        return eval(arguments[0]);
    }
});

import Component from "@glimmer/component";
import generatePassword from "glesys-controlpanel/utils/generate-password";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { bool } from "prop-types";
import { action } from "@ember/object";
import { tracked } from "@glimmer/tracking";
import { modifier } from "ember-modifier";

@forbidExtraArgs
export default class PasswordInput extends Component {
  @arg(bool) enableGenerate = false;

  element;
  @tracked type = "password";

  storeElement = modifier((element) => {
    this.element = element;

    return () => (this.element = null);
  });

  @action
  toggleType() {
    this.type = this.type == "text" ? "password" : "text";
  }

  @action
  generatePassword() {
    this.type = "text";
    this.element.value = generatePassword(10);
    this.element.dispatchEvent(new Event("input", { bubbles: true }));
  }
}

import Model, { attr, belongsTo } from "@ember-data/model";

export default class ApiKeyModel extends Model {
  @attr allowedhosts;
  @attr apikey;
  @attr description;
  @attr permissions;

  @belongsTo("project", { async: false, inverse: "apiKeys" }) project;
}

import Component from "@glimmer/component";
import { service } from "@ember/service";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { arrayOf, instanceOf } from "prop-types";
import { action } from "@ember/object";
import OrganizationModel from "glesys-controlpanel/models/organization";

@forbidExtraArgs
export default class Sidebar extends Component {
  @service session;
  @service current;
  @service router;

  @arg(arrayOf(instanceOf(OrganizationModel)).isRequired) organizations;

  get organization() {
    return this.current.organization;
  }

  get project() {
    return this.current.project;
  }

  @action
  projectChanged(project) {
    if (project) {
      this.current.project = project;

      let findJumpable = function (route) {
        if (route.metadata?.jumpable) {
          return route.name;
        }
        if (route.parent) {
          return findJumpable(route.parent);
        }
        return "organization.overview";
      };

      let routeName = findJumpable(this.router.currentRoute);
      this.router.transitionTo(routeName, { queryParams: { project: project?.id } });
    }
  }
}

import Route from "@ember/routing/route";
import { service } from "@ember/service";
import ENV from "glesys-controlpanel/config/environment";

export default class OrganizationAddPaymentMethodRoute extends Route {
  @service ajax;
  @service abilities;
  @service router;

  buildRouteInfoMetadata() {
    return { showOrganizationNotices: false };
  }

  deactivate() {
    let organization = this.currentModel.organization;
    // NOTE: The organization has to be reloaded as a card may have been added in DIBS.
    // If we are running in a test environment, we don't want to reload the organization
    // since it will trigger a request after the test application is teared down which means mirage isn't intercepting requests anymore.
    if (!organization?.isDestroying && ENV.environment !== "test") {
      organization.reload();
    }
  }

  resetController(controller, ...args) {
    super.resetController(controller, ...args);
    controller.set("url", null);
  }

  model() {
    let { organization } = this.modelFor("organization");
    return { organization };
  }

  redirect({ organization }) {
    if (this.abilities.cannot("access settings for organization", organization)) {
      this.router.transitionTo("organization");
    }
  }
}

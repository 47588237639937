import Controller from "@ember/controller";
import { service } from "@ember/service";

export default class OrganizationOverviewDashboardController extends Controller {
  @service session;
  @service intl;

  get organization() {
    return this.model.organization;
  }

  get user() {
    return this.session.currentUser;
  }

  get currentUserIsOwner() {
    return this.organization.isOwner;
  }
}

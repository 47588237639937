import { isNone } from "@ember/utils";
import Model, { attr, belongsTo } from "@ember-data/model";

export default class EmailAccountModel extends Model {
  @attr("string") emailaccount;
  @attr("string") displayname;

  @attr("number") antispamlevel;
  @attr("yesno") antivirus;
  @attr("yesno") autorespond;
  @attr autorespondmessage;
  @attr("yesno") autorespondsaveemail;
  @attr("date") created;
  @attr("yesno") rejectspam;
  @attr quotaingib;
  @attr usedQuota;

  @attr password;

  @belongsTo("email-domain", { async: false, inverse: "accounts" }) domain;

  get quotaPercentage() {
    let { usedQuota, quotaingib } = this;
    if (isNone(usedQuota)) {
      return 0;
    }
    return Math.round((usedQuota / quotaingib) * 100);
  }
}

import { service } from "@ember/service";
import Route from "@ember/routing/route";
import { isNone } from "@ember/utils";
import { restartableTask, all, timeout } from "ember-concurrency";
import ENV from "glesys-controlpanel/config/environment";

export default class LoadBalancerStatisticsRoute extends Route {
  @service ajax;

  async setupController(controller, model) {
    super.setupController(controller, model);
    controller.set("model", model);
    let listeners = await model.loadBalancer.get("listeners");
    controller.set("selectedListener", listeners.at(0));

    this.fetchLoadbalancerData.perform();
  }

  /* istanbul ignore next */
  resetController() {
    super.resetController(...arguments);
    this.fetchLoadbalancerData.cancelAll();
  }

  fetchLoadbalancerData = restartableTask(async () => {
    let { project, loadBalancer } = this.modelFor(this.routeName);
    let listeners = loadBalancer.get("listeners");
    let interval = 1 * 1000;
    let maxMetrics = 60;

    listeners.forEach((listener) => {
      /* istanbul ignore else */
      if (isNone(listener.get("metrics"))) {
        listener.set("metrics", []);
      }
    });

    // eslint-disable-next-line no-constant-condition
    while (true) {
      await all(
        listeners.map((listener) => {
          let url = `/io.php/projects/${project.id}/loadbalancers/${loadBalancer.id}/frontends/${listener.id}/status`;

          return this.ajax.request(url).then((response) => {
            let metrics = Object.assign([], listener.get("metrics"));
            /* istanbul ignore if */
            if (metrics.length > maxMetrics) {
              let n = metrics.length - maxMetrics;
              metrics.splice(-n, n);
            }
            metrics.push([new Date().getTime(), response.currentsessions]);
            listener.set("metrics", metrics);
          });
        }),
      );

      /* istanbul ignore else */
      if (ENV.environment === "test") {
        return;
      }

      /* istanbul ignore next */
      await timeout(interval);
      /* istanbul ignore next */
      interval = 30 * 1000;
    }
  });
}

import { Ability } from "ember-can";
import { service } from "@ember/service";

export default class UserAbility extends Ability {
  @service storage;

  get canAccessThemeSelector() {
    return this.storage.get("glesys:cloud-theme-selector")
      ? this.storage.get("glesys:cloud-theme-selector") !== "false"
      : this.model
          ?.hasMany("organizations")
          .value()
          ?.filter((organization) => organization.featureFlags?.includes("cloud-theme-selector")).length;
  }
}

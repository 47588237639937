import Component from "@glimmer/component";
import { action } from "@ember/object";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { func, number, shape } from "prop-types";
import { stringable } from "glesys-controlpanel/utils/prop-types";

let planPropType = shape({
  id: stringable,
  cost: shape({ currency: stringable, price: number, total: number }),
  quota: number,
});
@forbidExtraArgs
export default class SelectablePlanItem extends Component {
  @arg(number.isRequired) index;
  @arg(planPropType.isRequired) plan;
  @arg(planPropType) selectedPlan;
  @arg(func.isRequired) onToggle;

  get currencyIsSek() {
    let currency = this.plan.cost.currency;
    return `${currency}`.toLowerCase() === "sek";
  }

  get isStriped() {
    if (this.isSelected) {
      return false;
    }

    let index = this.index * 1;
    return index % 2 === 0;
  }

  get isSelected() {
    let selectedPlan = this.selectedPlan;
    return selectedPlan && selectedPlan.planid === this.plan.planid;
  }

  get perGibPrice() {
    let plan = this.plan;

    return ((plan.cost.total * 1.0) / Math.max(1, plan.quota)).toFixed(2);
  }

  get size() {
    let quota = parseInt(this.plan.quota, 10);
    return quota < 1024 ? quota : Math.round(quota / 1024);
  }

  get unit() {
    let quota = parseInt(this.plan.quota, 10);
    return quota < 1024 ? "GiB" : "TiB";
  }

  @action
  onClick() {
    this.onToggle(this.plan);
  }
}

import Model, { attr, belongsTo } from "@ember-data/model";

export default class ServerBackupModel extends Model {
  @attr("string") frequency;
  @attr("string") type;
  @attr("date") createdAt;
  @attr("date") completedAt;
  @attr("date") snapshotTakenAt;
  @attr("number") diskInGiB;

  @belongsTo("server", { async: false, inverse: "backups" }) server;

  get frequencyTranslationKey() {
    switch (this.frequency) {
      case "daily":
        return "server.details.backup.schedules.daily";
      case "weekly":
        return "server.details.backup.schedules.weekly";
      case "monthly":
        return "server.details.backup.schedules.monthly";
      default:
        return "server.details.backup.schedules.custom";
    }
  }
}

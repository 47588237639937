import Controller from "@ember/controller";
import { compare } from "@ember/utils";
import { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";

export default class DnsOverviewController extends Controller {
  @service ajax;

  @service errorHandler;

  get project() {
    return this.model.project;
  }

  get pricelist() {
    return this.model.pricelist;
  }

  get domains() {
    return this.model.domains;
  }

  stateSortOrder = Object.freeze([
    "glesys-failed",
    "glesys-expire",
    "glesys-waiting",
    "glesys-unknown",
    "glesys-ok",
    "other-ok",
  ]);

  get sortedDomains() {
    return this.domains.slice().sort((a, b) => {
      let stateOrder = this.stateSortOrder;

      let aValue = `${stateOrder.indexOf(a.get("state"))}~${a.get("displayname")}`;
      let bValue = `${stateOrder.indexOf(b.get("state"))}~${b.get("displayname")}`;

      return compare(aValue, bValue);
    });
  }

  @tracked filter = "";

  get filteredDomains() {
    let domains = this.sortedDomains;
    let filter = this.filter;

    if (filter.length) {
      return domains.filter((domain) => {
        let { id, displayname } = domain;
        return `${id}~${displayname}`.indexOf(filter) > -1;
      });
    }

    return domains;
  }
}

import { hash } from "rsvp";
import Route from "@ember/routing/route";
import { service } from "@ember/service";

export default class DashboardInviteRoute extends Route {
  @service store;

  model(params) {
    return hash({
      invite: this.store.findRecord("invite", params.invite_code),
    });
  }
}

import Component from "@glimmer/component";
import { task } from "ember-concurrency";
import { service } from "@ember/service";
import { action } from "@ember/object";

export default class extends Component {
  @service ajax;

  instanceId = this.args.instanceId;
  project = this.args.project;

  get cost() {
    return this.fetchEstimatedCostForInstance?.lastSuccessful?.value.billing;
  }

  @action
  refetchEstimatedCost() {
    this.fetchEstimatedCostForInstance.perform();
  }

  fetchEstimatedCostForInstance = task(async () => {
    return this.ajax.request(`/io.php/projects/${this.project.id}/object-storage/instances/${this.instanceId}/cost`);
  });
}

import Controller from "@ember/controller";
import { task } from "ember-concurrency";
import { service } from "@ember/service";

export default class IpsOverviewController extends Controller {
  @service http;

  get project() {
    return this.model.project;
  }

  loadIpCosts = task(async () => {
    return this.http.request({ url: `/io.php/ip/estimatedcost?context=${this.project.id}` });
  });
}

import hbs from "htmlbars-inline-precompile";

export default {
  title: "GInput",
  component: "GInput",
  argTypes: {
    type: {
      options: [null, "text", "number", "password", "email", "search"],
      control: {
        type: "select",
      },
    },
  },
};

export const Default = (args) => ({
  template: hbs`
      <GInput
        @useLegacyInput={{true}}
        @value={{this.value}}
        @type={{this.type}}
      />
    `,
  context: args,
});

Default.args = {
  value: "Text Input",
  type: null,
};

import ApplicationAdapter, { projectChildMixin } from "./application";

export default class EmailDomainAdapter extends ApplicationAdapter.extend(projectChildMixin) {
  uriSuffix = "email/domains";

  buildURL(modelName, id, snapshot, requestType, query) {
    switch (requestType) {
      case "query":
        return this.urlForQuery(query, modelName);
      case "queryRecord":
        return this.urlForQueryRecord(query, modelName);
    }
    return super.buildURL(modelName, id, snapshot, requestType, query);
  }

  urlForQuery(query) {
    let url = `${this.baseUri()}/projects/${query.project}/email/domains`;
    return url;
  }

  urlForQueryRecord(query) {
    let url = `${this.baseUri()}/projects/${query.project}/email/domains/${query.id}`;
    return url;
  }

  fetchMailServer(model) {
    let baseUri = this._baseUri(model);
    let url = `${baseUri}/mailserver`;

    return this.ajax(url, "GET")
      .then((emailDomain) => {
        return this.store.pushPayload(model.constructor.modelName, {
          emailDomain,
        });
      })
      .catch(() => {});
  }
}

import Controller from "@ember/controller";
import { task } from "ember-concurrency";
import { service } from "@ember/service";

export default class LoadBalancerController extends Controller {
  @service modal;
  @service intl;
  @service router;

  get loadBalancer() {
    return this.model.loadBalancer;
  }

  get tabs() {
    return [
      { name: this.intl.t("link.load-balancer.details"), route: "load-balancer.details" },
      { name: this.intl.t("link.load-balancer.advanced"), route: "load-balancer.advanced" },
      { name: this.intl.t("link.load-balancer.statistics"), route: "load-balancer.statistics" },
      { name: this.intl.t("link.load-balancer.errors"), route: "load-balancer.errors" },
    ];
  }

  deleteTask = task(async () => {
    try {
      this.loadBalancer.deleteRecord();
      await this.loadBalancer.save();
    } catch (error) {
      this.loadBalancer.rollbackAttributes();
      throw error;
    }

    this.router.transitionTo("load-balancers");
  });
}

import ApplicationSerializer from "./application";

export default class ReservedIpSerializer extends ApplicationSerializer {
  primaryKey = "address";

  attrs = {
    address: "ipaddress",
    lockedToProject: "lockedtoaccount",
    server: "serverid",
    dataCenter: "datacenter",
  };

  normalize(modelClass, resourceHash, prop) {
    resourceHash.project = resourceHash.projectkey ?? resourceHash.project ?? resourceHash.account;

    return super.normalize(modelClass, resourceHash, prop);
  }
}

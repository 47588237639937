import ApplicationAdapter from "./application";

export default class PrivateNetworkSegmentAdapter extends ApplicationAdapter {
  urlForCreateRecord() {
    return `${this.baseUri()}/privatenetwork/createsegment`;
  }

  urlForUpdateRecord(id) {
    return `${this.baseUri()}/privatenetwork/editsegment?id=${id}`;
  }

  urlForDeleteRecord(id) {
    return `${this.baseUri()}/privatenetwork/deletesegment?id=${id}`;
  }
}

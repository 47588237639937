import Controller from "@ember/controller";

export default class FileStorageVolumesOverviewController extends Controller {
  get project() {
    return this.model.project;
  }

  get fileStorageVolumes() {
    return this.model.fileStorageVolumes;
  }

  get sortedVolumes() {
    return this.fileStorageVolumes.slice().sort((a, b) => b.usedPercentage - a.usedPercentage);
  }
}

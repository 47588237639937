import Ember from "ember";
import Bugsnag from "@bugsnag/js";

export function initialize(appInstance) {
  let session = appInstance.lookup("service:session");

  Ember.onerror = (error) => {
    let user = session.currentUser;
    Bugsnag.setUser(user?.id, user?.username, user?.name);

    Bugsnag.notify(error);
  };
}

export default {
  name: "bugsnag",
  initialize,
};

import { hash } from "rsvp";
import { service } from "@ember/service";
import Route from "@ember/routing/route";

export default class ServerCreateRoute extends Route {
  @service metrics;

  activate() {
    this.metrics.trackEvent("Intercom", { event: "visited-create-server" });
  }

  buildRouteInfoMetadata() {
    return { jumpable: true };
  }

  model() {
    let { project } = this.modelFor("organization");
    return hash({
      project,
      reservedIps: project.get("reservedIps").reload(),
      platformArguments: project.get("platformArguments"),
    });
  }

  setupController(controller) {
    super.setupController(...arguments);
    controller.resetProperties();
    controller.setDefaultValues();
    controller.setTabs();
  }
}

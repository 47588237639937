import Controller from "@ember/controller";
import { task } from "ember-concurrency";
import { service } from "@ember/service";

export default class ArchivesOverviewController extends Controller {
  @service store;

  get project() {
    return this.model.project;
  }

  get archiveArguments() {
    return this.model.archiveArguments;
  }

  get archives() {
    return this.model.archives;
  }

  get archivesFiltered() {
    return this.archives.filter((archive) => !archive.isNew);
  }

  createArchiveTask = task(async (username, password, size) => {
    let project = this.project;

    let archive = this.store.createRecord("archive", {
      project,
      username,
      password,
      size,
    });

    await archive.save().catch((error) => {
      archive.unloadRecord();
      throw error;
    });
  });
}

import { hash } from "rsvp";
import Route from "@ember/routing/route";

export default class NetworkCircuitsRoute extends Route {
  buildRouteInfoMetadata() {
    return { jumpable: true };
  }

  model() {
    let { project } = this.modelFor("organization");
    let networkCircuits = project.get("networkCircuits");

    return hash({ project, networkCircuits });
  }
}

import Model, { attr, belongsTo } from "@ember-data/model";

export default class InvoiceModel extends Model {
  @attr currency;
  @attr duedate;
  @attr fullypaid;
  @attr invoicedate;
  @attr invoicenumber;
  @attr paymentdate;
  @attr remain;
  @attr total;
  @attr url;
  @attr("boolean") selected;

  @belongsTo("organization", { async: false, inverse: "invoices" }) organization;

  get isSettled() {
    return this.remain === 0;
  }

  get isCredit() {
    return this.total < 0;
  }
}

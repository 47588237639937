/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";

export default class GServerStatusIconComponent extends Component {
  get platform() {
    return this.args.platform?.toLowerCase();
  }

  get isRunning() {
    return this.args.isRunning || false;
  }

  get state() {
    if (this.args.isLocked) return "locked";

    if (this.args.isRunning !== undefined) {
      return this.args.isRunning ? "running" : "stopped";
    }

    return "unknown";
  }

  get colorClasses() {
    switch (this.state) {
      case "locked":
        return "border-yellow bg-yellow-lightest";
      case "running":
        return "border-ocean-green bg-ocean-green-lightest";
      case "stopped":
        return "border-red bg-red-lightest";
    }

    return "border-gray-light bg-gray-lightest";
  }
}

import { action } from "@ember/object";
import Controller from "@ember/controller";
import { tracked } from "@glimmer/tracking";

export default class LoadBalancerStatisticsController extends Controller {
  get loadBalancer() {
    return this.model.loadBalancer;
  }

  get listeners() {
    return this.loadBalancer.listeners.slice();
  }

  @tracked selectedListener;

  get max() {
    return this.selectedListener?.get("maxConnections") * 1.1;
  }

  get chartData() {
    return {
      datasets: [
        {
          data: this.selectedListener.metrics.map((data) => ({
            x: new Date(data[0]),
            y: data[1],
          })),
        },
      ],
      labels: this.selectedListener.metrics.map((data) => new Date(data[0])),
    };
  }

  get chartOptions() {
    return {
      plugins: {
        annotation: {
          annotations: {
            line: {
              type: "line",
              display: true,
              yMin: this.selectedListener?.get("maxConnections"),
              yMax: this.selectedListener?.get("maxConnections"),
              scaleID: "y-axis-0",
              borderColor: "red",
              borderDash: [5, 10],
              borderWidth: 2,

              label: {
                content: "Maximum connections",
                display: true,
                position: "start",
                yAdjust: -15,
                xAdjust: 5,
              },
            },
          },
        },
      },
    };
  }

  @action
  setListener(listener) {
    this.selectedListener = listener;
  }
}

import hbs from "htmlbars-inline-precompile";

export default {
  title: "Modal",
  component: "Modal",
};

export const Default = (args) => ({
  template: hbs`
  <div>
    {{! template-lint-disable no-bare-strings }}
    Something in the background
    {{#if this.isOpen}}
      <GModal @isOpen={{this.isOpen}}>
        <GModal::Header>This is the header</GModal::Header>
        <GModal::Body>This is the body </GModal::Body>
        <GModal::Footer>This is the footer</GModal::Footer>
      </GModal>
    {{/if}}
  </div>
  `,
  context: args,
});

Default.args = {
  isOpen: true,
};

import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { task, all } from "ember-concurrency";
import { service } from "@ember/service";
import { action } from "@ember/object";

export default class ModalsApiKeyCreate extends Component {
  @service modal;

  @tracked description = "";
  @tracked selectedProject;

  get organizations() {
    return this.args.params.organizations;
  }

  get task() {
    return this.args.params.task;
  }

  submitTask = task(async () => {
    await this.task.perform(this.selectedProject, this.description);
    this.modal.close();
  });

  @action
  setSelectedProject(project) {
    this.selectedProject = project;
  }

  loadData = task(async () => {
    return await all(
      this.organizations.map(async (organization) => ({
        groupName: `${organization.name} (#${organization.id})`,
        items: (await organization.projects).filter((project) => !project.locked),
      })),
    );
  });
}

import { helper } from "@ember/component/helper";

export function allValuesAre([obj, value]) {
  const recursiveFlat = (obj) => {
    if (obj.constructor.name == "Object") {
      return recursiveFlat(Object.values(obj));
    }
    if (obj.constructor.name == "Array") {
      return obj.every((el) => {
        if (el.constructor.name == "Array") {
          return recursiveFlat(el);
        }
        if (el.constructor.name == "Object") {
          return recursiveFlat(Object.values(el));
        }
        return el === value;
      });
    }
    return "done";
  };
  return typeof obj == "undefined" || obj == {} || obj == [] || !value ? false : recursiveFlat(obj);
}

export default helper(allValuesAre);

import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { keepLatestTask } from "ember-concurrency";
import { action } from "@ember/object";
import { service } from "@ember/service";

export default class extends Component {
  @service ajax;

  get config() {
    return this.args.config;
  }

  get project() {
    return this.args.project;
  }

  @tracked price;

  @action
  didInsert() {
    this.getServerCostTask.perform();
  }

  getServerCostTask = keepLatestTask(async () => {
    let projectId = this.project?.id;
    let url = `/io.php/projects/${projectId}/vps/servers/server/costs`;

    let data = {
      config: {
        ...this.config,
        numberofbackupimagestokeep: 0,
      },
    };

    try {
      let response = await this.ajax.post(url, { data });
      this.price = response.billing;
    } catch (_) {
      this.price = { price: null };
    }
  });
}

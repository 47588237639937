import { task } from "ember-concurrency";
import ApplicationAdapter, { projectChildMixin } from "./application";

export default class ServerAdapter extends ApplicationAdapter.extend(projectChildMixin) {
  uriSuffix = "vps/servers";

  changeRootPassword(server, rootpassword) {
    let baseUri = this._baseUri(server);
    let url = `${baseUri}/rootpassword`;

    return this.ajax(url, "POST", {
      data: { rootpassword },
    });
  }

  clone(server, data) {
    let baseUri = this._baseUri(server);
    let url = `${baseUri}/clone`;

    return this.ajax(url, "POST", { data }).then((server) => {
      return this.store.pushPayload("server", { server });
    });
  }

  console(server) {
    let baseUri = this._baseUri(server);
    let url = `${baseUri}/console`;

    return this.ajax(url, "GET");
  }

  fetchStatusTask = task(async (server) => {
    let baseUri = this._baseUri(server);
    let url = `${baseUri}/status`;

    await this.ajax(url, "GET").then((data) => {
      if (data) {
        try {
          this.store.push(this.store.normalize("server-status", data));
        } catch (e) {
          if (
            `${e.message}`.match(/Attempted to handle event `pushedData` on .* while in state root.deleted.inFlight./)
          ) {
            // The record may be `inFlight` when we try to push data. If that's the case
            // we will not be able to update the record.
            return;
          }
          throw e;
        }
      }
    });
  });

  fetchDetailsTask = task(async (server) => {
    let baseUri = this._baseUri(server);
    let url = `${baseUri}`;

    await this.ajax(url, "GET")
      .then((data) => {
        if (data) {
          try {
            this.store.push(this.store.normalize("server", data));
          } catch (e) {
            if (
              `${e.message}`.match(/Attempted to handle event `pushedData` on .* while in state root.deleted.inFlight./)
            ) {
              // The record may be `inFlight` when we try to push data. If that's the case
              // we will not be able to update the record.
              return;
            }
            throw e;
          }
        }
      })
      .catch((e) => {
        // Swallow the error if the server was just deleted.
        if (!server.get("isDeleted")) {
          throw e;
        }
      });
  });

  power(server, type) {
    let baseUri = this._baseUri(server);
    let url = `${baseUri}/power`;

    return this.ajax(url, "POST", {
      data: { type },
    });
  }

  buildURL(type, id, snapshot, requestType) {
    let url = super.buildURL(...arguments);
    if (requestType === "deleteRecord") {
      let keepReservedIps = snapshot?.adapterOptions?.keepReservedIps ?? true;
      url += `/${keepReservedIps}`;
    }
    return url;
  }
}

import { hash } from "rsvp";
import Route from "@ember/routing/route";

export default class ArchiveRoute extends Route {
  model(params) {
    let { project, archives } = this.modelFor("archives");
    return hash({
      archive: archives.find((archive) => archive.id === params.archive_id),
      archiveArguments: project.get("archiveArguments"),
    });
  }

  setupController(controller, model) {
    super.setupController(controller, model);

    controller.resetProperties();
  }
}

import ApplicationSerializer from "./application";

export default class EmailDomainSerializer extends ApplicationSerializer {
  fakeRestFormat = false;

  primaryKey = "domainname";

  normalize(modelClass, resourceHash, prop) {
    resourceHash.project = resourceHash.project || resourceHash.account;

    // The names clashes with the relationships
    resourceHash.accountsCount = resourceHash.accounts;
    resourceHash.aliasesCount = resourceHash.aliases;
    delete resourceHash.accounts;
    delete resourceHash.aliases;

    resourceHash.links = {
      accounts: "accounts",
      aliases: "aliases",
    };

    return super.normalize(modelClass, resourceHash, prop);
  }
}

import Controller from "@ember/controller";
import { task, dropTask, timeout } from "ember-concurrency";
import { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import ENV from "glesys-controlpanel/config/environment";

export default class extends Controller {
  @service intl;
  @service http;
  @service notification;

  get project() {
    return this.model.project;
  }

  get dataCenters() {
    return this.model.dataCenters;
  }

  @tracked instances = null;
  @tracked hasInstances = true;

  get availableDataCenters() {
    return this.dataCenters.filter((dataCenter) => dataCenter.hasObjectStorageInstanceSupport);
  }

  resetProperties() {
    this.fetchInstances.cancelAll({ resetState: true });
    this.hasInstances = true;
  }

  @action
  cancelPolling() {
    this.scheduleRefetchOfInstances.cancelAll();
    this.fetchInstances.cancelAll();
  }

  deleteInstance = task(async (instance) => {
    await this.http
      .request({ url: `/io.php/projects/${this.project.id}/object-storage/instances/${instance.id}`, method: "DELETE" })
      .then(() => {
        this.fetchInstances.perform();
        this.notification.add(
          "success",
          this.intl.t("object-storage.notifications.instance-deleted.title"),
          this.intl.t("object-storage.notifications.instance-deleted.text"),
        );
      });
  });

  scheduleRefetchOfInstances = dropTask(async () => {
    await timeout(60000);
    this.fetchInstances.perform();
  });

  fetchInstances = task(async () => {
    return this.http.request({ url: `/io.php/projects/${this.project.id}/object-storage/instances` }).then((result) => {
      this.hasInstances = !!result.content.length;

      if (ENV.environment !== "test") {
        this.scheduleRefetchOfInstances.perform();
      }

      return result.content.sort((a, b) => {
        return new Date(b.created) - new Date(a.created);
      });
    });
  });
}

import Controller from "@ember/controller";
import { task } from "ember-concurrency";
import { tracked } from "@glimmer/tracking";

export default class ServerBackupController extends Controller {
  @tracked backups;

  translations = {
    daily: "server.details.backup.plural.schedules.daily",
    weekly: "server.details.backup.plural.schedules.weekly",
    monthly: "server.details.backup.plural.schedules.monthly",
    conjunction: "helpers.enumerate.conjunction.and",
  };

  get restoreBackupTask() {
    return this.model.restoreBackupTask;
  }

  get createManualBackupTask() {
    return this.model.createManualBackupTask;
  }

  get deleteManualBackupTask() {
    return this.model.deleteManualBackupTask;
  }

  get server() {
    return this.model.server;
  }

  get project() {
    return this.model.project;
  }

  get dataCenters() {
    return this.model.dataCenters;
  }

  get schedulesWithTranslationStrings() {
    return this.server.backup?.schedules?.map((schedule, i, { length }) => ({
      ...schedule,
      conjunction: i + 1 !== length ? this.translations["conjunction"] : null,
      translationString: this.translations[schedule.frequency] || this.translations["custom"],
    }));
  }

  get totalNumberOfImagesToKeep() {
    return this.server.backup?.schedules?.reduce((acc, item) => {
      return acc + item.numberofimagestokeep;
    }, 0);
  }

  get scheduledBackups() {
    return this.backups?.filter((backup) => backup.type === "scheduled").sort((a, b) => b.createdAt - a.createdAt);
  }

  get manualBackups() {
    return this.backups?.filter((backup) => backup.type === "manual").sort((a, b) => b.createdAt - a.createdAt);
  }

  loadBackups = task(async () => {
    this.backups = await this.model.backups;
  });
}

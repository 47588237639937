import Component from "@glimmer/component";
import { action } from "@ember/object";
import { task } from "ember-concurrency";
import { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";

export default class ModalsFileStorageVolumeUpdateAccess extends Component {
  @service modal;

  //eslint-disable-next-line ember/no-tracked-properties-from-args
  @tracked accessList = this.args.params.accessList;

  get volumeId() {
    return this.args.params.volumeId;
  }

  get servers() {
    return this.args.params.servers;
  }

  get task() {
    return this.args.params.serverAccessListTask;
  }

  get createServerTask() {
    return this.args.params.createServerTask;
  }

  get sortedServers() {
    return this.servers.slice().sort((a, b) => a.hostname.localeCompare(b.hostname));
  }

  submitTask = task(async () => {
    let task = this.task;
    let selectedServers = this.accessList;

    await task.perform(selectedServers);

    this.modal.close();
  });

  @action
  createServer() {
    this.createServerTask?.perform();
    this.modal.close();
  }

  @action
  toggleServer(server) {
    let selectedServer = server.get("id");
    let match = this.accessList.find((serverInList) => serverInList === selectedServer);

    if (match) {
      this.accessList = this.accessList.filter((server) => server !== selectedServer);
    } else {
      this.accessList = [...this.accessList, selectedServer];
    }
  }
}

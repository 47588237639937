import Bugsnag from "@bugsnag/js";
import ENV from "glesys-controlpanel/config/environment";

export function initialize() {
  Bugsnag.start(ENV.bugsnag);
}

export default {
  name: "bugsnag",
  initialize,
};

import hbs from "htmlbars-inline-precompile";

export default {
  title: "Alert Message",
  component: "AlertMessage",
  parameters: {
    design: {
      type: "figma",
      url: "https://www.figma.com/file/s54nkbE6waaUUe21d4bcCQ/components?node-id=1686%3A1214",
    },
  },
  argTypes: {
    type: {
      options: [null, "confirmation", "information", "warning", "error"],
      control: {
        type: "select",
      },
    },
  },
};

export const Default = (args) => ({
  template: hbs`<AlertMessage
    @type={{this.type}}
    @message={{this.message}}
  />`,
  context: args,
});

Default.args = {
  type: null,
  message: "Alert Message",
};

const messageAsBlock = () =>
  `<div class="flex flex-col">
  <span>Alert Message</span>
  <span class="text-xs">Something went wrong!</span>
</div>`;

export const WithBlock = (args) => ({
  template: hbs`
    <AlertMessage @type={{this.type}}>
      {{! template-lint-disable no-triple-curlies }}
      {{{this.message}}}
    </AlertMessage>
  `,
  context: args,
});

WithBlock.args = {
  type: null,
  message: messageAsBlock(),
};

WithBlock.parameters = {
  knobs: { escapeHTML: false },
};

import Model, { attr } from "@ember-data/model";

export default class AvailableIpModel extends Model {
  // Helper properties to easily distinguish between `available-ip` and
  // `reserved-ip` without having to look at `ip.constructor.modelName`
  isAvailable = true;
  isReserved = false;

  @attr address;
  @attr cost;
  @attr dataCenter;
  @attr platform;

  get version() {
    return `${this.address}`.indexOf(":") > -1 ? 6 : 4;
  }
}

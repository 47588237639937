import Model, { attr, belongsTo } from "@ember-data/model";

export default class PlatformArgumentModel extends Model {
  @attr bacula;
  @attr bandwidthOptions;
  @attr cpuCores;
  @attr dataCenters;
  @attr diskSizes;
  @attr externalBandwidthOptions;
  @attr idera;
  @attr internalBandwidthOptions;
  @attr memorySizes;
  @attr templates;
  @attr veeam;

  @belongsTo("project", { async: false, inverse: "platformArguments" }) project;
  @belongsTo("server", { async: false, inverse: "platformArguments" }) server;
}

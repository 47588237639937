import Route from "@ember/routing/route";
import { hash } from "rsvp";

export default class extends Route {
  model(params) {
    let { servers } = this.modelFor("servers");
    let { project } = this.modelFor("organization");
    let server = servers.find((server) => server.id === params.server_id);

    return hash({
      project,
      server,
      type: params.type,
    });
  }

  setupController(controller) {
    super.setupController(...arguments);
    controller.pollStatus.perform();
  }

  resetController(controller) {
    super.resetController(...arguments);
    controller.pollStatus.cancelAll({ resetStatus: true });
  }

  afterModel(model, transition) {
    if (!model.server || !this.isValidType(model.type)) {
      transition.abort();
      this.intermediateTransitionTo("not-found", null);
    }
  }

  isValidType(type) {
    return ["jitsi", "wordpress", "gitlab", "plesk"].includes(type);
  }
}

import Ember from "ember";

export default function () {
  let duration = Ember.testing ? 0 : 500;

  this.transition(
    this.hasClass("usageGuide"),

    this.toValue(true),
    this.use("toDown", { duration }),
    this.reverse("fade", { duration }),
  );
}

import Controller from "@ember/controller";
import { task } from "ember-concurrency";
import { service } from "@ember/service";

export default class NetworksController extends Controller {
  @service store;

  get project() {
    return this.model.project;
  }

  get networks() {
    return this.model.networks;
  }

  get filteredNetworks() {
    return this.networks
      .filter((network) => !network.isNew)
      .filter((network) => !network.isDeleted)
      .filter((network) => !network.isPublic);
  }

  createNetworkTask = task(async (description, dataCenter) => {
    let project = this.project;
    let network = this.store.createRecord("network", {
      project,
      dataCenter,
      description,
    });
    await network.save().catch((error) => {
      network.unloadRecord();
      throw error;
    });
  });

  remove = task(async (network) => {
    network.deleteRecord();
    await network.save().catch((error) => {
      network.rollbackAttributes();
      throw error;
    });
  });
}

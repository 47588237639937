import Component from "@glimmer/component";
import { action } from "@ember/object";

export default class extends Component {
  get type() {
    return this.args.type || "button";
  }

  get disabled() {
    return this.args.disabled || false;
  }

  @action
  onClick() {
    this.args.onClick?.();
  }
}

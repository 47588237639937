import Route from "@ember/routing/route";

export default class ProjectsCreateRoute extends Route {
  model() {
    let { organization } = this.modelFor("organization");
    return organization;
  }

  buildRouteInfoMetadata() {
    return { showOrganizationNotices: false };
  }

  setupController(controller, model) {
    super.setupController(controller, model);

    controller.set("name", "");
  }
}

import Helper from "@ember/component/helper";
import { service } from "@ember/service";

export default class ModalHelper extends Helper {
  @service modal;

  compute([name], hash) {
    let modal = this.modal;
    return () => modal.open(name, hash);
  }
}

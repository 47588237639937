import Model, { attr, belongsTo } from "@ember-data/model";
import { task } from "ember-concurrency";
import { service } from "@ember/service";

export default class NetworkAdapterModel extends Model {
  @service("cost") costService;

  @attr("string") adapterType;
  @attr("number") bandwidth;
  @attr("string") name;
  @attr("string") state;

  @attr("boolean") isconnected;
  @attr("boolean") isprimary;
  @attr("string") macaddress;

  @belongsTo("network", { async: false, inverse: "networkAdapters" }) network;
  @belongsTo("server", { async: false, inverse: "networkAdapters" }) server;
  @belongsTo("private-network-segment", { async: false, inverse: "networkAdapters" }) segment;

  get cost() {
    return this.costService.forNetworkAdapter(this.bandwidth, this.server, this.network);
  }

  get isLocked() {
    return this.state === "locked";
  }

  get isReady() {
    return this.state === "ready";
  }

  /* TASKS */

  deleteTask = task(async () => {
    this.deleteRecord();
    await this.save();
  });

  editTask = task(async (properties) => {
    if (this.server.isKVM) {
      this.name = properties.name;
    } else {
      this.network = properties.network;
      this.bandwidth = properties.bandwidth;
    }

    await this.save();
  });
}

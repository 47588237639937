import Controller from "@ember/controller";
import { service } from "@ember/service";

export default class NetworkCirctuitController extends Controller {
  @service store;

  get networkCircuits() {
    return this.model.networkCircuits;
  }
}

import Controller from "@ember/controller";
import { task, all } from "ember-concurrency";
import { action } from "@ember/object";
import { service } from "@ember/service";

export default class LoadBalancerDetailsController extends Controller {
  @service intl;
  @service notification;
  @service store;

  get loadBalancer() {
    return this.model.loadBalancer;
  }

  get servers() {
    return this.model.servers;
  }

  uploadCertificateTask = task(async (name, certificate) => {
    let loadBalancer = this.loadBalancer;

    let loadBalancerCertificate = this.store.createRecord("load-balancer-certificate", {
      certificate,
      loadBalancer,
      name,
    });

    await loadBalancerCertificate.save().catch((error) => {
      loadBalancerCertificate.unloadRecord();
      throw error;
    });
  });

  deleteCertificateTask = task(async (certificate) => {
    await certificate.destroyRecord();
  });

  saveLoadBalancerTask = task(async () => {
    await this.loadBalancer.save();

    this.notification.add(
      "success",
      this.intl.t("load-balancer.notifications.load-balancer-updated.title"),
      this.intl.t("load-balancer.notifications.load-balancer-updated.text"),
    );
  });

  saveListenerTask = task(async (listener) => {
    await listener.save();
    await all(listener.dirtyTargets.map((target) => target.save()));
  });

  saveListenersTask = task(async () => {
    await all(this.loadBalancer.dirtyListeners.map(this.saveListenerTask.perform));

    this.notification.add(
      "success",
      this.intl.t("load-balancer.notifications.load-balancer-updated.title"),
      this.intl.t("load-balancer.notifications.load-balancer-updated.text"),
    );
  });

  @action
  addTarget(listener) {
    this.store.createRecord("load-balancer-target", {
      active: true,
      port: listener.get("port"),
      weight: 1,
      listener,
    });
  }

  @action
  addListener() {
    let loadBalancer = this.loadBalancer;

    this.store.createRecord("load-balancer-listener", {
      mode: "http",
      stickysessions: false,
      loadBalancer,
    });
  }
}

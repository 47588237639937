import Controller from "@ember/controller";
import { action } from "@ember/object";
import { service } from "@ember/service";

export default class ServerController extends Controller {
  @service intl;
  @service abilities;

  get server() {
    return this.model.server;
  }

  get project() {
    return this.model.project;
  }

  get organization() {
    return this.model.organization;
  }

  get hasCD() {
    return this.server.platform === "VMware";
  }

  get tabs() {
    switch (this.server.platform) {
      case "KVM":
        return [
          { name: this.intl.t("link.server.details"), route: "server.details" },
          { name: this.intl.t("title.backups"), route: "server.backup" },
          { name: this.intl.t("link.server.network-adapters"), route: "server.network-adapters" },
        ];
      case "OpenVZ":
        return [
          { name: this.intl.t("link.server.details"), route: "server.details" },
          { name: this.intl.t("link.server.network-adapters"), route: "server.network-adapters" },
        ];
      case "VMware":
        return [
          { name: this.intl.t("link.server.details"), route: "server.details" },
          { name: this.intl.t("link.server.disks"), route: "server.disks" },
          { name: this.intl.t("link.server.network-adapters"), route: "server.network-adapters" },
          { name: this.intl.t("link.server.statistics"), route: "server.statistics" },
          { name: this.intl.t("link.server.iso"), route: "server.iso" },
        ];
      default:
        return [];
    }
  }

  @action
  reboot() {
    this.server.power("reboot");
  }

  @action
  powerOff() {
    this.server.power("off");
  }

  @action
  powerOffForced() {
    this.server.power("off-forced");
  }

  @action
  powerOn() {
    this.server.power("on");
  }

  @action
  powerOnBios() {
    this.server.power("on-bios");
  }
}

import hbs from "htmlbars-inline-precompile";

export default {
  title: "MaintenanceWindow",
  component: "MaintenanceWindow",
  argTypes: {
    value: {
      control: {
        type: "object",
      },
    },
  },
};

const args = {
  value: {
    weekDay: "Monday",
    startTime: "02:00:00",
    durationInMinutes: 240,
  },
};

export const Default = (args) => ({
  template: hbs`
    <MaintenanceWindow @value={{this.value}}/>
  `,
  context: args,
});

Default.args = args;

export const WithBlockParameters = (args) => ({
  template: hbs`
    <MaintenanceWindow @value={{this.value}} as |date day start end| >
      <table class="table-auto">
        {{! template-lint-disable no-bare-strings }}
        <thead></thead>
        <tbody>
        <tr>
          <td class="border px-4 py-2">Date object</td><td class="border px-4 py-2">{{date}}</td>
        </tr>
        <tr>
          <td class="border px-4 py-2">Day</td><td class="border px-4 py-2">{{day}}</td>
          </tr>
        <tr>
          <td class="border px-4 py-2">Start Time</td><td class="border px-4 py-2">{{start}}</td>
        </tr>
        <tr>
          <td class="border px-4 py-2">End Time</td><td class="border px-4 py-2">{{end}}</td>
        </tr>
        </tbody>
      </table>
    </MaintenanceWindow>
  `,
  context: args,
});

WithBlockParameters.args = args;

WithBlockParameters.parameters = {
  docs: {
    description: {
      story: `If you want to control the template yourself you can use block parameters. <br>
      You will get a date object, weekday as a string, startTime, and endTime in format "HH:mm"`,
    },
  },
};

import hbs from "htmlbars-inline-precompile";

export default {
  title: "Copyable",
  component: "Copyable",
};

export const Default = (args) => ({
  template: hbs`
  {{! template-lint-disable no-bare-strings }}
  Usage:

  <div class="block">
    <code class="inline-block p-2 border bg-gray-lighter text-mono">
      &lt;Copyable&gt;{{this.text}}&lt;/Copyable&gt;
    </code>
  </div>

  <div class="pt-6">
    <Copyable>{{this.text}}</Copyable>
  </div>
  `,
  context: args,
});

Default.args = {
  text: "Text to copy",
};

import hbs from "htmlbars-inline-precompile";

export default {
  title: "Pill",
  component: "Pill",
  argTypes: {
    color: {
      options: [null, "green", "powder", "blue", "orange", "red", "gray"],
      control: {
        type: "select",
      },
    },
  },
};

export const Default = (args) => ({
  template: hbs`
    <Pill
      class="p-2"
      @text={{this.text}}
      @color={{this.color}}
    />`,
  context: args,
});

Default.args = {
  text: "Your label",
  color: null,
};

import Component from "@glimmer/component";
import { service } from "@ember/service";
import { task } from "ember-concurrency";
import { action } from "@ember/object";
import { tracked } from "@glimmer/tracking";
import { allValuesAre } from "glesys-controlpanel/helpers/all-values-are";

import { copy } from "ember-copy";

export default class ModalsApiKeyPermissions extends Component {
  @service modal;
  @service intl;

  get task() {
    return this.args.params.task;
  }

  get description() {
    return this.args.params.apiKey.description;
  }

  allowOption = {
    action: () => {},
    value: "allow",
    label: {
      passive: this.intl.t("component.modal.api-key-permissions.toggle.allow"),
      active: this.intl.t("component.modal.api-key-permissions.toggle.allowed"),
    },
  };

  denyOption = {
    action: () => {},
    value: "deny",
    label: {
      passive: this.intl.t("component.modal.api-key-permissions.toggle.deny"),
      active: this.intl.t("component.modal.api-key-permissions.toggle.denied"),
    },
  };

  mixedOption = {
    action: () => {},
    value: "mixed",
    label: { active: "", passive: "" },
  };

  get apikey() {
    let key = Object.entries(this.permissions);
    let status = "custom";

    if (allValuesAre([this.permissions, "yes"])) {
      status = "allowed";
    }
    if (allValuesAre([this.permissions, "no"])) {
      status = "denied";
    }

    let allowAllOption = {
      ...this.allowOption,
      action: () => this.setAllPermissionsTo("yes"),
      label: {
        passive: this.intl.t("component.modal.api-key-permissions.toggle.allow-all"),
        active: this.intl.t("component.modal.api-key-permissions.toggle.all-allowed"),
      },
    };

    let denyAllOption = {
      ...this.denyOption,
      action: () => this.setAllPermissionsTo("no"),
      label: {
        passive: this.intl.t("component.modal.api-key-permissions.toggle.deny-all"),
        active: this.intl.t("component.modal.api-key-permissions.toggle.all-denied"),
      },
    };

    let selected = this.mixedOption;

    if (status === "allowed") {
      selected = allowAllOption;
    }

    if (status === "denied") {
      selected = denyAllOption;
    }

    return {
      status,
      selected,
      denyAllOption,
      allowAllOption,
      modules: key.map(([name, apiFunctions]) => {
        let status = "custom";

        if (allValuesAre([Object.values(apiFunctions), "yes"])) {
          status = "allowed";
        }

        if (allValuesAre([Object.values(apiFunctions), "no"])) {
          status = "denied";
        }

        let selected = this.mixedOption;

        if (allValuesAre([apiFunctions, "yes"])) {
          selected = {
            ...this.allowOption,
            action: () => this.setAllApiFunctionsForModuleTo(name, "yes"),
          };
        }

        if (allValuesAre([apiFunctions, "no"])) {
          selected = {
            ...this.denyOption,
            action: () => this.setAllApiFunctionsForModuleTo(name, "no"),
          };
        }

        return {
          name,
          showApiFunctions: this.expandedView.has(name),
          status,
          allowed: Object.values(apiFunctions).filter((v) => v == "yes"),
          toggle: {
            left: {
              ...this.denyOption,
              action: () => this.setAllApiFunctionsForModuleTo(name, "no"),
            },
            right: {
              ...this.allowOption,
              action: () => this.setAllApiFunctionsForModuleTo(name, "yes"),
            },
            selected,
          },
          apiFunctions: Object.entries(apiFunctions)
            .map(([apiFunction, value]) => ({
              name: apiFunction,
              value,
              toggle: {
                left: {
                  ...this.denyOption,
                  action: () => this.setPermissionForRuleTo(name, apiFunction, "no"),
                },
                right: {
                  ...this.allowOption,
                  action: () => this.setPermissionForRuleTo(name, apiFunction, "yes"),
                },
                selected:
                  value == "yes"
                    ? {
                        ...this.allowOption,
                        action: () => this.setPermissionForRuleTo(name, apiFunction, "yes"),
                      }
                    : {
                        value: "no",
                        ...this.denyOption,
                        action: () => this.setPermissionForRuleTo(name, apiFunction, "no"),
                      },
              },
            }))
            .sort((name, anotherName) => name.name > anotherName.name),
        };
      }),
    };
  }

  @action
  toggleVisibilityOfModules() {
    this.hideModules = !this.hideModules;
  }

  @action
  collapseAll() {
    this.expandedView = new Set([]);
  }

  @action
  expandAll() {
    this.expandedView = new Set(Object.keys(this.permissions));
  }

  @tracked permissions = copy(this.args.params.apiKey.permissions, true);
  @tracked expandedView = new Set([]);
  @tracked hideModules = allValuesAre([this.permissions, "yes"]) || allValuesAre([this.permissions, "no"]);

  submitTask = task(async () => {
    await this.task.perform(this.permissions);
    this.modal.close();
  });

  @action
  setAllPermissionsTo(value) {
    Object.keys(this.permissions).forEach((apiModule) => this.setAllApiFunctionsForModuleTo(apiModule, value));
  }
  @action
  setAllApiFunctionsForModuleTo(apiModule, value) {
    Object.keys(this.permissions[apiModule]).forEach((apiFunction) =>
      this.setPermissionForRuleTo(apiModule, apiFunction, value),
    );
  }

  @action
  setPermissionForRuleTo(apiModule, apiFunction, value) {
    this.permissions[apiModule][apiFunction] = value;
    this.permissionsUpdated(this.permissions);
  }

  @action
  toggleExpandedView(moduleName) {
    let setOfExpandedModules = this.expandedView;
    setOfExpandedModules.has(moduleName)
      ? setOfExpandedModules.delete(moduleName)
      : setOfExpandedModules.add(moduleName);
    this.expandedView = setOfExpandedModules;
  }

  @action
  permissionsUpdated(permissions) {
    this.permissions = permissions;
  }
}

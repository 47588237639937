import Component from "@glimmer/component";
import { Task, task } from "ember-concurrency";
import { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { instanceOf, object, shape, number, array, string } from "prop-types";

@forbidExtraArgs
export default class ModalsServerAddDisk extends Component {
  @service modal;

  @arg(
    shape({
      limits: object.isRequired,
      currentNumberOfDisks: number.isRequired,
      sizes: array.isRequired,
      name: string.isRequired,
      getServerDiskCostTask: instanceOf(Task).isRequired,
      task: instanceOf(Task).isRequired,
    }).isRequired,
  )
  params;

  @tracked name = this.defaultDiskName;
  @tracked selectedSize = this.minSize;

  constructor() {
    super(...arguments);
    this.getServerDiskCostTask.perform(this.selectedSize);
  }

  get task() {
    return this.args.params.task;
  }

  get getServerDiskCostTask() {
    return this.args.params.getServerDiskCostTask;
  }

  get sizeOptions() {
    return this.args.params.sizes;
  }

  get minSize() {
    return this.args.params.limits.minsizeingib;
  }

  get maxNumberOfDisks() {
    return this.args.params.limits.maxnumdisks;
  }

  get currentNumberOfDisks() {
    return this.args.params.currentNumberOfDisks;
  }

  get cantCreateMoreAdditionalDisks() {
    return this.maxNumberOfDisks <= this.currentNumberOfDisks;
  }

  get defaultDiskName() {
    return this.args.params.name;
  }

  submitTask = task(async () => {
    await this.task.perform(this.name, this.selectedSize);
    this.modal.close();
  });

  @action
  selectSize(size) {
    this.selectedSize = size;
    this.getServerDiskCostTask.perform(this.selectedSize);
  }
}

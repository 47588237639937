import RequestManager from "@ember-data/request";
import { service } from "@ember/service";
import ENV from "glesys-controlpanel/config/environment";
import { CacheHandler } from "@ember-data/store";
import Fetch from "@ember-data/request/fetch";

const apiBaseUri = ENV.apiBaseUri || document.location.origin;

const WithDefaultHeaders = {
  async request({ request }, next) {
    let headers = new Headers(request.headers);
    headers.append("x-api-skip-transform-7", true);
    headers.append("Content-Type", "application/json");

    return next(Object.assign({}, request, { headers }));
  },
};

const WithAuthToken = (session) => ({
  async request({ request }, next) {
    let headers = new Headers(request.headers);

    if (session.bearerToken && request.url.startsWith(apiBaseUri)) {
      headers.append("Authorization", `Bearer ${session.bearerToken}`);
    }

    return next(Object.assign({}, request, { headers }));
  },
});

const WithApiBaseUri = {
  async request(context, next) {
    let url = new URL(context.request.url, apiBaseUri).toString();

    return next(Object.assign({}, context.request, { url }));
  },
};

export default class Http extends RequestManager {
  @service session;

  constructor(createArgs) {
    super(createArgs);
    this.use([WithDefaultHeaders, WithApiBaseUri, WithAuthToken(this.session), Fetch]);
    this.useCache(CacheHandler);
  }

  request(requestInfo) {
    const request = Object.assign({}, requestInfo);
    if (request.body) {
      request.body = JSON.stringify(request.body);
    }

    return super.request(request);
  }
}

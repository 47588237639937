/**
  @public
  @module glesys-controlpanel
*/

/**
  The most gangster money formatter known to mankind.

  @public
  @method g-money
  @for helpers
  @param amount {float}
  @param [options] {object}
  @param [options.currency] {string} One of `EUR` or `SEK`
  @param [options.forceDecimals] {boolean} Force the display of decimals even when not needed

  @example
    `{{g-money 1000}}` returns `1 000`

    `{{g-money 1000 forceDecimals=true}}` returns `1 000,00`

    `{{g-money 1000.09 currency='EUR'}}` returns `€1.000,09`

    `{{g-money 1000.9 currency='SEK'}}` returns `1 001,90 kr`

    `{{g-money 1000.9 currency='SEK' forceDecimals=true}}` returns `1 000,90 kr`
*/
export default (amount, options = {}) => {
  amount = parseFloat(amount);

  let { forceDecimals, currency, showSign } = options;

  let precision = forceDecimals || Math.round(amount) !== amount ? 2 : 0;

  options = { minimumFractionDigits: precision, maximumFractionDigits: precision, ...options };

  if (currency) {
    options = { ...options, style: "currency", currency };
  }

  if (showSign) {
    options = { ...options, signDisplay: "exceptZero" };
  }

  if (Number.isNaN(amount)) {
    return "N/A";
  }

  return Intl.NumberFormat(navigator.languages, options).format(amount);
};

import Component from "@glimmer/component";
import { task } from "ember-concurrency";
import { service } from "@ember/service";
import { action } from "@ember/object";
import { tracked } from "@glimmer/tracking";

export default class ModalsExportZoneFile extends Component {
  @service modal;
  @service ajax;
  @service notification;
  @service intl;

  get domain() {
    return this.args.params.domain;
  }

  get fileObjectUrl() {
    let data = new Blob([this.file], { type: "text/txt" });
    let fileObjectUrl = URL.createObjectURL(data);

    return fileObjectUrl;
  }

  @tracked file = "";

  @action
  didInsert() {
    this.exportFile.perform();
  }

  exportFile = task(async () => {
    try {
      let response = await this.ajax.request(
        `/io.php/projects/${this.domain.project.get("id")}/domain/${this.domain.id}/export`,
      );
      this.file = response.zonefile;
    } catch (e) {
      this.notification.add(
        "error",
        this.intl.t("dns.domain.files.notifications.title"),
        this.intl.t("dns.domain.files.notifications.error-text"),
      );
      this.modal.close();
    }
  });
}

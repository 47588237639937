import Component from "@glimmer/component";
import { service } from "@ember/service";
import { arg } from "ember-arg-types";
import { bool } from "prop-types";

export default class TourHint extends Component {
  @service tour;

  @arg(bool) show = true;
}

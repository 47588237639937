import Route from "@ember/routing/route";
import RSVP from "rsvp";
import { service } from "@ember/service";

export default class ApiAccessRoute extends Route {
  @service store;
  @service session;

  model() {
    let organizations = this.store.peekAll("organization");
    let ownedOrganizations = organizations.filter(
      (organization) => this.session.currentUser?.isStaff || organization.get("isOwner"),
    );
    return RSVP.hash({
      organizations: ownedOrganizations,
      projects: RSVP.all(
        ownedOrganizations.map((organization) => {
          return organization.get("projects").then((projects) => {
            return RSVP.all(
              projects.map((project) => {
                return project.get("apiKeys").then(() => project);
              }),
            );
          });
        }),
      ).then((map) => [].concat(...map)),
    });
  }
}

import Helper from "@ember/component/helper";
import { service } from "@ember/service";

export default class CanAccessBetaFeatures extends Helper {
  @service current;
  @service abilities;

  compute([organization]) {
    return this.abilities.can("access beta features for organization", organization || this.current.organization);
  }
}

/**
  @public
  @module glesys-controlpanel
*/

import { helper as buildHelper } from "@ember/component/helper";

/**
  Transform and return the upper-case representation of a string.

  @public
  @method to-upper
  @for helpers
  @param string {string}

  @example

  `{{to-upper 'Hello World!'}}` returns `HELLO WORLD!`
*/
export function toUpper([value] /* , hash*/) {
  return `${value}`.toUpperCase();
}

export default buildHelper(toUpper);

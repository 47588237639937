import hbs from "htmlbars-inline-precompile";

export default {
  title: "GButton",
  component: "ButtonGroup",
};

const encode = (string) => {
  return decodeURIComponent(string);
};

export const Group = () => ({
  template: hbs`
    <pre>
      {{this.doc}}
    </pre>
    <br />
    <ButtonGroup>
      {{! template-lint-disable no-bare-strings }}
      <GButton>Button 1</GButton>
      <GButton>Button 2</GButton>
      <GButton>Button 3</GButton>
      <GButton>Button 4</GButton>
    </ButtonGroup>
  `,
  context: {
    doc: encode(`
<ButtonGroup>
  <GButton>Button 1</GButton>
  <GButton>Button 2</GButton>
  <GButton>Button 3</GButton>
  <GButton>Button 4</GButton>
</ButtonGroup>`),
  },
});

Group.storyName = "As a group";

import Component from "@glimmer/component";
import { action } from "@ember/object";
import TEMPLATES from "./templates";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { stringable } from "glesys-controlpanel/utils/prop-types";
import { func } from "prop-types";

@forbidExtraArgs
export default class DnsGroup extends Component {
  @arg(stringable.isRequired) type;

  @arg(func.isRequired) onTemplate;

  get templates() {
    return TEMPLATES[this.type] || [];
  }

  @action
  template(template) {
    this.onTemplate(template);
  }
}

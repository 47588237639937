import Service, { service } from "@ember/service";
import { task, timeout } from "ember-concurrency";
import ENV from "glesys-controlpanel/config/environment";

export default class UpdateNotifyService extends Service {
  @service notification;
  @service intl;

  ignore = true;

  check() {
    if (ENV.environment !== "test") {
      this.updateCheck.perform();
    }
  }

  get supportsServiceWorker() {
    return "serviceWorker" in navigator;
  }

  willDestroy() {
    if (this.supportsServiceWorker) {
      navigator.serviceWorker.oncontrollerchange = null;
    }
  }

  setupControllerChange() {
    navigator.serviceWorker.oncontrollerchange = () => {
      if (this.ignore) {
        return;
      }

      let content = this.intl.t("component.update-notify.refresh");

      if (this.notification.has(content)) {
        return;
      }

      this.notification.add("success", this.intl.t("component.update-notify.update-available"), content, {
        action: {
          title: this.intl.t("component.update-notify.click-here-to-refresh"),
          action: () => window.location.reload(),
        },
        position: "bottom-left",
        icon: "🎉",
        persist: true,
      });
    };
  }

  updateCheck = task(async () => {
    if (!this.supportsServiceWorker) {
      return;
    }

    this.setupControllerChange();

    let first = true;

    // eslint-disable-next-line no-constant-condition
    while (true) {
      try {
        let registration = await navigator.serviceWorker.register("/sw.js", {
          scope: "/",
        });

        await registration?.update();

        // On initial page load you might get a new worker installed
        // but you already have the latest version of the code so we're ignoring it
        this.ignore = first;
        first = false;
      } catch (error) {
        // Do nothing
      }

      await timeout(10 * 60 * 1000);
    }
  });
}

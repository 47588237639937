import Service from "@ember/service";
import ENV from "glesys-controlpanel/config/environment";
import { cancel, later, run } from "@ember/runloop";

export default class KeyboardService extends Service {
  listeners = [];
  callbacks = [];

  timer;
  currentEvents = [];

  timeout = ENV.environment === "test" ? 0 : 500;

  getListeners() {
    return this.listeners;
  }

  mightHandle(args, events) {
    let keys = events.map((e) => e.key).join(" ");
    return args.key?.startsWith(keys) && args.key !== keys;
  }

  keysToCompare(args) {
    let keys = Object.keys(args);
    let requiredKeys = ["ctrlKey", "metaKey"];
    let optionalKeys = ["key", "code", "shiftKey", "altKey"];
    return requiredKeys.concat(optionalKeys.filter((key) => keys.includes(key)));
  }

  shouldHandle(args, events) {
    return events.every((event) => {
      if (!args.ignoreFormFieldFocus && this.isFormField(event)) {
        return false;
      }

      return this.keysToCompare(args).every((key) => {
        if (key === "key") {
          let keys = events.map((e) => e.key).join(" ");
          return args.key === keys;
        }
        return (args[key] || false) === (event[key] || false);
      });
    });
  }

  isFormField(event) {
    if (event.key == "Escape") {
      return false;
    }

    return ["input", "textarea", "select", "button"].includes(event.target?.nodeName.toLowerCase());
  }

  callCallbacks(listeners, event) {
    listeners.forEach((listener) => {
      if (listener.args.preventDefault) {
        event.preventDefault();
      }
      listener.callback(event);
    });
    this.currentEvents = [];
    cancel(this.timer);
  }

  createCallback(context) {
    return run(() => (event) => {
      cancel(this.timer);

      this.currentEvents.push(event);

      let listeners = this.listeners.filter((listener) => listener.context === context);

      let matchingListeners = listeners.filter((listener) => this.shouldHandle(listener.args, this.currentEvents));

      let possibleListeners = listeners.filter((listener) => this.mightHandle(listener.args, this.currentEvents));

      if (!possibleListeners.length) {
        this.callCallbacks(matchingListeners, event);
      }

      this.timer = later(() => {
        if (possibleListeners.length) {
          this.callCallbacks(matchingListeners, event);
        }

        this.currentEvents = [];
      }, this.timeout);
    });
  }

  findContextOrCreate(context) {
    let callbackForContext = this.callbacks.filter((callback) => callback.context === context);

    if (callbackForContext.length > 0) {
      return callbackForContext[0].context;
    }

    const callback = this.createCallback(context);
    this.callbacks.push({
      context,
      callback,
    });
    context.addEventListener("keydown", callback);

    return context;
  }

  removeListener(args, context) {
    this.listeners = this.listeners.filter((listener) => {
      return !(listener.context === context && this.shouldHandle(listener.args, [args]));
    });
  }

  noListenersLeftForContext(context) {
    return this.listeners.filter((listener) => listener.context === context).length === 0;
  }

  removeEventListener(context) {
    const index = this.callbacks.findIndex((cb) => cb.context === context);

    if (index > -1) {
      const callback = this.callbacks.splice(index, 1)[0];
      context.removeEventListener("keydown", callback.callback);
    }
  }

  listenerAlreadyRegistered(args) {
    return this.listeners.some((listener) => this.isEqual(listener.args, args));
  }

  isEqual(obj1, obj2) {
    const keysToIgnore = ["preventDefault", "ignoreFormFieldFocus"];
    return Object.keys({ ...obj1, ...obj2 })
      .filter((key) => !keysToIgnore.includes(key))
      .every((key) => obj1[key] === obj2[key]);
  }

  listenFor(args, callback, context = document.body) {
    if (this.listenerAlreadyRegistered(args)) {
      if (ENV.environment !== "production") {
        // eslint-disable-next-line no-console
        console.warn(`Listener already registered for ${JSON.stringify(args)}`);
      }
      return;
    }
    this.listeners.push({
      args,
      callback,
      context: this.findContextOrCreate(context),
    });
  }

  stopListenFor(args, context = document.body) {
    this.removeListener(args, context);

    if (this.noListenersLeftForContext(context)) {
      this.removeEventListener(context);
    }
  }

  stopListen() {
    this.listeners.forEach((listener) => {
      this.stopListenFor(listener.args, listener.context);
    });
  }
}

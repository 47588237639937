import Component from "@glimmer/component";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { instanceOf } from "prop-types";
import { Task, task } from "ember-concurrency";
import { action } from "@ember/object";

@forbidExtraArgs
export default class OrganizationDetails extends Component {
  @arg organization;

  @arg(instanceOf(Task).isRequired) task;

  organizationNumber = this.organization.id;
  organizationName = this.organization.name;
  organizationCountry = this.organization.country;
  organizationIdentityNumber = this.organization.identityNumber;
  organizationInvoiceReference = this.organization.invoiceReference;
  organizationEmail = this.organization.email;
  organizationPhone = this.organization.phone;
  organizationAddress = this.organization.address;
  organizationCity = this.organization.city;
  organizationZip = this.organization.zip;

  get organizationNamePlaceholder() {
    return `Organization ${this.organizationNumber}`;
  }

  @action
  onFormChange({ organizationAddress, invoiceAddress, separateInvoiceAddress }) {
    this.organizationAddress = organizationAddress;

    this.organization.separateInvoiceAddress = Boolean(separateInvoiceAddress);
    this.organization.invoiceAddress = invoiceAddress;
  }

  submitTask = task(async () => {
    await this.task.perform({
      name: this.organizationName,
      identityNumber: this.organizationIdentityNumber,
      invoiceReference: this.organizationInvoiceReference,
      email: this.organizationEmail,
      phone: this.organizationPhone,
      address: this.organizationAddress,
      city: this.organizationCity,
      zip: this.organizationZip,
    });
  });
}

import ApplicationSerializer from "./application";

export default class DnsDomainSerializer extends ApplicationSerializer {
  primaryKey = "domainname";

  normalize(modelClass, resourceHash, prop) {
    resourceHash.links = { records: "records" };

    return super.normalize(modelClass, resourceHash, prop);
  }
}

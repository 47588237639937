import { helper as buildHelper } from "@ember/component/helper";

const toInteger = function (value) {
  let result = parseInt(value, 10);
  if (isNaN(result)) {
    return value;
  }
  return result;
};

export default buildHelper(function ([value]) {
  if (value === undefined) {
    return toInteger;
  }
  return toInteger(value);
});

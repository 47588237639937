import Helper from "@ember/component/helper";
import { service } from "@ember/service";
import relativeTime from "dayjs/plugin/relativeTime";
import localeData from "dayjs/plugin/localeData";
import dayjs from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/sv";

dayjs.extend(relativeTime);
dayjs.extend(localeData);

export default class DateFromNow extends Helper {
  @service intl;

  compute([date]) {
    return dayjs(date).locale(this.intl.primaryLocale).fromNow();
  }
}

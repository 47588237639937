import Component from "@glimmer/component";
import EmberObject, { action } from "@ember/object";
import { tracked } from "@glimmer/tracking";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { array, bool, func, instanceOf, object, oneOf, oneOfType, string } from "prop-types";
import { guidFor } from "@ember/object/internals";
import { stringable } from "glesys-controlpanel/utils/prop-types";
import { next } from "@ember/runloop";
import styles from "./styles.css";

const caseInsensitiveSearch = (string, query) => string?.toString().toLowerCase().includes(query.toLowerCase());

const findIndex = (array, query, valueKey) => {
  return array?.findIndex((item) => {
    if (item.items) {
      return findIndex(item.items, query, valueKey);
    }

    return item[valueKey]?.toString().toLowerCase().startsWith(query);
  });
};

const search = (array, query, valueKey) => {
  return array
    .map((item) => {
      if (caseInsensitiveSearch(item.groupName, query)) {
        return item;
      }

      if (item.items) {
        return {
          ...item,
          items: search(item.items, query, valueKey),
        };
      }

      if (caseInsensitiveSearch(item[valueKey], query)) {
        return item;
      }

      return null;
    })
    .filter((x) => (x?.items ? x.items.length : x));
};

let positionPropType = oneOf([
  "",
  "top-left",
  "top",
  "top-right",
  "right",
  "bottom-right",
  "bottom",
  "bottom-left",
  "left",
]);

@forbidExtraArgs
export default class extends Component {
  styles = styles;

  @arg(bool) isOpen = false;

  @arg(func) onSelect;

  @arg(bool) searchable = false;

  @arg(bool) showAllItems = false;

  @arg(bool) showTooltip = false;

  @arg(bool) showArrow = false;

  @arg(positionPropType) arrowPosition = "";

  @arg(oneOfType([string, object])) selected;

  @arg(oneOfType([array, instanceOf(EmberObject)])) items;

  @arg(stringable) ariaLabel = "Select a value";

  get parsedItems() {
    return this.items;
  }

  @arg(string) key = "value";

  get valueKey() {
    return this.key;
  }

  @arg(positionPropType) prefersPosition = "";

  get preferRight() {
    return this.prefersPosition.includes("right");
  }

  get preferTop() {
    return this.prefersPosition.includes("top");
  }

  get positionRight() {
    if (!this.availablePositions.includes("right") && !this.availablePositions.includes("left")) {
      return this.preferRight;
    }

    if (!this.availablePositions.includes("right")) {
      return false;
    }

    return this.preferRight || !this.availablePositions.includes("left");
  }

  get positionTop() {
    if (!this.availablePositions.includes("top") && !this.availablePositions.includes("bottom")) {
      return this.preferTop;
    }

    if (!this.availablePositions.includes("top")) {
      return false;
    }

    return this.preferTop || !this.availablePositions.includes("bottom");
  }

  @tracked availablePositions = [];

  get filteredItems() {
    if (!this.searchQuery) {
      return this.parsedItems;
    }

    return search(this.parsedItems, this.searchQuery, this.valueKey);
  }

  get allItemsFlattened() {
    return this.parsedItems?.flatMap((item) => {
      return item.items ? item.items.map((i) => i) : item;
    });
  }

  get allFilteredItemsFlattened() {
    if (!this.searchQuery) {
      return this.allItemsFlattened;
    }

    return this.filteredItems?.flatMap((item) => {
      return item.items ? item.items.map((i) => i) : item;
    });
  }

  get hasGroups() {
    return this.parsedItems?.[0]?.items !== undefined;
  }

  get animationClasses() {
    return this.isOpen ? "scale-y-100" : "scale-y-0";
  }

  get directionClasses() {
    let classes = [];

    if (this.positionRight) {
      classes.push("-right-4");
    } else {
      classes.push("left-0");
    }

    if (this.positionTop) {
      classes.push(["bottom-full pb-3 origin-bottom"]);
    } else {
      classes.push(["top-full pt-3 origin-top"]);
    }

    return classes.join(" ");
  }

  get heightClass() {
    if (this.showAllItems) {
      return "";
    }

    return this.searchable || this.hasGroups ? "max-h-64" : "max-h-52";
  }

  get arrowClasses() {
    if (!this.showArrow) {
      return "";
    }

    let classes = [this.styles.arrow];

    if (this.arrowPosition.includes("right") || this.positionRight) {
      classes.push(this.styles.arrowRight);
    } else {
      classes.push(this.styles.arrowLeft);
    }

    if (this.arrowPosition.includes("bottom") || this.positionTop) {
      classes.push([this.styles.arrowBottom]);
    } else {
      classes.push([this.styles.arrowTop]);
    }

    return classes.join(" ");
  }

  get guid() {
    return guidFor(this);
  }

  get selectedIndex() {
    let index = this.allFilteredItemsFlattened?.findIndex((item) => item === this.selected);

    return index >= 0 ? index : null;
  }

  get selectedID() {
    let index = this.selectedIndex;

    return index ? `${this.guid}-item-${index}` : null;
  }

  @tracked currentIndex = -1;
  @tracked current = null;

  @tracked searchQuery = null;

  @tracked searchKeys = "";

  @tracked element = null;

  @tracked list = null;

  interval = null;

  @action
  scrollToCurrent() {
    let value = this.current || this.allFilteredItemsFlattened[0];

    if (!value || !value[this.valueKey]) {
      return;
    }

    let element = this.list.querySelector(`[aria-selected="true"]`);

    const inView = (container, element) => {
      let top = container.scrollTop;
      let bottom = top + container.clientHeight;

      return element.offsetTop >= top && element.offsetTop <= bottom;
    };

    if (element && !inView(this.list, element)) {
      this.list.scrollTo(0, element.offsetTop - element.clientHeight);
    }
  }

  @action
  didInsert(element) {
    this.element = element;
    this.handleListener();
    this.updateCurrent();
  }

  @action
  didInsertList(element) {
    this.list = element;
  }

  @action
  removeListener() {
    document.removeEventListener("keydown", this.keyDown);
  }

  @action
  updateCurrent() {
    if (!this.items) {
      return;
    }

    this.current = this.selected;
    this.currentIndex = this.allFilteredItemsFlattened?.findIndex((item) => item === this.selected);

    next(this.scrollToCurrent);
  }

  @action
  handleListener() {
    if (this.isOpen) {
      document.addEventListener("keydown", this.keyDown);
    } else {
      this.removeListener();
    }
  }

  getAvailablePositions() {
    let listHeight = parseInt(getComputedStyle(this.list).height);
    let listWidth = parseInt(getComputedStyle(this.list).width);
    let listRect = this.element.getBoundingClientRect();
    let containerHeight = 0;

    if (this.positionTop) {
      let container = this.element.previousElementSibling;
      containerHeight = container.getBoundingClientRect().height;
    }

    let height = window.innerHeight;
    let width = window.innerWidth;

    let toBottom = height - (listRect.y + listHeight + containerHeight);
    let toTop = listRect.y - listHeight;

    let availablePositions = [];

    if (toTop > 0) {
      availablePositions.push("top");
    }
    if (toBottom > 0) {
      availablePositions.push("bottom");
    }
    if (listRect.x - listWidth > 0) {
      availablePositions.push("right");
    }
    if (listRect.x + listWidth < width) {
      availablePositions.push("left");
    }

    return availablePositions;
  }

  @action
  updateDirection() {
    if (!this.isOpen) {
      return;
    }

    next(() => (this.availablePositions = this.getAvailablePositions()));
  }

  isFormField(event) {
    if (event.key == "Escape") {
      return false;
    }

    return ["input", "textarea", "select", "button"].includes(event.target.nodeName.toLowerCase());
  }

  @action
  keyDown(event) {
    if (this.isFormField(event)) {
      return;
    }

    event.preventDefault();

    switch (event.key) {
      case "Enter":
        !this.current?.disabled && this.selectItem(this.current, event);
        return;
      case "ArrowDown":
        this.currentIndex += 1;
        if (this.currentIndex >= this.allFilteredItemsFlattened.length) {
          this.currentIndex = this.allFilteredItemsFlattened.length - 1;
        }

        this.current = this.allFilteredItemsFlattened[this.currentIndex];
        next(this.scrollToCurrent);
        return;
      case "ArrowUp":
        this.currentIndex -= 1;
        if (this.currentIndex < 0) {
          this.currentIndex = 0;
        }

        this.current = this.allFilteredItemsFlattened[this.currentIndex];
        next(this.scrollToCurrent);
        return;
    }

    this.handleTypeToSearch(event);
  }

  handleTypeToSearch(event) {
    this.searchKeys += event.key.toLowerCase();

    const foundIndex = findIndex(this.filteredItems, this.searchKeys, this.valueKey);

    if (foundIndex >= 0) {
      this.currentIndex = foundIndex;
      this.current = this.allFilteredItemsFlattened[this.currentIndex];
      next(this.scrollToCurrent);
    }

    clearTimeout(this.interval);
    this.interval = setTimeout(() => (this.searchKeys = ""), 1000);
  }

  @action
  selectItem(item, event) {
    event.stopPropagation();

    this.updateCurrent();
    this.searchKeys = "";

    this.onSelect?.(item);
  }
}

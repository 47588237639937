import Component from "@glimmer/component";
import { task } from "ember-concurrency";
import { tracked } from "@glimmer/tracking";
import { service } from "@ember/service";
import { action } from "@ember/object";

export default class ModalsUserTwofactorActivate extends Component {
  @service modal;

  get google() {
    return this.args.params.google;
  }

  get task() {
    return this.args.params.task;
  }

  get method() {
    return this.args.params.method;
  }

  get isYubiKey() {
    return this.method === "yubikey";
  }

  @tracked otp = "";

  @action
  onFormChange({ otp }) {
    this.otp = otp;
  }

  submitTask = task(async () => {
    let { task, method, otp } = this;
    let secret = this.isYubiKey ? otp.substring(0, 12) : this.google.secret;
    await task.perform(method, secret, otp);
    this.otp = "";
    this.modal.close();
  });
}

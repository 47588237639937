import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import Bugsnag from "@bugsnag/js";

export default class NetworkStatusComponent extends Component {
  @tracked networkStatus = "online";

  @action
  registerEventListeners() {
    window.addEventListener("offline", this.toggleNetworkStatus);
    window.addEventListener("online", this.toggleNetworkStatus);
  }

  @action
  toggleNetworkStatus() {
    this.networkStatus = navigator.onLine ? "online" : "offline";

    Bugsnag.leaveBreadcrumb(`Network status changed: ${this.networkStatus}`);
  }
}

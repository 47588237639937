/* eslint-disable ember/no-new-mixins */
import { dasherize } from "@ember/string";
import Mixin from "@ember/object/mixin";

export default Mixin.create({
  keyForAttribute(attribute) {
    return dasherize(attribute);
  },

  keyForRelationship(relationship) {
    return dasherize(relationship);
  },

  keyForLink(link) {
    return dasherize(link);
  },
});

import hbs from "htmlbars-inline-precompile";
import { action } from "@storybook/addon-actions";

export default {
  title: "Table",
  component: "Table",
  args: {
    footer: true,
  },
};

let items = [
  { id: 1, name: "Item number 1" },
  { id: 2, name: "Item number 2" },
  { id: 3, name: "Item number 3" },
  { id: 4, name: "Item number 4" },
  { id: 5, name: "Item number 5" },
  { id: 6, name: "Item number 6" },
  { id: 7, name: "Item number 7" },
  { id: 8, name: "Item number 8" },
];

export const Default = (args) => ({
  template: hbs`
    <div class="pt-8">
      <span class="pb-2">
        You can pass items either as an array of objects, an array of ember data models or a ember concurrency task that returns an array
      </span>
      <Table
        @fixed={{this.fixed}}
        @headers={{this.headers}}
        @footer={{this.footer}}
        @items={{this.items}}
        @pagination={{this.pagination}}
        @outsideBorders={{this.outsideBorders}}
      />
    </div>
  `,
  context: args,
});

Default.args = {
  headers: [
    { title: "id", key: "id" },
    { title: "name", key: "name" },
  ],
  items,
  fixed: false,
  pagination: {
    page: 1,
    total: null,
    perPage: 5,
  },
  outsideBorders: true,
};

export const WithPaginationData = (args) => ({
  template: hbs`
    <div class="pt-8">
      <p class="pb-2">
        Pass meta data for pagination.
      </p>
      <span class="pb-2">
        You can pass down
        <code class="bg-gray-light border rounded py-0.5 px-1">@onPageChange</code>
        to handle things like API request when switching page
      </span>
      <Table
        @fixed={{this.fixed}}
        @headers={{this.headers}}
        @footer={{this.footer}}
        @items={{this.items}}
        @outsideBorders={{this.outsideBorders}}
        @pagination={{this.pagination}}
        @onPageChange={{this.onPageChange}}
      />
    </div>
  `,
  context: args,
});

WithPaginationData.args = {
  headers: [
    { title: "id", key: "id" },
    { title: "name", key: "name" },
  ],
  items: items.concat(
    [...Array(12).keys()].map((i) => ({
      id: i + 9,
      name: `Item number ${i + 9}`,
    })),
  ),
  fixed: false,
  outsideBorders: true,
  pagination: {
    page: 1,
    total: 20,
    perPage: 10,
  },
  onPageChange: action("Page change, page:"),
};

export const WithCustomBody = (args) => ({
  template: hbs`
    <div class="pt-8">
      Specify a custom body by using named blocks:
        <Code @code={{~
"<Table @headers={{this.headers}} @items={{this.items}}>
  <:body as |item|>
    <Table::td>{{item.id}}</Table::td>
    <Table::td>{{item.name}}</Table::td>
  </:body>
</Table>"
~}} />
      <Table @headers={{this.headers}} @footer={{this.footer}} @items={{this.items}} @fixed={{this.fixed}}>
        <:body as |item|>
          <Table::td>{{item.id}}</Table::td>
          <Table::td>{{item.name}}</Table::td>
        </:body>
      </Table>
    </div>
  `,
  context: args,
});

WithCustomBody.args = {
  headers: [
    { title: "id", key: "id" },
    { title: "name", key: "name" },
  ],
  items: [
    { id: 1, name: "Item number 1" },
    { id: 2, name: "Item number 2" },
  ],
  fixed: false,
};

export const WithCustomHeader = (args) => ({
  template: hbs`
    <div class="pt-8">
      Specify a custom header (you need to specify a custom body in this case as well) by using named blocks.:
        <Code @code={{~
"<Table @items={{this.items}}>
  <:header>
    <Table::th>ID</Table::th>
    <Table::th>Name</Table::th>
  </:header>
  <:body as |item|>
    <Table::td>{{item.id}}</Table::td>
    <Table::td>{{item.name}}</Table::td>
  </:body>
</Table>"
~}} />
      <Table @footer={{this.footer}} @items={{this.items}} @fixed={{this.fixed}}>
        <:header>
          <Table::th>ID</Table::th>
          <Table::th>Name</Table::th>
        </:header>
        <:body as |item|>
          <Table::td>{{item.id}}</Table::td>
          <Table::td>{{item.name}}</Table::td>
        </:body>
      </Table>
    </div>
  `,
  context: args,
});

WithCustomHeader.args = {
  items: [
    { id: 1, name: "Item number 1" },
    { id: 2, name: "Item number 2" },
  ],
  fixed: false,
};

export const WithLoadingState = (args, { loaded }) => ({
  template: hbs`
    <div class="pt-8">
      Specify loading state by using named blocks:
        <Code @code={{~
"<Table @headers={{this.headers}} @items={{this.items}}>
  <:loading><Table::td><Icons::LoadingSpinner class='size-5' /></Table::td></:loading>
</Table>"
~}} />
      <Table @headers={{this.headers}} @footer={{this.footer}} @items={{this.items}} @fixed={{this.fixed}}>
        <:loading><Table::td><Icons::LoadingSpinner class="size-5" /></Table::td></:loading>
      </Table>
    </div>
  `,
  context: { ...args, ...loaded },
});

WithLoadingState.args = {
  headers: [
    { title: "id", key: "id" },
    { title: "name", key: "name" },
  ],
  fixed: false,
};

WithLoadingState.loaders = [
  async () => ({
    items: new Promise((resolve) => {
      setTimeout(() => {
        resolve(items);
      }, 5000);
    }),
  }),
];

export const WithEmptyState = (args) => ({
  template: hbs`
    <div class="pt-8">
      Specify empty state by using named blocks:
        <Code @code={{~
"<Table @headers={{this.headers}} @items={{this.items}}>
  <:emptyState><Table::td>No items found...</Table::td></:emptyState>
</Table>"
~}} />
      <Table @headers={{this.headers}} @footer={{this.footer}} @items={{this.items}} @fixed={{this.fixed}}>
        <:emptyState><Table::td>No items found...</Table::td></:emptyState>
      </Table>
    </div>
  `,
  context: args,
});

WithEmptyState.args = {
  headers: [
    { title: "id", key: "id" },
    { title: "name", key: "name" },
  ],
  items: [],
  fixed: false,
};

export const WithErrorState = (args, { loaded }) => ({
  template: hbs`
    <div class="pt-8">
      Specify loading state by using named blocks:
        <Code @code={{~
"<Table @headers={{this.headers}} @items={{this.items}}>
  <:loading><Table::td><Icons::LoadingSpinner class='size-5' /></Table::td></:loading>
  <:error><Table::Td>Oh no, something went wrong :(</Table::Td></:error>
</Table>"
~}} />
      <Table @headers={{this.headers}} @footer={{this.footer}} @items={{this.items}} @fixed={{this.fixed}}>
        <:loading><Table::td><Icons::LoadingSpinner class="size-5" /></Table::td></:loading>
        <:error><Table::Td>Oh no, something went wrong :(</Table::Td></:error>
      </Table>
    </div>
  `,
  context: { ...args, ...loaded },
});

WithErrorState.args = {
  headers: [
    { title: "id", key: "id" },
    { title: "name", key: "name" },
  ],
  fixed: false,
};

WithErrorState.loaders = [
  async () => ({
    items: new Promise((_, reject) => {
      setTimeout(() => {
        reject(items);
      }, 2000);
    }),
  }),
];

import Model, { attr, belongsTo } from "@ember-data/model";

export default class VpnUserModel extends Model {
  @attr username;
  @attr protocol;
  @attr("yesno") enabled;
  @attr password;

  @belongsTo("project", { async: false, inverse: "vpnUsers" }) project;
}

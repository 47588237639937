import Controller from "@ember/controller";
import { task } from "ember-concurrency";
import { service } from "@ember/service";

export default class OrganizationCollaboratorsOverviewController extends Controller {
  @service store;

  get organization() {
    return this.model.organization;
  }

  get collaborators() {
    return this.model.collaborators;
  }

  get sortedCollaborators() {
    return this.collaborators
      .slice()
      .sort((a, b) => a.isInvitePending - b.isInvitePending || a.username.localeCompare(b.username));
  }

  inviteCollaboratorTask = task(async (username) => {
    let organization = this.organization;
    let collaborator = this.store.createRecord("collaborator", {
      organization,
      username,
    });

    return collaborator.save().catch((error) => {
      collaborator.deleteRecord();
      throw error;
    });
  });

  deleteCollaboratorTask = task(async (collaborator) => {
    return collaborator.destroyRecord().catch((error) => {
      collaborator.rollbackAttributes();
      throw error;
    });
  });
}

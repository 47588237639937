import Service, { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";
import { task } from "ember-concurrency";
import { action } from "@ember/object";

export default class SessionService extends Service {
  @service ajax;
  @service router;
  @service store;
  @service storage;

  @tracked currentUser;
  @tracked sidebarOpen = false;

  get isAuthenticated() {
    return !!this.currentUser;
  }

  get bearerToken() {
    return this.storage.get("glesys:token");
  }

  open = task(async (username, password, otp, verificationCode) => {
    let data = {
      username,
      password,
    };

    if (otp) {
      data.otp = otp;
    }

    if (verificationCode) {
      data.verificationCode = verificationCode;
    }

    let { token } = await this.ajax.post("/io.php/login", { data });

    this.storage.set("glesys:token", token);

    return this.fetch.perform();
  });

  fetch = task(async () => {
    try {
      this.currentUser = await this.store.queryRecord("user", {});
      await this.currentUser.get("organizations").reload();
    } catch (error) {
      this.currentUser = null;
    }
    return this.currentUser;
  });

  close = task(async (status) => {
    await this.ajax.del("/io.php/users/current").catch(() => {});

    this.storage.remove("glesys:token");
    this.currentUser = null;

    await this.router.transitionTo("login", { queryParams: { status } });

    this.store.unloadAll();

    if (window.Intercom) {
      window.Intercom("shutdown");
    }
  });

  requireAuthentication(transition) {
    if (!this.isAuthenticated) {
      this.attemptedTransition = transition;
      this.router.transitionTo("login");
    }
  }

  @action
  openSidebar() {
    this.sidebarOpen = true;
  }

  @action
  closeSidebar() {
    this.sidebarOpen = false;
  }
}

export default (items, entityName) => {
  let regex = new RegExp(`^${entityName}(?: (?<number>\\d+))?$`);
  let filteredItems = items.filter((item) => item.match(regex)).toSorted();

  let maxItem = filteredItems.at(-1);

  if (!maxItem) {
    return `${entityName} 1`;
  }

  return maxItem.replace(regex, (...args) => {
    let number = parseInt(args.at(-1).number ?? filteredItems.length);
    return `${entityName} ${number + 1}`;
  });
};

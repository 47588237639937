import Route from "@ember/routing/route";

export default class LoadBalancerDetailsRoute extends Route {
  resetController({ model: { loadBalancer } }) {
    loadBalancer.rollbackAttributes();
    loadBalancer.dirtyListeners?.forEach((listener) => {
      listener.dirtyTargets?.forEach((target) => target.rollbackAttributes());
      listener.rollbackAttributes();
    });
  }
}

import Component from "@glimmer/component";
import { service } from "@ember/service";
import { task } from "ember-concurrency";

export default class ModalsSshKeysManage extends Component {
  @service modal;

  get sshKeys() {
    return this.args.params.sshKeys;
  }

  get project() {
    return this.args.params.project;
  }

  typeOfKey(key) {
    let prefix = key.split(" ")[0];
    let type = prefix.split("-")[1];

    return prefix.includes("ssh") ? type.replace(/[0-9]/g, "") : prefix.substring(0, 2);
  }

  deleteSshKey = task(async (key) => {
    key.destroyRecord();
  });
}

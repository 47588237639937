/**
  @public
  @module glesys-controlpanel
*/

import { helper as buildHelper } from "@ember/component/helper";

export function shortenString([value, length] /* , hash*/) {
  return `${value}`.slice(0, length);
}

export default buildHelper(shortenString);

import Controller from "@ember/controller";
import { task } from "ember-concurrency";
import { tracked } from "@glimmer/tracking";
import { service } from "@ember/service";

export default class extends Controller {
  @service ajax;

  @tracked showSuccess = false;
  @tracked email;

  submitEmail = task(async () => {
    await this.ajax.post("/io.php/recover", { data: { email: this.email } });
    this.showSuccess = true;
  });
}

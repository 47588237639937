import hbs from "htmlbars-inline-precompile";
import { action } from "@storybook/addon-actions";

export default {
  title: "ColorPicker",
  component: "ColorPicker",
  argTypes: {
    color: {
      options: [
        "skyblue",
        "midnightblue",
        "lightcoral",
        "silver",
        "mediumaquamarine",
        "sandybrown",
        "lemonchiffon",
        "rebeccapurple",
      ],
      control: {
        type: "select",
      },
    },
  },
};

export const Default = (args) => ({
  template: hbs`
    <ColorPicker @color={{this.color}} @onChange={{this.onChange}} />
  `,
  context: args,
});

Default.args = {
  color: "silver",
  onChange: action("Color changed"),
};

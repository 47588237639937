import Component from "@glimmer/component";
import { action } from "@ember/object";
import { task } from "ember-concurrency";
import { tracked } from "@glimmer/tracking";
import { service } from "@ember/service";

export default class ModalEditNetworkAdapter extends Component {
  @service modal;
  @service cost;

  get project() {
    return this.server.get("project");
  }

  get networkAdapter() {
    return this.args.params.networkAdapter;
  }

  get server() {
    return this.networkAdapter.server;
  }

  get task() {
    return this.args.params.task;
  }

  get newMonthlyCost() {
    return this.cost.forNetworkAdapter(this.selectedBandwidth, this.server, this.selectedNetwork);
  }

  get bandwidthOptions() {
    let selectedNetwork = this.selectedNetwork;
    let bandwidthOptions = selectedNetwork.get("isPublic")
      ? this.server.get("platformArguments.externalBandwidthOptions")
      : this.server.get("platformArguments.internalBandwidthOptions");

    return bandwidthOptions.map((option) => option.value);
  }

  loadNetworks = task(async () => {
    let networks = await this.project.get("networks");
    return networks.filter((network) => network.dataCenter === this.server.get("datacenter"));
  });

  @tracked selectedBandwidth = this.networkAdapter.bandwidth;
  @tracked selectedNetwork = this.networkAdapter.network;

  submitTask = task(async () => {
    let task = this.task;

    let bandwidth = this.selectedBandwidth;
    let network = this.selectedNetwork;

    await task.perform({ bandwidth, network });

    this.modal.close();
  });

  @action
  selectNetwork(network) {
    this.selectedNetwork = network;
  }

  @action
  selectBandwidth(bandwidth) {
    this.selectedBandwidth = bandwidth;
  }
}

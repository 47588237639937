import ApplicationSerializer from "./application";

export default class AvailableIpSerializer extends ApplicationSerializer {
  primaryKey = "address";

  attrs = {
    cost: "costperip",
    dataCenter: "datacenter",
  };

  normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
    let normalizedPayload = payload.iplist;

    let ips = normalizedPayload.ipaddresses;
    delete normalizedPayload.ipaddresses;

    ips = ips.map((ip) => ({
      address: ip,
      ...normalizedPayload,
    }));

    return super.normalizeArrayResponse(store, primaryModelClass, ips, id, requestType);
  }
}

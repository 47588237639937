import ApplicationSerializer from "./application";
import DasherizedMixin from "./_dasherized-mixin";

export default class ProjectSerializer extends ApplicationSerializer.extend(DasherizedMixin) {
  primaryKey = "accountname";

  attrs = {
    lockedReason: "lockedreason",
  };

  normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
    let normalizedPayload = payload.filter((project) => project.access !== "read");

    return super.normalizeArrayResponse(store, primaryModelClass, normalizedPayload, id, requestType);
  }

  normalize(modelClass, resourceHash, prop) {
    resourceHash.links = {
      "api-keys": "api/keys",
      archives: "archives",
      "archive-arguments": "archives/arguments",
      "dns-domains": "dns/domains",
      "email-domains": "email/domains",
      "load-balancers": "loadbalancers",
      networks: "networks",
      servers: "vps/servers",
      "file-storage-volumes": "file-storage-volumes",
      "private-networks": "private-networks",
      "network-circuits": "network-circuits",
      "platform-arguments": "vps/arguments",
      "reserved-ips": "ips",
      "vpn-users": "vpn/users",
      "ssh-keys": "sshkeys",
    };

    return super.normalize(modelClass, resourceHash, prop);
  }
}

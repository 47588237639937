import { helper } from "@ember/component/helper";

export function gButtonSupplementColor(color) {
  switch (color[0]) {
    case "green":
      return "bg-ocean-green-dark";
    case "blue":
      return "bg-powder-blue-darker";
    case "outline":
      return "bg-gray-light";
    case "gray":
    default:
      return "bg-gray";
  }
}

export default helper(gButtonSupplementColor);

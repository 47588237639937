import Route from "@ember/routing/route";
import { service } from "@ember/service";

export default class DnsOverviewRoute extends Route {
  @service router;

  resetController(controller, ...args) {
    super.resetController(controller, ...args);
    controller.set("filter", "");
  }

  afterModel(model, transition) {
    let { dnsDomains } = model.project;
    if (dnsDomains.length == 0 && transition.to.name !== "dns.register") {
      this.router.transitionTo("dns.register");
    }
  }
}

import { service } from "@ember/service";
import Controller from "@ember/controller";
import { task, all } from "ember-concurrency";
import { action } from "@ember/object";
import { tracked } from "@glimmer/tracking";

export default class IpsReserveController extends Controller {
  @service modal;
  @service store;

  get project() {
    return this.model.project;
  }

  get dataCenters() {
    return this.model.dataCenters;
  }

  @tracked selectedIps = [];

  get noSelectedIps() {
    return this.selectedIps.length == 0;
  }

  get ipCost() {
    return this.selectedIps.reduce((acc, curr) => acc + curr.cost.amount, 0);
  }

  @tracked selectedPlatform = null;
  @tracked selectedDataCenter = null;
  @tracked selectedVersion = null;

  fetchAvailableIpsTask = task(async (platform, dataCenter, version) => {
    let project = this.project;

    let availableIps = await this.store.query("available-ip", {
      project: project.get("id"),
      dataCenter,
      platform,
      version,
    });

    return availableIps;
  });

  reserveIpTask = task(async () => {
    let project = this.project;
    let selectedIps = this.selectedIps;

    await all(
      selectedIps.map((selectedIp) => {
        let reservedIp = this.store.createRecord("reserved-ip", {
          project,
          address: selectedIp.get("address"),
        });

        return reservedIp.save();
      }),
    );

    selectedIps.forEach((ip) => {
      this.store.unloadRecord(ip);
    });

    this.selectedIps = [];
  });

  @action
  platformChange(platform) {
    this.selectedPlatform = platform;
    this.selectedDataCenter = null;
    this.selectedVersion = null;
    this.fetchAvailableIpsTask.cancelAll({ resetState: true });
    this.selectedIps = [];
  }

  @action
  dataCenterChange(dataCenter) {
    this.selectedDataCenter = dataCenter;
    this.selectedVersion = null;
    this.fetchAvailableIpsTask.cancelAll({ resetState: true });
    this.selectedIps = [];
  }

  @action
  versionChange(version) {
    this.selectedVersion = version;

    this.selectedIps = [];

    this.fetchAvailableIpsTask.perform(this.selectedPlatform, this.selectedDataCenter?.name, this.selectedVersion);
  }

  @action
  selectAvailableIp(ip) {
    this.selectedIps = [...this.selectedIps, ip];
  }

  @action
  deselectAvailableIp(ip) {
    this.selectedIps = this.selectedIps.filter((x) => x !== ip);
  }
}

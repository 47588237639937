import Helper from "@ember/component/helper";
import { service } from "@ember/service";

export default class ModalCloseHelper extends Helper {
  @service modal;

  compute() {
    return () => this.modal.close();
  }
}

import { service } from "@ember/service";
import Route from "@ember/routing/route";
import RSVP from "rsvp";
import { task, enqueueTask } from "ember-concurrency";

export default class EmailDomainRoute extends Route {
  @service ajax;
  @service store;

  model(params) {
    let { project } = this.modelFor("organization");

    return RSVP.hash({
      project,
      domain: this.store.queryRecord("email-domain", {
        id: params.email_domain_id,
        project: project.get("id"),
      }),
    });
  }

  afterModel(model) {
    let { project, domain } = model;

    let fetchDomainMailServerTask = this.fetchDomainMailServerTask;
    fetchDomainMailServerTask.perform(domain);

    let fetchQuotaTask = this.fetchQuotaTask;
    domain.get("accounts").then((emailAccounts) => {
      emailAccounts.forEach((emailAccount) => {
        fetchQuotaTask.perform(project, domain, emailAccount);
      });
    });
  }

  fetchDomainMailServerTask = enqueueTask({ maxConcurrency: 3 }, async (domain) => {
    await domain.fetchMailServer();
  });

  fetchQuotaTask = task(async (project, domain, emailAccount) => {
    let url = `/io.php/projects/${project.id}/email/domains/${domain.id}/accounts/${emailAccount.id}/quota`;

    await this.ajax.request(url).then((response) => {
      let usedQuota = Math.round((response.usedquotainmib / 1024) * 100) / 100;

      return this.store.pushPayload("email-account", {
        "email-account": {
          emailaccount: emailAccount.id,
          usedQuota,
        },
      });
    });
  });
}

import { service } from "@ember/service";
import Route from "@ember/routing/route";
import { task } from "ember-concurrency";
export default class ServerBackupRoute extends Route {
  @service ajax;
  @service router;
  @service store;

  model() {
    let { project, server } = this.modelFor("server");
    let dataCenters = this.store.peekAll("data-center");
    let backups = server.get("backups");

    return {
      server,
      project,
      dataCenters,
      backups,
      restoreBackupTask: this.restoreBackupTask,
      createManualBackupTask: this.createManualBackupTask,
      deleteManualBackupTask: this.deleteManualBackupTask,
    };
  }

  redirect({ server }) {
    if (!server.isKVM) {
      this.router.transitionTo("server");
    }
  }

  restoreBackupTask = task(async (backup, dataCenter) => {
    let { project } = this.modelFor("server");

    const response = await this.ajax.post(`/io.php/servers/backups/restore`, {
      data: {
        backupid: backup.id,
        datacenter: dataCenter,
      },
    });

    await project.hasMany("servers").reload();

    this.router.transitionTo("server.details", response.id);
  });

  createManualBackupTask = task(async (server) => {
    let backup = await this.store.createRecord("server-backup", { server });
    await backup.save();
  });

  deleteManualBackupTask = task(async (backup) => {
    await backup.destroyRecord();
  });
}

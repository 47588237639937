import { tracked } from "@glimmer/tracking";

export default class DomainItem {
  domainName;
  tld;
  tldPrice;
  prices;
  isAvailable;

  @tracked isSelected = false;
  isDone = false;

  @tracked error;

  authCode = "";
  period;

  constructor(domainName, tld, isAvailable, prices, tldPrice) {
    this.domainName = domainName;
    this.tld = tld;
    this.isAvailable = isAvailable;
    this.prices = prices;
    this.tldPrice = tldPrice;

    if (this.status === "registrable") {
      this.period = this.prices.at(0).years;
    }
  }

  get displayName() {
    return this.domainName;
  }

  get priceRegister() {
    return this.tldPrice.registration.at(0);
  }

  get priceTransfer() {
    return this.tldPrice.regtransfer.at(0);
  }

  get priceRenew() {
    return this.tldPrice.renew.at(0);
  }

  get status() {
    if (this.priceRenew) {
      if (this.isAvailable && this.priceRegister) {
        return "registrable";
      }
      if (!this.isAvailable && this.priceTransfer) {
        return "transferable";
      }
    }
    return "unavailable";
  }
}

import hbs from "htmlbars-inline-precompile";
import { action } from "@storybook/addon-actions";

export default {
  title: "Slider",
  component: "Slider",
};

export const Default = (args) => ({
  template: hbs`
    <div class="p-4">
      <Slider
        @min={{this.min}}
        @max={{this.max}}
        @step={{this.step}}
        @disabled={{this.disabled}}
        @value={{this.initialValue}}
        @showValue={{this.showValue}}
        @onChange={{this.onChange}}
      />
    </div>
  `,
  context: args,
});

Default.args = {
  disabled: false,
  initialValue: 30,
  min: 0,
  max: 100,
  step: 1,
  showValue: true,
  onChange: action("Changed"),
};

import { Ability } from "ember-can";
import { service } from "@ember/service";

export default class LoadBalancerAbility extends Ability {
  @service session;

  get loadBalancer() {
    return this.model;
  }

  get canDelete() {
    if (this.session.currentUser?.isStaff) {
      return true;
    }
    if (this.loadBalancer.get("isManagedHosting")) {
      return false;
    }
    return true;
  }

  get canEdit() {
    return this.canDelete;
  }
}

import Component from "@glimmer/component";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { action } from "@ember/object";
import { func, oneOf } from "prop-types";

let availablePlatforms = ["KVM", "VMware"];

@forbidExtraArgs
export default class PlatformButton extends Component {
  @arg(oneOf(availablePlatforms)) platform;
  @arg(oneOf(availablePlatforms)) selectedPlatform;
  @arg(func.isRequired) onSelect;

  get platformIsSelected() {
    return this.isSelected ? "border-glesys-blue" : "border-gray-light";
  }

  get imageUri() {
    let platform = this.platform.toLowerCase();
    return `/assets/images/server-icon-frame-${platform}-67x67.svg`;
  }

  get isSelected() {
    return this.platform === this.selectedPlatform;
  }

  @action
  onClick() {
    this.onSelect(this.platform);
  }
}

import Component from "@glimmer/component";
import generatePassword from "glesys-controlpanel/utils/generate-password";
import { action } from "@ember/object";
import { tracked } from "@glimmer/tracking";
import { service } from "@ember/service";

export default class KvmGenerateUser extends Component {
  @service current;

  //eslint-disable-next-line ember/no-tracked-properties-from-args
  @tracked password = this.args.password;
  //eslint-disable-next-line ember/no-tracked-properties-from-args
  @tracked sshKey = this.args.sshKey;
  //eslint-disable-next-line ember/no-tracked-properties-from-args
  @tracked supportsSshKeys = this.args.supportsSshKeys;
  username = this.args.username;

  get passwordIsValid() {
    return !this.password || this.password.length === 0 || this.password.length >= 8;
  }

  get sshKeys() {
    return this.project.sshKeys;
  }

  get project() {
    return this.current.project;
  }

  @action
  onSshKeySave(key) {
    this.sshKey = key;
    this.args.onChange(this.username, this.password, this.sshKey);
  }

  @action
  applySshKey(key) {
    this.sshKey = key.data;
    this.args.onChange(this.username, this.password, this.sshKey);
  }

  @action
  generatePassword() {
    this.password = generatePassword(10);
    this.args.onChange(this.username, this.password, this.sshKey);
  }

  @action
  onUsernameChange(event) {
    this.username = event.target.value;
    this.args.onChange(this.username, this.password, this.sshKey);
  }

  @action
  onPasswordChange(event) {
    this.password = event.target.value;
    this.args.onChange(this.username, this.password, this.sshKey);
  }

  @action
  onSshKeyChange(event) {
    this.sshKey = event.target.value;
    this.args.onChange(this.username, this.password, this.sshKey);
  }

  @action
  didUpdateArgs() {
    this.password = this.args.password;
    this.sshKey = this.args.sshKey;
    this.supportsSshKeys = this.args.supportsSshKeys;
  }
}

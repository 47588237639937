import hbs from "htmlbars-inline-precompile";

export default {
  title: "SecureContent",
  component: "SecureContent",
};

export const Default = (args) => ({
  template: hbs`
    <SecureContent @hiddenCharacters={{this.hiddenCharacters}} @hint={{this.hint}}>{{this.text}}</SecureContent>
  `,
  context: args,
});

Default.args = {
  text: "Secret content is visible",
  hint: false,
  hiddenCharacters: 20,
};

export const WithHiddenBlock = (args) => ({
  template: hbs`
    <SecureContent>
      <:hidden>
        {{this.securedContent}}
      </:hidden>
      <:default>
        {{this.text}}
      </:default>
    </SecureContent>
  `,
  context: args,
});

WithHiddenBlock.args = {
  text: "Secret content is visible",
  securedContent: "Secret content is hidden",
};

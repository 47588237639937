import hbs from "htmlbars-inline-precompile";
import { action } from "@storybook/addon-actions";

export default {
  title: "PasswordInput",
  component: "PasswordInput",
};

export const Default = (args) => ({
  template: hbs`<PasswordInput @value={{this.value}} @onChange={{this.onChange}} />`,
  context: args,
});

Default.args = {
  value: "supersecret",
  onChange: action("Password changed"),
};

import Controller from "@ember/controller";
import { action } from "@ember/object";

import { task } from "ember-concurrency";
import { service } from "@ember/service";

export default class OrganizationCollaboratorsDetailsController extends Controller {
  @service router;

  get collaborator() {
    return this.model.collaborator;
  }

  get organization() {
    return this.model.organization;
  }

  get projects() {
    return this.model.projects;
  }

  @action
  changeIsOwner(value) {
    this.collaborator.isOwner = value;
    this.updateCollaboratorTask.perform();
  }

  @action
  removeProjectAccess(projectId) {
    let projectAccess = this.collaborator.projectAccess;

    let newProjectAccess = Object.keys(projectAccess)
      .filter((k) => k !== projectId)
      .reduce((carry, key) => {
        carry[key] = projectAccess[key];
        return carry;
      }, {});

    this.collaborator.projectAccess = newProjectAccess;
    this.updateCollaboratorTask.perform();
  }

  @action
  updateProjectAccess(projectId, newAccess) {
    let projectAccess = Object.assign({}, this.collaborator.projectAccess);
    projectAccess[projectId] = newAccess;

    this.collaborator.projectAccess = projectAccess;
    this.updateCollaboratorTask.perform();
  }

  updateCollaboratorTask = task(async () => {
    let collaborator = this.collaborator;

    await collaborator.save().catch((error) => {
      collaborator.rollbackAttributes();
      throw error;
    });
  });

  deleteCollaboratorTask = task(async (collaborator) => {
    await collaborator.destroyRecord().catch((error) => {
      collaborator.rollbackAttributes();
      throw error;
    });
    this.router.transitionTo("organization.collaborators");
  });
}

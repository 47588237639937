import { service } from "@ember/service";
import Controller from "@ember/controller";
import { task } from "ember-concurrency";

export default class ProjectsCreateController extends Controller {
  @service router;
  @service current;
  @service store;

  get organization() {
    return this.model;
  }

  name = "";
  color;

  createProjectTask = task(async () => {
    let { name, organization, color } = this;
    let project = this.store.createRecord("project", {
      name,
      organization,
      color,
    });
    await project.save().catch((error) => {
      project.unloadRecord();
      throw error;
    });

    this.router.transitionTo("organization.overview", { queryParams: { project: project.id } });
  });
}

import Route from "@ember/routing/route";
import { service } from "@ember/service";

export default class extends Route {
  @service session;
  @service router;

  model() {
    return this.session.currentUser;
  }

  redirect(user) {
    // Redirect individual users back to home
    if (user.isIndividual) {
      this.router.transitionTo("dashboard.home");
    }
  }
}

import ApplicationAdapter, { projectChildMixin } from "./application";

export default class NetworkAdapterAdapter extends ApplicationAdapter.extend(projectChildMixin) {
  uriSuffix = "network-adapters";

  baseUriProject(snapshot) {
    if (snapshot) {
      let baseUri = this.baseUri();
      let server = snapshot.belongsTo("server");
      let projectId = server.belongsTo("project").id;
      return `${baseUri}/projects/${projectId}`;
    }
  }
}

import ApplicationSerializer from "./application";

export default class NetworkAdapterSerializer extends ApplicationSerializer {
  fakeRestFormat = false;

  primaryKey = "networkadapterid";

  attrs = {
    adapterType: "adaptertype",
    server: "serverid",
    network: "networkid",
  };

  serialize() {
    let json = super.serialize(...arguments);

    json.networkid = json.networkid ?? json.segment;

    return json;
  }
}

import Controller from "@ember/controller";
import { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";

export default class OrganizationController extends Controller {
  @service router;

  queryParams = ["project"];
  @tracked project = null;

  get organization() {
    return this.model.organization;
  }

  get showNotices() {
    return this.router.currentRoute.metadata?.showOrganizationNotices ?? true;
  }

  setupController({ project }) {
    if (!project) {
      this.project = null;
    }
  }
}

import { Ability } from "ember-can";

export default class IpAbility extends Ability {
  get ip() {
    return this.model;
  }

  get canRelease() {
    if (this.ip.lockedToProject) {
      return false;
    }

    if (this.ip.serverId) {
      return false;
    }

    return true;
  }
}

import { helper as buildHelper } from "@ember/component/helper";

export function percentage([x, y] /* , hash */) {
  x = parseFloat(x);
  y = parseFloat(y);

  return x > 0 && y > 0 ? parseFloat(((x / y) * 100).toFixed(0)) : 0;
}

export default buildHelper(percentage);

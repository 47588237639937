import Component from "@glimmer/component";
import { restartableTask, timeout } from "ember-concurrency";
import { tracked } from "@glimmer/tracking";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { stringable } from "glesys-controlpanel/utils/prop-types";
import { bool, func, oneOf } from "prop-types";
import { action } from "@ember/object";
import ENV from "glesys-controlpanel/config/environment";

@forbidExtraArgs
export default class Code extends Component {
  @tracked state = null;
  @tracked text = this.code;

  @arg(stringable) code;

  @arg(bool) editable;

  @arg(func) onChange;

  @arg(oneOf(["tabs", "spaces"])) indentation = "spaces";

  get lineNumbers() {
    return this.text.split(/\r?\n/).map((_, index) => index + 1);
  }

  get supportsCopyingToClipboard() {
    return navigator.clipboard;
  }

  copyCodeToClipboard = restartableTask(async () => {
    // https://caniuse.com/mdn-api_navigator_clipboard
    navigator.clipboard.writeText(this.text).then(
      () => (this.state = "success"),
      (exception) => {
        this.state = "failure";
        throw exception;
      },
    );
    await timeout(4 * 1000);
  });

  @action
  didUpdateCode() {
    this.text = this.code;
  }

  @action
  onInput(event) {
    this.text = event.target.value;
    this.debounceChangeTask.perform();
  }

  @action
  onKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      let indentation = this.indentation === "tabs" ? "\t" : "  ";
      event.target.setRangeText(indentation, event.target.selectionStart, event.target.selectionStart, "end");
    }
  }

  debounceChangeTask = restartableTask(async () => {
    await timeout(ENV.environment === "test" ? 0 : 500);
    this.onChange?.(this.text);
  });
}

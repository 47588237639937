import Route from "@ember/routing/route";
import RSVP from "rsvp";
import { service } from "@ember/service";

export default class ServerDisksRoute extends Route {
  @service ajax;
  @service abilities;

  model() {
    let { server, project, organization } = this.modelFor("server");

    return RSVP.hash({
      server,
      organization,
      limits: this.ajax.request(`/io.php/serverdisk/limits?serverid=${server.id}&context=${project.id}`),
    });
  }

  setupController(controller, model) {
    super.setupController(...arguments);
    controller.set("disks", model.server.additionaldisks);
  }
}

import showdown from "showdown";

export function initialize() {
  showdown.extension("targetblank", function () {
    return [
      {
        type: "output",
        regex: "<a(.*?)>",
        replace: (match, content) => {
          return content.indexOf("mailto:") !== -1 ? `<a${content}>` : `<a target="_blank"${content}>`;
        },
      },
    ];
  });
}

export default {
  name: "register-showdown-extensions",
  initialize,
};

import ApplicationSerializer from "./application";
import { EmbeddedRecordsMixin } from "@ember-data/serializer/rest";

export default class LoadBalancerSerializer extends ApplicationSerializer.extend(EmbeddedRecordsMixin) {
  attrs = {
    listeners: {
      key: "ports",
      embedded: "always",
    },
  };
}

import Service from "@ember/service";
import { tracked } from "@glimmer/tracking";
export default class NotificationService extends Service {
  @tracked notifications = [];

  defaultOptions = {
    position: "top-right",
    persist: false,
  };

  clear() {
    this.notifications = [];
  }

  has(content) {
    return this.find(content) !== undefined;
  }

  find(content) {
    // content is either a raw string or a ember-intl SafeString,
    // so we need to call toString on it to be able to compare.
    return this.notifications.find((item) => item.content.toString() === content.toString());
  }

  remove(content) {
    let itemToRemoveByContent = this.find(content);
    this.notifications = this.notifications.filter((notification) => notification !== itemToRemoveByContent);
  }

  add(type, title, content, args = {}) {
    let options = { ...this.defaultOptions, ...args };
    this.notifications = [
      ...this.notifications,
      {
        type,
        title,
        content,
        options,
      },
    ];

    !options.persist &&
      setTimeout(() => {
        this.remove(content);
      }, 7000);
  }
}

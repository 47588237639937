import { action } from "@ember/object";
import { tracked } from "@glimmer/tracking";
import { task } from "ember-concurrency";
import { service } from "@ember/service";
import Component from "@glimmer/component";

export default class extends Component {
  @service notification;
  @service http;
  @service intl;

  @tracked description = "";
  @tracked wantsToDelete = false;

  get deleteText() {
    if (this.deleteKey.isRunning) {
      return this.intl.t("label.deleting");
    }

    return this.wantsToDelete ? this.intl.t("label.are-you-sure") : this.intl.t("label.delete");
  }

  get project() {
    return this.args.params.project;
  }

  get isCreating() {
    return !this.createNewCredentials.lastSuccessful;
  }

  get instanceId() {
    return this.args.params.instance.id;
  }

  get createdCredentials() {
    return this.createNewCredentials.lastSuccessful.value;
  }

  @action
  confirmDelete(credential, instance, event) {
    event.stopPropagation();

    if (this.wantsToDelete && !this.deleteKey.isRunning) {
      this.deleteKey.perform(credential, instance);
      this.wantsToDelete = false;
    } else {
      this.wantsToDelete = true;
    }

    setTimeout(() => {
      this.wantsToDelete = false;
    }, 6000);
  }

  deleteKey = task(async (credential, instance) => {
    await this.http
      .request({
        url: `/io.php/projects/${this.project.id}/object-storage/instances/${instance}/credentials/${credential}`,
        method: "DELETE",
      })
      .then(() => {
        this.fetchInstanceCredentials.perform();
        this.notification.add(
          "success",
          this.intl.t("object-storage.notifications.credentials-deleted.title"),
          this.intl.t("object-storage.notifications.credentials-deleted.text"),
        );
      });
  });

  fetchInstanceCredentials = task(async () => {
    let { content: instance } = await this.http.request({
      url: `/io.php/projects/${this.project.id}/object-storage/instances/${this.instanceId}`,
    });
    return instance.credentials;
  });

  createNewCredentials = task(async () => {
    let { content: credentials } = await this.http.request({
      url: `/io.php/projects/${this.project.id}/object-storage/instances/${this.instanceId}/credentials`,
      method: "POST",
      body: {
        description: this.description,
      },
    });

    if (typeof credentials !== "undefined") {
      await this.fetchInstanceCredentials.perform();
      await this.notification.add(
        "success",
        this.intl.t("object-storage.notifications.credentials-created.title"),
        this.intl.t("object-storage.notifications.credentials-created.text"),
      );
      return credentials;
    }
  });
}

import Route from "@ember/routing/route";
import RSVP from "rsvp";
import { service } from "@ember/service";

export default class OrganizationCollaboratorsRoute extends Route {
  @service abilities;
  @service router;

  buildRouteInfoMetadata() {
    return { jumpable: true };
  }

  model() {
    let { organization } = this.modelFor("organization");
    let collaborators = organization.get("collaborators");

    return RSVP.hash({ organization, collaborators });
  }

  redirect({ organization }) {
    if (this.abilities.cannot("access collaborators for organization", organization)) {
      this.router.transitionTo("organization");
    }
  }
}

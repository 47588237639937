import Controller from "@ember/controller";
import { task, timeout } from "ember-concurrency";
import { service } from "@ember/service";
import { action } from "@ember/object";
import { tracked } from "@glimmer/tracking";
import ENV from "glesys-controlpanel/config/environment";

export default class OrganizationAddPaymentMethodController extends Controller {
  @service ajax;
  @service router;
  @service modal;

  get organization() {
    return this.model.organization;
  }

  @tracked url = null;

  checkForNewAddedCard = task(async () => {
    let oldCard = this.organization.paymentCard?.createdat;

    // eslint-disable-next-line no-constant-condition
    while (true) {
      await timeout(ENV.environment === "test" ? 0 : 10 * 1000);
      await this.organization.reload();

      if (oldCard !== this.organization.paymentCard?.createdat) {
        return true;
      }

      /* istanbul ignore else */
      if (ENV.environment === "test") {
        return;
      }
    }
  });

  submitPaymentMethodTask = task(async (paymentMethod, properties) => {
    let organization = this.organization;
    organization.setProperties(properties);

    if (paymentMethod === "invoice") {
      organization.set("paymentMethod", paymentMethod);
    }

    await organization.save().catch((error) => {
      organization.rollbackAttributes();
      throw error;
    });

    if (paymentMethod === "card") {
      this.modal.open("modals/organization-payment-card", { task: this.checkForNewAddedCard });
    }
  });

  @action
  cancel() {
    this.router.transitionTo("organization");
  }
}

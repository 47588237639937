import { service } from "@ember/service";
import Route from "@ember/routing/route";
import RSVP from "rsvp";

export default class DashboardHomeRoute extends Route {
  @service ajax;
  @service session;
  @service current;
  @service router;
  @service store;

  model() {
    let user = this.session.currentUser;
    let organizations = this.store.peekAll("organization");

    return RSVP.hash({
      user,
      organizations,
    });
  }

  redirect({ organizations }) {
    let organization =
      organizations.find((organization) => organization.id === this.current.latestOrganizationFromLocalStorage) ||
      organizations.at(0);

    if (organization?.id) {
      return this.router.replaceWith("organization.overview", organization.id, {
        queryParams: { project: this.current.latestProjectFromLocalStorage || null },
      });
    }
  }
}

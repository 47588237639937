import hbs from "htmlbars-inline-precompile";

export default {
  title: "Label",
  component: "Label",
  parameters: {
    design: {
      type: "figma",
      url: "https://www.figma.com/file/s54nkbE6waaUUe21d4bcCQ/components?node-id=1634%3A1182",
    },
  },
};

export const Default = (args) => ({
  template: hbs`
      <Label class="p-2">{{this.text}}</Label>
    `,
  context: args,
});

Default.args = {
  text: "Your label",
};

import Component from "@glimmer/component";
import { task } from "ember-concurrency";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";

export default class ModalsCreateNetworkAdapter extends Component {
  @service modal;
  @service cost;

  get server() {
    return this.args.params.server;
  }

  get task() {
    return this.args.params.task;
  }

  get project() {
    return this.args.params.project;
  }

  get networkAdapterCost() {
    return this.cost.forNetworkAdapter(this.selectedBandwidth, this.server);
  }

  adapterTypeOptions = Object.freeze([{ value: "VMXNET 3" }, { value: "E1000" }]);

  get bandwidthOptions() {
    return this.server.platformArguments.get("externalBandwidthOptions").map((option) => option.value);
  }

  selectedAdapterType = "VMXNET 3";
  @tracked selectedBandwidth = 100;

  submitTask = task(async () => {
    let task = this.task;

    let adapterType = this.selectedAdapterType;
    let bandwidth = this.selectedBandwidth;

    await task.perform({ adapterType, bandwidth });

    this.modal.close();
  });

  @action
  selectAdapterType(adapterType) {
    this.selectedAdapterType = adapterType.value;
  }

  @action
  onBandwidthChange(value) {
    this.selectedBandwidth = value;
  }
}

import Controller from "@ember/controller";
import { dropTask, task } from "ember-concurrency";
import { action } from "@ember/object";
import { tracked } from "@glimmer/tracking";
import { service } from "@ember/service";

export default class ArchiveController extends Controller {
  @service router;

  get archive() {
    return this.model.archive;
  }

  get archiveArguments() {
    return this.model.archiveArguments;
  }

  @tracked description;
  @tracked newPassword;
  @tracked newPasswordConfirm;
  @tracked sizeSelection;

  get isPasswordTooShort() {
    let password = this.newPassword;
    return password.length > 0 && password.length < 8;
  }

  get isPasswordMismatch() {
    let password = this.newPassword;
    let passwordConfirm = this.newPasswordConfirm;
    return password.length > 0 && password !== passwordConfirm;
  }

  get hasChangedConfiguration() {
    return this.description !== this.archive.description || this.sizeSelection !== this.archive.sizetotal;
  }

  get isDisabled() {
    return !this.newPassword || this.isPasswordTooShort || this.isPasswordMismatch;
  }

  saveConfigurationTask = dropTask(async () => {
    let archive = this.archive;
    let description = this.description;
    let size = this.sizeSelection;

    archive.description = description;
    archive.size = size;

    await archive.save().catch((error) => {
      archive.rollbackAttributes();
      throw error;
    });
  });

  changePasswordTask = dropTask(async () => {
    let archive = this.archive;
    let password = this.newPassword;

    archive.password = password;
    await archive.save();

    this.newPassword = "";
    this.newPasswordConfirm = "";
  });

  deleteArchive = task(async () => {
    await this.archive.destroyRecord();
    this.router.transitionTo("archives");
  });

  @action
  onFormChange({ description }) {
    this.description = description;
  }

  @action
  onPasswordFormChange({ newPassword, newPasswordConfirm }) {
    this.newPassword = newPassword;
    this.newPasswordConfirm = newPasswordConfirm;
  }

  @action
  selectSize(size) {
    this.sizeSelection = size.value;
  }

  get sizeOptions() {
    return this.archiveArguments.size
      .filter((size) => size >= this.archive.sizetotal)
      .map((size) => ({ label: `${size} GB`, value: size }));
  }

  resetProperties() {
    let archive = this.archive;
    this.description = archive.description;
    this.newPassword = "";
    this.newPasswordConfirm = "";
    this.sizeSelection = archive.sizetotal;
  }
}

import Component from "@glimmer/component";
import { service } from "@ember/service";
import { action } from "@ember/object";

export default class extends Component {
  @service session;
  @service intl;
  @service router;
  @service modal;
  @service keyboard;
  @service storage;

  get organization() {
    return this.args.organization;
  }

  get user() {
    return this.args.user;
  }

  get locale() {
    return this.intl.primaryLocale;
  }

  @action
  transitionTo(route) {
    this.router.transitionTo(route);
  }

  @action
  switchLanguage(locale) {
    this.intl.setLocaleIfValid(locale);
    this.storage.set("glesys:locale", locale);
  }

  @action
  signOut(status) {
    this.session.close.perform(status);
  }

  @action
  didInsertComponent(element) {
    this.keyboard.listenFor({ key: "?" }, () => this.modal.open("modals/hotkeys", {}));

    window.addEventListener("scroll", () => {
      if (window.scrollY >= 80) {
        element.classList.add("border-b", "border-gray-light");
      } else {
        element.classList.remove("border-b", "border-gray-light");
      }
    });
  }

  @action
  willDestroyComponent() {
    this.keyboard.stopListenFor({ key: "?" });
  }
}

import { helper } from "@ember/component/helper";

export const colors = ["green", "blue", "red", "outline", "link", "gray-dark", "gray"];

export function gButtonColor(color) {
  switch (color[0]) {
    case "green":
      return "bg-ocean-green border hover:bg-ocean-green-dark hover:border-ocean-green-dark border-ocean-green text-white hover:text-white";
    case "blue":
      return "bg-powder-blue border hover:bg-powder-blue-darker hover:border-powder-blue-darker border-powder-blue text-white hover:text-white";
    case "red":
      return "bg-red border hover:bg-red-darker hover:border-red-darker border-red text-white hover:text-white";
    case "outline":
      return "bg-white hover:border-gray border border-gray-light hover:text-black text-gray-darker";
    case "link":
      return "text-gray-dark border hover:border-gray-light border-transparent hover:text-gray-darker hover:bg-gray-light bg-transparent";
    case "gray-dark":
      return "text-gray-darker border hover:border-gray border-gray-light hover:text-black hover:bg-gray bg-gray-light";
    case "gray":
    default:
      return "text-gray-darker border hover:border-gray-light border-gray-lighter hover:text-gray-darkest hover:bg-gray-light bg-gray-lighter";
  }
}

export default helper(gButtonColor);

import { service } from "@ember/service";
import Route from "@ember/routing/route";
import RSVP from "rsvp";
import { later } from "@ember/runloop";
import ENV from "glesys-controlpanel/config/environment";

export default class ServerStatisticsRoute extends Route {
  @service ajax;
  @service router;

  queryParams = {
    resolution: { refreshModel: true },
    resource: { refreshModel: true },
  };

  model(params) {
    let { server } = this.modelFor("server");
    let { project } = this.modelFor("organization");
    let resolution = params.resolution ? params.resolution : "day";
    let resource = params.resource ? params.resource : "cpuusage";
    let url = `/io.php/projects/${project.get("id")}/vps/servers/${
      server.id
    }/usage?resolution=${resolution}&resource=${resource}`;

    return RSVP.hash({
      server,
      data: server.isVMware ? this.ajax.request(url) : [],
    });
  }

  afterModel() {
    if (ENV.environment === "test") {
      return;
    }
    later(
      this,
      () => {
        this.refresh();
      },
      4e4,
    );
  }

  redirect({ server }) {
    if (!server.isVMware) {
      this.router.transitionTo("server");
    }
  }
}

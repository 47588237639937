import Controller from "@ember/controller";
import { task } from "ember-concurrency";
import { action } from "@ember/object";

export default class IpsOverviewController extends Controller {
  get project() {
    return this.model.project;
  }

  loadReservedIps = task(async () => {
    let ips = await this.project.reservedIps;
    return ips
      .slice()
      .sort((a, b) => a.dataCenter?.localeCompare(b.dataCenter) || a.version - b.version || a.address - b.address);
  });

  @action
  releaseIP(ip) {
    ip.destroyRecord();
  }
}

export function initialize(appInstance) {
  let intl = appInstance.lookup("service:intl");
  let storage = appInstance.lookup("service:storage");
  let locale = storage.get("glesys:locale") || "en";
  intl.setLocale(locale);

  intl.setOnMissingTranslation(intl.onMissingTranslation);
}

export default {
  name: "locale",
  initialize,
};

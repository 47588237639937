import Component from "@glimmer/component";
import { action } from "@ember/object";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { arrayOf, instanceOf, shape, string } from "prop-types";
import { guidFor } from "@ember/object/internals";
import dnsRecord from "glesys-controlpanel/models/dns-record";

@forbidExtraArgs
export default class DnsRecordComponent extends Component {
  @arg(instanceOf(dnsRecord).isRequired) record;

  @arg(
    arrayOf(
      shape({
        hostname: string.isRequired,
        ipsVersion4: arrayOf(string).isRequired,
        ipsVersion6: arrayOf(string).isRequired,
      }),
    ),
  )
  servers;

  get id() {
    return this.record.id || guidFor(this.record);
  }

  get ips() {
    let version = this.record.type === "A" ? "ipsVersion4" : "ipsVersion6";
    return this.servers
      .filter((server) => server[version].length)
      .map((server) => {
        return {
          groupName: server.hostname,
          items: server[version].map((ip) => ({ value: ip })),
        };
      });
  }

  get hostname() {
    let ip = this.record.rdata;
    let ipsKey = { A: "ipsVersion4", AAAA: "ipsVersion6" }[this.record.type];
    return this.servers.reduce((previousValue, server) => {
      return server[ipsKey].includes(ip) ? server.hostname : previousValue;
    }, "");
  }

  get hasIpsVersion4() {
    return this.servers
      ? this.servers.reduce((value, server) => {
          return value + server.ipsVersion4.length;
        }, 0) > 0
      : false;
  }

  get hasIpsVersion6() {
    return this.servers
      ? this.servers.reduce((value, server) => {
          return value + server.ipsVersion6.length;
        }, 0) > 0
      : false;
  }

  @action
  toggleDelete() {
    let record = this.record;
    if (record.isDeleted) {
      record.rollbackAttributes();
    } else {
      record.deleteRecord();
    }
  }

  @action
  updateIpField(ip) {
    this.record.rdata = ip.value;
  }
}

import Transform from "@ember-data/serializer/transform";
import { isNone } from "@ember/utils";

export default class StringTrimTransform extends Transform {
  trimString(string) {
    return isNone(string) ? string : String(string).trim();
  }

  deserialize(serialized) {
    return this.trimString(serialized);
  }

  serialize(deserialized) {
    return this.trimString(deserialized);
  }
}

import Route from "@ember/routing/route";
import { service } from "@ember/service";

export default class OrganizationOverviewRoute extends Route {
  @service router;

  async model() {
    return this.modelFor("organization");
  }

  afterModel({ project }, transition) {
    if (!project && transition.to.name !== "organization.overview.dashboard") {
      this.router.replaceWith("organization.overview.dashboard");
    }
  }
}

import Component from "@glimmer/component";
import { action } from "@ember/object";

export default class TaskForm extends Component {
  get task() {
    return this.args.task;
  }

  get isLoading() {
    return this.args.isLoading || this.task.isRunning;
  }

  @action
  onChange(event) {
    this.args.onChange?.(Object.fromEntries(new FormData(event.currentTarget).entries()));
  }

  @action
  submit(event) {
    event.preventDefault();
    this.task.perform();
    return false;
  }
}

import Model, { attr, belongsTo, hasMany } from "@ember-data/model";
import { isNone } from "@ember/utils";

export default class EmailDomainModel extends Model {
  @attr accountsCount;
  @attr aliasesCount;
  @attr domainname;
  @attr displayname;
  @attr mailserver;

  @belongsTo("project", { async: false, inverse: "emailDomains" }) project;
  @hasMany("email-account", { async: true, inverse: "domain" }) accounts;
  @hasMany("email-alias", { async: true, inverse: "domain" }) aliases;

  get hasAccounts() {
    return this.accountsCount > 0;
  }

  get hasAliases() {
    return this.aliasesCount > 0;
  }

  get hasAccountsOrAliases() {
    return this.hasAccounts || this.hasAliases;
  }

  get hasGlesysMailServer() {
    return this.mailserver === "glesys";
  }

  get unknownMailServer() {
    return isNone(this.mailserver);
  }

  fetchMailServer(force = false) {
    if (!this.mailserver || force) {
      let adapter = this.store.adapterFor(this.constructor.modelName);
      return adapter.fetchMailServer(this);
    }
    return this;
  }
}

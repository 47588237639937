import Component from "@glimmer/component";
import { service } from "@ember/service";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { instanceOf } from "prop-types";
import Organization from "../../models/organization";
import { task } from "ember-concurrency";
import { action } from "@ember/object";

@forbidExtraArgs
export default class extends Component {
  @service ajax;
  @service metrics;

  @arg(instanceOf(Organization)) organization;

  get certificateUrl() {
    return this.getGreenHostingCertificate.lastSuccessful.value;
  }

  @action
  handleGenerate() {
    this.getGreenHostingCertificate.perform();
  }

  @action
  resetState() {
    this.getGreenHostingCertificate.cancelAll({ resetState: true });
  }

  getGreenHostingCertificate = task(async () => {
    await this.metrics.trackEvent("Intercom", { event: "generated-green-hosting-certificate" });
    let { url } = await this.ajax.post(`/io.php/certificate/generate`, {
      data: {
        organizationId: this.organization.id,
      },
    });
    return url;
  });
}

import Component from "@glimmer/component";
import { Task } from "ember-concurrency";
import { service } from "@ember/service";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { shape, instanceOf } from "prop-types";

@forbidExtraArgs
export default class ModalsOrganizationPaymentCard extends Component {
  @service modal;

  @arg(
    shape({
      task: instanceOf(Task).isRequired,
    }).isRequired,
  )
  params;

  get task() {
    return this.params.task;
  }

  constructor() {
    super(...arguments);
    this.task.perform();
  }
}

import Component from "@glimmer/component";
import { action } from "@ember/object";
import { tracked } from "@glimmer/tracking";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { oneOf } from "prop-types";
@forbidExtraArgs
export default class InfoPopup extends Component {
  @arg(oneOf([null, "orange", "red"])) iconColor;

  @tracked showToolTip = false;

  get iconColorClass() {
    switch (this.iconColor) {
      case "orange":
        return this.showToolTip ? "stroke-orange-dark" : "stroke-orange-light";
      case "red":
        return "stroke-red-dark";
      default:
        return this.showToolTip ? "stroke-gray-darker" : "stroke-gray";
    }
  }

  get iconStyle() {
    return `info w-4 inline-flex self-center relative z-8 cursor-pointer ${this.iconColorClass}`;
  }

  @action
  handleMouseEnter() {
    this.showToolTip = true;
  }

  @action
  handleMouseLeave() {
    this.showToolTip = false;
  }

  @action
  registerListeners(element) {
    element.addEventListener("mouseenter", this.handleMouseEnter);
    element.addEventListener("mouseleave", this.handleMouseLeave);
  }

  @action
  unregisterListeners(element) {
    element.removeEventListener("mouseenter", this.handleMouseEnter);
    element.removeEventListener("mouseleave", this.handleMouseLeave);
  }
}

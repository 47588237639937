import { service } from "@ember/service";
import Route from "@ember/routing/route";
import RSVP from "rsvp";

export default class DnsRegisterRoute extends Route {
  @service ajax;

  buildRouteInfoMetadata() {
    return { jumpable: true };
  }

  model() {
    let { project } = this.modelFor("organization");

    return RSVP.hash({
      project,
      countries: this.ajax.request("/io.php/countries"),
      organization: project.get("organization"),
      pricelist: this.ajax.request(`/io.php/projects/${project.get("id")}/domain/pricelist`),
    });
  }

  setupController(controller, model) {
    super.setupController(controller, model);

    controller.setProperties({
      availableDomains: [],
      selectedDomains: [],
      hasSearched: false,
    });
  }
}

import ApplicationAdapter, { childMixin } from "./application";

export default class LoadBalancerListenerAdapter extends ApplicationAdapter.extend(childMixin) {
  parentAdapter = "load-balancer";
  parentRelationship = "loadBalancer";
  parentSuffix = "ports";

  fetchTargetStatus(listener) {
    let baseUri = this._baseUri(listener);
    let url = `${baseUri}/targets/status`;

    return this.ajax(url, "GET");
  }
}

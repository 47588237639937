import { inject as service } from "@ember/service";
import Controller from "@ember/controller";
import { task, timeout } from "ember-concurrency";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import ENV from "glesys-controlpanel/config/environment";

export default class SupportChallengeController extends Controller {
  @service ajax;
  @service session;

  @tracked code = null;
  @tracked success = false;

  get currentUserEmail() {
    return this.session.currentUser.id;
  }

  get canInitiateSupportChallenge() {
    return !this.code && !this.success;
  }

  @action
  cancelPolling() {
    this.fetchSupportChallengeStatus.cancelAll();
  }

  @task
  *initateSupportChallenge() {
    let result = yield this.ajax.post("/io.php/support-challenge");

    if (result.supportchallenge.code) {
      this.code = result.supportchallenge.code;
      this.fetchSupportChallengeStatus.perform();
    }
  }

  @task
  *fetchSupportChallengeStatus() {
    let challenge = yield this.ajax.request("/io.php/support-challenge");

    if (!challenge.supportchallenge) {
      this.code = null;
      return;
    }

    if (challenge.supportchallenge?.status == "answered") {
      this.success = true;
      return;
    }

    if (ENV.environment !== "test") {
      yield timeout(5000);
      this.fetchSupportChallengeStatus.perform();
    }
  }

  resetProperties() {
    this.code = null;
    this.success = false;
  }
}

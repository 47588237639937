import { service } from "@ember/service";
import Route from "@ember/routing/route";
import RSVP from "rsvp";

export default class DashboardSettingsRoute extends Route {
  @service ajax;

  buildRouteInfoMetadata() {
    return { jumpable: true };
  }

  model() {
    return RSVP.hash({
      google: this.ajax.request("/io.php/user/googleauthsecret"),
    });
  }
}

import Component from "@glimmer/component";
import { task } from "ember-concurrency";
import { service } from "@ember/service";
import { action } from "@ember/object";

export default class ModalsLoadBalancerUploadCertificate extends Component {
  @service modal;

  get task() {
    return this.args.params.task;
  }

  certificate;
  name;

  @action
  onFormChange({ certificate }) {
    this.certificate = certificate;
  }

  submitTask = task(async () => {
    await this.task.perform(this.name, this.certificate);

    this.modal.close();
  });
}

import { Ability } from "ember-can";

export default class ServerAbility extends Ability {
  get server() {
    return this.model;
  }

  get project() {
    return this.server.project;
  }

  get canAddIpAddress() {
    return this.project.get("hasWriteAccess") && this.server.supportsEditIp;
  }

  get canReconfigure() {
    return this.project.get("hasWriteAccess");
  }

  get canCreateNetworkAdapter() {
    return this.project.get("hasWriteAccess") && !this.server.status.get("isLocked");
  }

  get canClone() {
    return this.project.get("hasWriteAccess") && this.server.supportsCloning;
  }

  get canOpenConsole() {
    return this.canOpenVncConsole || this.canOpenHtmlConsole;
  }

  get canOpenVncConsole() {
    return this.server.supportsVncConsole;
  }

  get canOpenHtmlConsole() {
    return this.server.supportsHtmlConsole;
  }
}

import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";

import { action } from "@ember/object";
import { service } from "@ember/service";

import { Task, task } from "ember-concurrency";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { array, instanceOf, object, shape } from "prop-types";

@forbidExtraArgs
export default class ModalsArchivesCreate extends Component {
  @service current;
  @service modal;
  @service cost;

  @arg(
    shape({
      project: object.isRequired,
      sizes: array.isRequired,
      task: instanceOf(Task).isRequired,
    }).isRequired,
  )
  params;

  @tracked password;
  @tracked username;
  @tracked selectedSize = this.sizes[0];

  get project() {
    return this.params.project;
  }

  get sizes() {
    return this.params.sizes;
  }

  get task() {
    return this.params.task;
  }

  get archiveCost() {
    return this.cost.forArchive(this.selectedSize);
  }

  get passwordIsValid() {
    return this.password?.length >= 8;
  }

  get canSubmit() {
    return this.passwordIsValid && this.username?.length > 0 && !this.current.organization.isRestricted;
  }

  get sizeOptions() {
    let sizes = this.sizes;
    return sizes.map((size) => {
      let label = `${size} GB`;
      let value = size;
      return { label, value };
    });
  }

  @action
  onFormChange({ username, password }) {
    this.username = username;
    this.password = password;
  }

  @action
  selectSize(sizeObject) {
    this.selectedSize = sizeObject.value;
  }

  submitTask = task(async () => {
    await this.task.perform(`${this.project.id}_${this.username}`, this.password, this.selectedSize);
    this.modal.close();
  });
}

import Route from "@ember/routing/route";
import RSVP from "rsvp";
import ENV from "glesys-controlpanel/config/environment";
import { task, timeout } from "ember-concurrency";

export default class FileStorageVolumesOverviewRoute extends Route {
  buildRouteInfoMetadata() {
    return { jumpable: true };
  }

  model() {
    let { project } = this.modelFor("organization");
    let fileStorageVolumes = project.get("fileStorageVolumes");

    return RSVP.hash({ fileStorageVolumes, project });
  }

  setupController() {
    super.setupController(...arguments);
    this.volumeStatusLoopTask.perform();
  }

  resetController() {
    super.resetController(...arguments);
    this.volumeStatusLoopTask.cancelAll();
  }

  volumeStatusLoopTask = task(async () => {
    if (ENV.environment === "test") {
      return;
    }

    let { project } = this.modelFor(this.routeName);

    // eslint-disable-next-line no-constant-condition
    while (true) {
      await project.get("fileStorageVolumes").reload();
      await timeout(1000);
    }
  });
}

import Component from "@glimmer/component";
import { task } from "ember-concurrency";
import { tracked } from "@glimmer/tracking";
import { service } from "@ember/service";
import { action } from "@ember/object";

export default class ModalsUserTwoFactorDeactivate extends Component {
  @service modal;

  get task() {
    return this.args.params.task;
  }

  @tracked password = "";
  @tracked otp = "";

  @action
  onFormChange({ password, otp }) {
    this.password = password;
    this.otp = otp;
  }

  submitTask = task(async () => {
    let { task, otp, password } = this;

    await task.perform(otp, password);
    this.otp = "";
    this.password = "";
    this.modal.close();
  });
}

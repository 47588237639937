import { service } from "@ember/service";
import Controller from "@ember/controller";
import { isPresent, isNone } from "@ember/utils";
import { action } from "@ember/object";
import { task, keepLatestTask } from "ember-concurrency";
import generatePassword from "glesys-controlpanel/utils/generate-password";
import { tracked } from "@glimmer/tracking";

const DEFAULT_PLATFORM_TEMPLATES = {
  VMware: "Windows Server 2022 Standard LTSC",
  KVM: "Ubuntu 24.04 LTS (Noble Numbat)",
};

export default class ServerCreateController extends Controller {
  @service ajax;
  @service intl;
  @service metrics;
  @service session;
  @service router;
  @service store;

  get project() {
    return this.model.project;
  }

  get platformArguments() {
    return this.model.platformArguments;
  }

  @tracked hostname;
  description;
  @tracked password = "";

  @tracked users;

  @tracked selectedPlatform;
  @tracked selectedTemplate;
  @tracked selectedDataCenter;
  @tracked selectedDiskSize;
  @tracked selectedMemorySize;
  @tracked selectedCpuCores;
  @tracked selectedBandwidthOption;
  @tracked selectedIpVersion4;
  @tracked selectedIpVersion6;

  #placeholderCloudConfig =
    "## template: glesys\n#cloud-config\n{{> users }}\nbootcmd:\n  - echo 'Hello {{users.0.username}}!'";
  @tracked cloudConfig;

  @tracked showCampaignCode = false;
  campaignCode;

  @tracked serverCost;

  tabs;

  backupSchedules;

  get templateArguments() {
    let templateArguments = this.selectedPlatformArguments.templates;
    let noneTemplate = templateArguments?.find((templateArgument) => templateArgument.value === "None");
    if (isPresent(noneTemplate)) {
      return templateArguments.filter((template) => template !== noneTemplate).concat(noneTemplate);
    }
    return templateArguments;
  }

  get currentlySelectedTemplateOrDefaultTemplateForPlatform() {
    let selectedTemplate = this.templateArguments.find((x) => x == this.selectedTemplate);
    let defaultTemplateForPlatform = this.templateArguments.find(
      (x) => x.value == DEFAULT_PLATFORM_TEMPLATES[this.selectedPlatform],
    );
    let fallbackTemplate = this.templateArguments[0];

    return selectedTemplate || defaultTemplateForPlatform || fallbackTemplate;
  }

  get currentlySelectedDataCenterOrDefaultDataCenter() {
    let selectedDataCenter = this.selectedPlatformArguments.dataCenters.find((x) => x.value == this.selectedDataCenter);
    return selectedDataCenter?.value || "Falkenberg";
  }

  get hasSelectedNoneTemplate() {
    return this.selectedTemplate.value === "None";
  }

  get isWindowsTemplate() {
    let template = this.selectedTemplate.value;
    return `${template}`.toLowerCase().indexOf("windows") > -1;
  }

  get filteredUsers() {
    return this.users.filter((user) => user.username || user.sshKey || user.password);
  }

  get canNotSubmit() {
    if (this.project.organization.isRestricted) {
      return true;
    }

    if (!this.hostname) {
      return true;
    }

    if (this.hasSelectedNoneTemplate) {
      return false;
    }

    if (this.supportsCloudInit) {
      if (this.hasCustomCloudConfig) {
        return false;
      }

      return (
        this.filteredUsers.length === 0 ||
        !this.filteredUsers.every((user) => user.username && (user.sshKey || user.password))
      );
    } else {
      return !this.password;
    }
  }

  get hasCustomCloudConfig() {
    return this.#placeholderCloudConfig !== this.cloudConfig;
  }

  get supportsCloudInit() {
    return this.selectedTemplate.bootstrapMethod === "CLOUD_INIT";
  }

  get isKvm() {
    return this.selectedPlatform === "KVM";
  }

  get isVMware() {
    return this.selectedPlatform === "VMware";
  }

  get pricePerMonth() {
    let cost = 0;

    let diskSize = this.selectedDiskSize;
    let memorySize = this.selectedMemorySize;
    let cpuCores = this.selectedCpuCores;
    let bandwidthOption = this.selectedBandwidthOption;

    if (diskSize && memorySize && cpuCores && bandwidthOption) {
      cost += diskSize.basecost + diskSize.licensecost;
      cost += memorySize.basecost + memorySize.licensecost;
      cost += cpuCores.basecost + cpuCores.licensecost;
      cost += bandwidthOption.basecost + bandwidthOption.licensecost;
    }

    return cost.toFixed(2);
  }

  get diskSizeOptions() {
    let diskSizes = this.selectedPlatformArguments.diskSizes;
    if (!this.selectedTemplate) {
      return diskSizes;
    }

    return diskSizes.filter((option) => {
      return option.value >= this.selectedTemplate.minimumDiskSize;
    });
  }

  get memorySizeOptions() {
    let memorySizes = this.selectedPlatformArguments.memorySizes;
    if (!this.selectedTemplate) {
      return memorySizes;
    }

    return memorySizes.filter((option) => {
      return option.value >= this.selectedTemplate.minimumMemorySize;
    });
  }

  get selectedPlatformArguments() {
    let projectId = this.project.id;
    let platformArguments = this.platformArguments;
    let selectedPlatform = this.selectedPlatform;
    let id = `${projectId}-${selectedPlatform}`;
    if (platformArguments) {
      return platformArguments.find((platformArgument) => platformArgument.id === id);
    }
    return null;
  }

  getKVMServerCostTask = keepLatestTask(async () => {
    let projectId = this.project?.id;
    let url = `/io.php/projects/${projectId}/vps/servers/server/costs`;

    let numberOfBackupImagesFromSchedules = this.backupSchedules
      .filter((schedule) => schedule.enabled)
      .reduce((totalPrice, schedule) => totalPrice + schedule.numberofimagestokeep, 0);

    let data = {
      config: {
        cpucores: this.selectedCpuCores?.value,
        disksize: this.selectedDiskSize?.value,
        memorysize: this.selectedMemorySize?.value,
        bandwidth: this.selectedBandwidthOption?.value,
        numberofbackupimagestokeep: numberOfBackupImagesFromSchedules,
        campaigncode: this.campaignCode,
        templateid: this.selectedTemplate?.templateId,
      },
    };

    try {
      let response = await this.ajax.post(url, { data });

      this.serverCost = {
        price: response.billing.price,
        total: response.billing.total,
      };
    } catch (_) {
      this.serverCost = {
        price: null,
      };
    }
  });

  createServerTask = task(async () => {
    let project = this.project;
    let properties = {
      project,
      hostname: this.hostname,
      description: this.description,
      platform: this.selectedPlatform,
      templatename: this.selectedTemplate.value,
      datacenter: this.selectedDataCenter,
      disksize: this.selectedDiskSize.value,
      memorysize: this.selectedMemorySize.value,
      cpucores: this.selectedCpuCores.value,
      bandwidth: this.selectedBandwidthOption.value,
      campaignCode: this.campaignCode,
    };

    if (this.supportsCloudInit) {
      let users = this.filteredUsers.map((user) => {
        let object = { username: user.username };

        user.sshKey && (object.sshKeys = [user.sshKey]);
        user.password && (object.password = user.password);

        return object;
      });

      properties.users = users;
      if (this.hasCustomCloudConfig) {
        properties.cloudconfig = this.cloudConfig;
      }
    } else {
      properties.rootpassword = this.password;
    }

    if (this.isKvm) {
      let schedules = this.backupSchedules
        .filter((schedule) => schedule.enabled)
        .map(({ frequency, numberofimagestokeep }) => ({ frequency, numberofimagestokeep }));

      properties.backup = {
        enabled: schedules.length > 0,
        schedules,
      };
    }

    properties.ipv4 = this.selectedIpVersion4?.id ?? "none";
    properties.ipv6 = this.selectedIpVersion6?.id ?? "none";

    let server = this.store.createRecord("server", properties);
    await server.save().catch((error) => {
      server.unloadRecord();
      throw error;
    });

    this.resetProperties();
    this.setDefaultValues();

    this.router.transitionTo("server.details", server.id);
  });

  setTabs() {
    this.tabs = [
      { name: this.intl.t("servers.create.tabs.distribution"), route: "servers.create" },
      { name: this.intl.t("servers.create.tabs.one-click-installer"), route: "servers.appliance" },
    ];
  }

  setDefaultValues() {
    let setDefaultValueIfNone = (propertyName, argumentName, defaultValue) => {
      if (isNone(this[propertyName])) {
        let platformArguments = this.selectedPlatformArguments;
        this[propertyName] = platformArguments
          .get(argumentName)
          .find((platformArgument) => platformArgument.value === defaultValue);
      }
    };

    setDefaultValueIfNone("selectedBandwidthOption", "externalBandwidthOptions", 100);
    setDefaultValueIfNone("selectedCpuCores", "cpuCores", 2);
    setDefaultValueIfNone("selectedDiskSize", "diskSizes", 20);
    setDefaultValueIfNone("selectedMemorySize", "memorySizes", 2048);
  }

  resetProperties() {
    this.hostname = null;
    this.password = "";
    this.users = [
      {
        username: this.session.get("currentUser")?.defaultServerUsername,
        password: "",
        sshKey: "",
      },
    ];

    this.cloudConfig = this.#placeholderCloudConfig;

    this.selectedPlatform = "KVM";

    this.selectedTemplate = this.currentlySelectedTemplateOrDefaultTemplateForPlatform;
    this.selectedDataCenter = "Falkenberg";

    this.selectedBandwidthOption = null;
    this.selectedCpuCores = null;
    this.selectedDiskSize = null;
    this.selectedMemorySize = null;

    this.selectedIpVersion4 = null;
    this.selectedIpVersion6 = null;

    this.showCampaignCode = false;
    this.campaignCode = null;
    this.backupSchedules = [];
  }

  @action
  onPasswordChange(event) {
    this.password = event.target.value;
  }

  @action
  toggleCampaignCode() {
    this.showCampaignCode = !this.showCampaignCode;
  }

  @action
  generatePassword() {
    this.password = generatePassword();
  }

  @action
  updateBackup(backupSchedules) {
    this.backupSchedules = backupSchedules;
    this.getKVMServerCostTask.perform();
  }

  @action
  onPlatformChange(platform) {
    this.selectedPlatform = platform;
    this.selectedDataCenter = this.currentlySelectedDataCenterOrDefaultDataCenter;
    this.selectedTemplate = this.currentlySelectedTemplateOrDefaultTemplateForPlatform;
    this.campaignCode = null;
  }

  @action
  onCampaignCodeChange(event) {
    this.campaignCode = event.target.value;
    if (this.campaignCode.length >= 5) {
      this.getKVMServerCostTask.perform();
    }
  }

  @action
  addUser() {
    let newUser = {
      username: "",
      password: "",
      sshKey: "",
    };

    this.users = [...this.users, newUser];
  }

  @action
  removeUser(user) {
    this.users = this.users.filter((u) => u !== user);
  }

  @action
  onUserChange(user, username, password, sshKey) {
    user.username = username;
    user.password = password;
    user.sshKey = sshKey;

    this.users = [...this.users];
  }

  @action
  onDiskSizeChange(diskSize) {
    this.selectedDiskSize = diskSize;
    if (this.isKvm) {
      this.getKVMServerCostTask.perform();
    }
  }

  @action
  onMemorySizeChange(memorySize) {
    this.selectedMemorySize = memorySize;
    if (this.isKvm) {
      this.getKVMServerCostTask.perform();
    }
  }

  @action
  onCpuCoresChange(cpuCores) {
    this.selectedCpuCores = cpuCores;
    if (this.isKvm) {
      this.getKVMServerCostTask.perform();
    }
  }

  @action
  onBandwidthOptionChange(bandwidthOption) {
    this.selectedBandwidthOption = bandwidthOption;
    if (this.isKvm) {
      this.getKVMServerCostTask.perform();
    }
  }

  @action
  selectTemplate(template) {
    this.selectedTemplate = template;
    if (this.isKvm) {
      this.getKVMServerCostTask.perform();
    }
  }

  @action
  selectDataCenter(dataCenter) {
    this.selectedDataCenter = dataCenter.value;
    this.selectedTemplate = this.currentlySelectedTemplateOrDefaultTemplateForPlatform;
    this.campaignCode = null;
  }

  @action
  onIpsChange(IPv4, IPv6) {
    this.selectedIpVersion4 = IPv4;
    this.selectedIpVersion6 = IPv6;
  }

  @action
  onCloudConfigChange(cloudConfig) {
    this.cloudConfig = cloudConfig;
  }
}

import Model, { attr } from "@ember-data/model";

export default class DataCenterModel extends Model {
  @attr name;

  @attr("boolean") hasNetworkSupport;
  @attr("boolean") hasFileStorageVolumeSupport;

  @attr("boolean") hasVmwareSupport;
  @attr("boolean") hasKvmSupport;
  @attr("boolean") hasObjectStorageInstanceSupport;
  @attr("boolean") hasPrivateNetworkSupport;
}

import Model, { attr, belongsTo } from "@ember-data/model";

export default class ServerStatusModel extends Model {
  @attr cpuusage;
  @attr memoryusage;
  @attr diskusage;
  @attr status;
  @attr isRunning;
  @attr isSuspended;
  @attr isLocked;

  get isStopped() {
    return !this.isRunning;
  }

  @belongsTo("server", { async: false, inverse: "status" }) server;
}

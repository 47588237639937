import { service } from "@ember/service";
import Route from "@ember/routing/route";
import RSVP from "rsvp";

export default class DnsRoute extends Route {
  @service ajax;

  buildRouteInfoMetadata() {
    return { jumpable: true };
  }

  model() {
    let { project } = this.modelFor("organization");

    return RSVP.hash({
      project,
      countries: this.ajax.request("/io.php/countries"),
      domains: project.get("dnsDomains"),
      pricelist: this.ajax.request(`/io.php/projects/${project.id}/domain/pricelist`),
    });
  }
}

import Component from "@glimmer/component";
import { action } from "@ember/object";
import { tracked } from "@glimmer/tracking";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { instanceOf } from "prop-types";
import { Task, task } from "ember-concurrency";
import user from "glesys-controlpanel/models/user";

@forbidExtraArgs
export default class RecoverConfirm2faUser extends Component {
  @arg(instanceOf(Task).isRequired) task;

  @arg(instanceOf(user).isRequired) user;

  @tracked currentStep = "select-action";
  otp = null;
  password = null;

  @action
  setStep(step) {
    this.currentStep = step;
  }

  recoverTask = task(async () => {
    let { otp, password } = this;
    await this.task.perform({
      action: this.currentStep,
      otp,
      password,
    });
  });
}

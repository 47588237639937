import hbs from "htmlbars-inline-precompile";
import { action } from "@storybook/addon-actions";

export default {
  title: "RadioButton",
  component: "RadioButton",
};

export const Default = (args) => ({
  template: hbs`
    {{! template-lint-disable no-bare-strings }}
    Selected value: <span class="font-bold">{{this.groupValue}}</span>


    <div class="flex justify-between w-44 pt-6">
      <RadioButton
        @name="add-type"
        @groupValue={{this.groupValue}}
        @value="add"
        @disabled={{this.disabled}}
        @onChange={{fn (mut this.groupValue)}}
      >
        Add
      </RadioButton>
      <RadioButton
        @name="add-type"
        @groupValue={{this.groupValue}}
        @value="transfer"
        @disabled={{this.disabled}}
        @onChange={{fn (mut this.groupValue)}}
      >
        Transfer
      </RadioButton>
    </div>
  `,
  context: args,
});

Default.args = {
  groupValue: "add",
  disabled: false,
  onChange: action("Selected"),
};

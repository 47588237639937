import Component from "@glimmer/component";
import { action } from "@ember/object";
import { isEqual } from "@ember/utils";
import { guidFor } from "@ember/object/internals";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { stringable } from "glesys-controlpanel/utils/prop-types";
import { bool, func } from "prop-types";

@forbidExtraArgs
export default class RadioButton extends Component {
  @arg(stringable) id = guidFor(this);

  @arg(stringable.isRequired) name;

  @arg groupValue;

  @arg value;

  @arg(func) onChange;

  @arg(bool) disabled = false;

  get checked() {
    return isEqual(this.groupValue, this.value);
  }

  @action
  onInputChange() {
    if (!isEqual(this.groupValue, this.value)) {
      this.onChange?.(this.value);
    }
  }
}

import RESTSerializer from "@ember-data/serializer/rest";
import { pluralize } from "ember-inflector";

export default class ApplicationSerializer extends RESTSerializer {
  fakeRestFormat = true;
  usesProxy = false;

  getPayload(payload) {
    if (!this.usesProxy) {
      return payload;
    }

    let key = Object.keys(payload).find((key) => !["debug", "meta", "status"].includes(key));

    return payload[key];
  }

  modelNameFromPayloadKey(key) {
    // The general best practice is to name all models using singular names.
    // However, in some cases the inflector has a hard time converting between
    // singular and plural. Also, in some cases this doesn't really make sense.
    switch (key) {
      case "email-quota":
        return key;
    }
    return super.modelNameFromPayloadKey(...arguments);
  }

  normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
    let normalizedPayload;

    if (this.fakeRestFormat) {
      let rootKey = pluralize(primaryModelClass.modelName);
      normalizedPayload = { [rootKey]: this.getPayload(payload) };
    } else {
      normalizedPayload = this.getPayload(payload);
    }

    return super.normalizeArrayResponse(store, primaryModelClass, normalizedPayload, id, requestType);
  }

  normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
    let normalizedPayload;

    if (this.fakeRestFormat) {
      let rootKey = primaryModelClass.modelName;
      normalizedPayload = { [rootKey]: this.getPayload(payload) };
    } else {
      normalizedPayload = this.getPayload(payload);
    }

    return super.normalizeSingleResponse(store, primaryModelClass, normalizedPayload, id, requestType);
  }

  normalizeDeleteRecordResponse(store, primaryModelClass, payload, id, requestType) {
    let normalizedPayload;

    if (this.fakeRestFormat) {
      let rootKey = primaryModelClass.modelName;
      normalizedPayload = { [rootKey]: { id } };
    } else {
      normalizedPayload = { id };
    }

    return super.normalizeDeleteRecordResponse(store, primaryModelClass, normalizedPayload, id, requestType);
  }

  serialize(snapshot /* , options */) {
    let payload = super.serialize(...arguments);
    if (snapshot.adapterOptions && snapshot.adapterOptions.payload) {
      Object.assign(payload, snapshot.adapterOptions.payload);
    }
    return payload;
  }

  serializeIntoHash(hash, modelClass, snapshot, options) {
    Object.assign(hash, this.serialize(snapshot, options));
  }
}

import Model, { attr, belongsTo, hasMany } from "@ember-data/model";
import { compare } from "@ember/utils";
import arrayDiff from "glesys-controlpanel/utils/array-diff";
import { service } from "@ember/service";
import dayjs from "dayjs";

export default class DnsDomainModel extends Model {
  @service intl;
  @attr domainname;
  @attr displayname;
  @attr registrarinfo;

  @attr resolvednameservers;

  get configurednameservers() {
    return this.registrarinfo?.configurednameservers;
  }

  get nameserverMismatch() {
    return this.isManagedByGlesys && compare(this.resolvednameservers, this.configurednameservers) !== 0;
  }

  get nameserverDiff() {
    return arrayDiff(this.configurednameservers, this.resolvednameservers);
  }

  @attr("yesno") usingglesysnameserver;

  // Part of details
  @attr primarynameserver;
  @attr responsibleperson;
  @attr refresh;
  @attr retry;
  @attr expire;
  @attr minimum;

  @belongsTo("project", { async: false, inverse: "dnsDomains" }) project;
  @hasMany("dns-record", { async: true, inverse: "domain" }) records;

  get isAutoRenewalActive() {
    return this.registrarinfo.autorenew === "yes";
  }

  get renewalDate() {
    return dayjs(this.registrarinfo.expire).subtract(30, "days").format("YYYY-MM-DD");
  }

  get isManagedByGlesys() {
    let state = this.state;
    return state !== "other-ok";
  }

  get failedDescription() {
    switch (this.registrarinfo?.state) {
      case "CHANGENAMESERVERSFAIL":
        return this.intl.t("dns.domain.failed-description.changenameservers");
      case "RENEWFAIL":
        return this.intl.t("dns.domain.failed-description.renew");
      case "REGISTRATIONFAIL":
        return this.intl.t("dns.domain.failed-description.registration");
      case "TRANSFERFAIL":
        return this.intl.t("dns.domain.failed-description.transfer");
      default:
        return "";
    }
  }

  get state() {
    let registrarinfo = this.registrarinfo;

    if (registrarinfo && registrarinfo.state) {
      switch (registrarinfo.state) {
        case "OK":
          return "glesys-ok";

        case "EXPIRED":
          return "glesys-expired";

        case "EXPIRING5":
        case "EXPIRING30":
        case "EXPIRING60":
          return "glesys-expiring";

        case "CHANGENAMESERVERS":
        case "CHANGINGNAMESERVERS":
        case "RENEW":
        case "RENEWING":
        case "REGISTER":
        case "REGISTERING":
        case "TRANSFER":
        case "TRANSFERING":
        case "TRANSFERSENT":
          return "glesys-waiting";

        case "CHANGENAMESERVERSFAIL":
        case "RENEWFAIL":
        case "REGISTRATIONFAIL":
        case "TRANSFERFAIL":
          return "glesys-failed";

        case "UNKNOWN":
        /* falls through */
        default:
          return "glesys-unknown";
      }
    }

    return "other-ok";
  }

  get isRenewFail() {
    return this.registrarinfo.state === "RENEWFAIL";
  }
}

import Modifier from "ember-modifier";
import { registerDestructor } from "@ember/destroyable";
import { action } from "@ember/object";

const cleanup = (instance) => {
  instance.removeEventListeners();
};
export default class OnSwipeModifier extends Modifier {
  defaultOptions = Object.freeze({ threshold: 45 });

  element;
  options;
  start = { x: 0, y: 0 };
  end;

  modify(element, [direction, callback], options) {
    this.element = element;
    this.options = { ...this.defaultOptions, ...options, direction, callback };
    this.addEventListeners();
    registerDestructor(this, cleanup);
  }

  get hasReachedThreshold() {
    if (!this.end) {
      return false;
    }

    if (["right", "left"].includes(this.options.direction)) {
      return Math.abs(this.start.x - this.end.x) >= this.options.threshold;
    }

    return Math.abs(this.start.y - this.end.y) >= this.options.threshold;
  }

  get swipedUp() {
    return this.start.y - this.end.y > 0;
  }

  get swipedDown() {
    return this.start.y - this.end.y < 0;
  }

  get swipedRight() {
    return this.start.x - this.end.x < 0;
  }

  get swipedLeft() {
    return this.start.x - this.end.x > 0;
  }

  @action
  onSwipeStart(event) {
    let point = event.targetTouches?.[0] ?? event;
    this.start = {
      x: point.clientX,
      y: point.clientY,
    };
  }

  @action
  onSwipeMove(event) {
    let point = event.targetTouches?.[0] ?? event;
    this.end = {
      x: point.clientX,
      y: point.clientY,
    };
  }

  @action
  onSwipeEnd(event) {
    if (!this.hasReachedThreshold) {
      return;
    }

    switch (this.options.direction) {
      case "up":
        this.swipedUp && this.options.callback(event);
        break;
      case "down":
        this.swipedDown && this.options.callback(event);
        break;
      case "right":
        this.swipedRight && this.options.callback(event);
        break;
      case "left":
        this.swipedLeft && this.options.callback(event);
        break;
    }
  }

  addEventListeners() {
    this.element.addEventListener("touchstart", this.onSwipeStart, { passive: true });
    this.element.addEventListener("mousedown", this.onSwipeStart, { passive: true });

    this.element.addEventListener("touchmove", this.onSwipeMove, { passive: true });
    this.element.addEventListener("mousemove", this.onSwipeMove, { passive: true });

    this.element.addEventListener("touchend", this.onSwipeEnd, { passive: true });
    this.element.addEventListener("mouseup", this.onSwipeEnd, { passive: true });
  }

  removeEventListeners() {
    this.element.removeEventListener("touchstart", this.onSwipeStart);
    this.element.removeEventListener("mousedown", this.onSwipeStart);

    this.element.removeEventListener("touchmove", this.onSwipeMove);
    this.element.removeEventListener("mousemove", this.onSwipeMove);

    this.element.removeEventListener("touchend", this.onSwipeEnd);
    this.element.removeEventListener("mouseup", this.onSwipeEnd);
  }
}

import { hash } from "rsvp";
import Route from "@ember/routing/route";
import { service } from "@ember/service";

export default class EmailDomainAccountRoute extends Route {
  @service router;

  model(params) {
    let { domain } = this.modelFor("email.domain");

    return hash({
      account: domain
        .get("accounts")
        .then((accounts) => accounts.find((account) => account.id === params.email_account_id)),
    });
  }

  afterModel({ account }) {
    if (!account) {
      let { domain } = this.modelFor("email.domain");
      this.router.replaceWith("email.domain", domain.id);
    }
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    controller.setupController();
  }
}

import hbs from "htmlbars-inline-precompile";
import { action } from "@storybook/addon-actions";

export default {
  title: "EditableField",
  component: "EditableField",
  argTypes: {
    task: {
      control: { type: null },
    },
  },
};

export const Default = (args) => ({
  template: hbs`
    <div class="p-16">
      <EditableField
        @placeholder={{this.placeholder}}
        @value={{this.value}}
        @canEdit={{this.canEdit}}
        @task={{this.task}}
      />
    </div>
  `,
  context: args,
});

Default.args = {
  placeholder: "Placeholder",
  value: "Value",
  canEdit: true,
  truncate: false,
  task: {
    perform: action("Task performed"),
  },
};

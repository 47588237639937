import Service from "@ember/service";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";

export default class TourService extends Service {
  @tracked isOpen = false;
  @tracked steps = [];
  @tracked currentIndex = null;

  onEndCallbacks = [];

  get current() {
    return this.steps[this.currentIndex];
  }

  get isFirst() {
    return this.currentIndex === 0;
  }

  get isLast() {
    return this.currentIndex === this.steps.length - 1;
  }

  get progress() {
    return {
      currentStep: this.currentIndex + 1,
      numberOfSteps: this.steps.length,
    };
  }

  new() {
    this.steps = [];

    return this;
  }

  addSteps(steps) {
    this.steps = this.steps.concat(steps);

    return this;
  }

  onEnd(callback) {
    this.onEndCallbacks.push(callback);

    return this;
  }

  start() {
    this.currentIndex = 0;
    this.isOpen = true;

    return this;
  }

  @action
  next() {
    if (this.isLast) {
      this.exit();
      return;
    }
    this.currentIndex += 1;
  }

  @action
  back() {
    if (this.isFirst) {
      return;
    }
    this.currentIndex -= 1;
  }

  @action
  exit() {
    this.isOpen = false;

    this.onEndCallbacks.forEach((callback) => callback());
  }
}

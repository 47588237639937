import Component from "@glimmer/component";
import { arg } from "ember-arg-types";
import { arrayOf, shape } from "prop-types";
import { stringable } from "glesys-controlpanel/utils/prop-types";

export default class EntityTable extends Component {
  @arg(
    arrayOf(
      shape({
        header: stringable.isRequired,
        value: stringable.isRequired,
      }),
    ),
  )
  entity;

  @arg(stringable) minWidthForColumn = "200px";

  get gridTemplateStyle() {
    return `grid-template-columns: repeat(auto-fill, minmax(${this.minWidthForColumn}, 1fr))`;
  }
}

import Service, { service } from "@ember/service";

export default class CostService extends Service {
  @service current;

  get currency() {
    return this.current.project.currency;
  }

  get prices() {
    return {
      loadBalancer: { SEK: 236, EUR: 23.57 },
      vpn: { SEK: 58.92, EUR: 5.89 },
      archive: { SEK: 1.17, EUR: 0.11 },
      remoteHands: {
        officeHours: { SEK: 1055, EUR: 105.51 },
        nonOfficeHours: { SEK: 1875, EUR: 187.46 },
      },
      network: { SEK: 295, EUR: 29.46 },
      email: {
        account: { SEK: 10.71, EUR: 1.07 },
        storage: { SEK: 3.21, EUR: 0.32 },
      },
      backup: {
        storage: { SEK: 0.35, EUR: 0.03 },
      },
      ipv4: { SEK: 26.78, EUR: 2.68 },
      ipv6: { SEK: 1.07, EUR: 0.1 },
    };
  }

  forManualBackup(size) {
    return this.formatCost(size * this.prices.backup.storage[this.currency]);
  }

  forNetworkAdapter(bandwidth, server, network) {
    let platformArguments = server?.get("platformArguments");

    let bandwidthOptions = platformArguments?.get("externalBandwidthOptions");

    if (network && !network.get("isPublic")) {
      bandwidthOptions = platformArguments?.get("internalBandwidthOptions");
    }

    let bandwidthOption = bandwidthOptions?.find((option) => option.value === bandwidth);

    return bandwidthOption ? bandwidthOption.basecost + bandwidthOption.licensecost : 0;
  }

  forEmailAccount(quota = 1) {
    return this.formatCost(
      (Math.ceil(quota) - 1) * this.prices.email.storage[this.currency] + this.prices.email.account[this.currency],
    );
  }

  forNetwork() {
    return this.formatCost(this.prices.network[this.currency]);
  }

  forRemoteHands() {
    return {
      officeHours: this.formatCost(this.prices.remoteHands.officeHours[this.currency]),
      nonOfficeHours: this.formatCost(this.prices.remoteHands.nonOfficeHours[this.currency]),
    };
  }

  forArchive(size) {
    return this.formatCost(size * this.prices.archive[this.currency]);
  }

  forVpnUser() {
    return this.formatCost(this.prices.vpn[this.currency]);
  }

  forLoadBalancer() {
    return this.formatCost(this.prices.loadBalancer[this.currency]);
  }

  forIpVersion(version) {
    return this.formatCost(this.prices[`ipv${version}`][this.currency]);
  }

  formatCost(cost) {
    return {
      cost: cost ? Number(cost.toFixed(2)) : "N/A",
      currency: this.currency ?? "",
    };
  }
}

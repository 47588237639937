/**
  @public
  @module glesys-controlpanel
*/

import Helper from "@ember/component/helper";
import { service } from "@ember/service";

export default class Enumerate extends Helper {
  @service intl;

  compute([array], { conjunctionKey = "helpers.enumerate.conjunction.and" }) {
    let conjunction = this.intl.t(conjunctionKey);
    let delimiter = this.intl.t("helpers.enumerate.delimiter");

    if (!array) {
      return "";
    }
    if (array.length == 2) {
      return array.join(` ${conjunction} `);
    }
    if (array.length > 2) {
      let lastWord = array.pop();
      return array.join(`${delimiter} `).concat(` ${conjunction} ${lastWord}`);
    } else {
      return array?.join();
    }
  }
}

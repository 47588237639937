import { hash } from "rsvp";
import Route from "@ember/routing/route";

export default class LoadBalancersOverviewRoute extends Route {
  model() {
    let { project } = this.modelFor("organization");
    return hash({
      project,
      loadBalancers: project.get("loadBalancers"),
      reservedIps: project.hasMany("reservedIps").reload(),
    });
  }
}

import ApplicationSerializer from "./application";
import DasherizedMixin from "./_dasherized-mixin";
import { EmbeddedRecordsMixin } from "@ember-data/serializer/rest";

export default class ServerSerializer extends ApplicationSerializer.extend(DasherizedMixin, EmbeddedRecordsMixin) {
  attrs = {
    hasManagedHosting: "managedhosting",
    ips: { embedded: "always" },
    project: "projectkey",
  };

  normalize(modelClass, resourceHash, prop) {
    resourceHash.ips = Object.values(resourceHash.ips);

    return super.normalize(modelClass, resourceHash, prop);
  }
}

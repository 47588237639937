import Model, { attr, belongsTo } from "@ember-data/model";
import { isNone } from "@ember/utils";

export default class ReservedIpModel extends Model {
  // Helper properties to easily distinguish between `available-ip` and
  // `reserved-ip` without having to look at `ip.constructor.modelName`
  isAvailable = false;
  isReserved = true;

  @attr address;
  @attr("yesno") lockedToProject;

  @attr cost;

  @attr dataCenter;
  @attr platforms;

  @attr ptr;

  @attr broadcast;
  @attr gateway;
  @attr nameservers;
  @attr netmask;

  @belongsTo("project", { async: false, inverse: "reservedIps" }) project;
  @belongsTo("server", { async: false, inverse: "ips" }) server;

  get serverId() {
    return this.belongsTo("server").id();
  }

  get targetType() {
    let serverId = this.serverId;
    if (isNone(serverId)) {
      return "none";
    }
    if (serverId.indexOf("lb") === 0) {
      return "load balancer";
    }
    return "server";
  }

  get version() {
    return `${this.address}`.indexOf(":") > -1 ? 6 : 4;
  }
}

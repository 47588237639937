import Model, { attr, belongsTo } from "@ember-data/model";

export default class FileStorageVolumeModel extends Model {
  @attr accessList;
  @attr cost;
  @attr dataCenter;
  @attr("yesno") hasAutoPromotion;
  @attr fqdn;
  @attr("yesno") isLocked;
  @attr plan;
  @attr name;
  @attr status;
  @attr("number") used;
  @attr maintenanceWindow;

  get nfsUrl() {
    let fqdn = this.fqdn;
    return fqdn ? `nfs://${fqdn}/dpool/nfs` : null;
  }

  get usedPercentage() {
    let { plan, used } = this;

    return Math.round((used / plan.quota) * 100);
  }

  @belongsTo("project", { async: false, inverse: "fileStorageVolumes" }) project;
}

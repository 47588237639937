import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";

import { action } from "@ember/object";
import { service } from "@ember/service";

import { task } from "ember-concurrency";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { object, array, shape } from "prop-types";

@forbidExtraArgs
export default class ModalsDnsAddExistingDomain extends Component {
  @service ajax;
  @service errorHandler;
  @service intl;
  @service modal;
  @service router;
  @service store;

  @arg(
    shape({
      project: object.isRequired,
      pricelist: array.isRequired,
    }).isRequired,
  )
  params;

  get project() {
    return this.params.project;
  }

  get pricelist() {
    return this.params.pricelist;
  }

  @tracked _domainNameQuery = "";
  @tracked domainName = "";
  @tracked domain = null;
  @tracked notSupportedTld = false;
  @tracked transferPrice = null;
  @tracked addDefaultRecords = true;

  get domainNameQuery() {
    return this._domainNameQuery;
  }

  set domainNameQuery(value) {
    this._domainNameQuery = value.toLowerCase();
  }

  get addDefaultRecordsDisabled() {
    return this.isArpaDomain;
  }

  authCode = null;

  @tracked addType = "add";

  get isTransferable() {
    if (this.domain && this.domainNameQuery === this.domainName && this.addType === "transfer") {
      return this.domain?.available !== "no" ? "x-red.svg" : "check-green.svg";
    }
    return null;
  }

  get cantBeTransferred() {
    if (this.domain && this.domainNameQuery === this.domainName && this.addType === "transfer") {
      return this.domain?.available !== "no";
    }
    return false;
  }

  get errorMessage() {
    let domain = `${this.domainName}`;
    return this.cantBeTransferred && this.notSupportedTld
      ? this.intl.t("component.modal.dns-add-existing-domain.messages.tld-is-unsupported")
      : this.intl.t("component.modal.dns-add-existing-domain.messages.domain-cant-be-transferred", { domain });
  }

  get isArpaDomain() {
    let domainName = `${this.domainName}`;
    return !!domainName.match(/\.(in-addr|ip6)\.arpa$/i);
  }

  get isTransfer() {
    return this.addType === "transfer";
  }

  get isAdd() {
    return !this.isTransfer;
  }

  get canTransfer() {
    return this.isTransferable && this.addType === "transfer" && this.domain && this.domain.available == "no";
  }

  get canSubmitForm() {
    return this.addType == "add"
      ? !this.domainNameQuery
      : this.isTransfer && !this.canTransfer && !this.submitTask.isRunning;
  }

  @action
  onFormChange({ addDefaultRecords }) {
    this.addDefaultRecords = Boolean(addDefaultRecords);
  }

  @action
  changeType(type) {
    this.addType = type;
    this.domain = null;
  }

  @action
  checkDomain() {
    this.checkDomainTask.perform(this.domainName);
  }

  checkDomainTask = task(async () => {
    this.domainName = this.domainNameQuery;
    this.domain = null;
    this.notSupportedTld = false;

    let tldExists = this.pricelist.find((tld) => {
      return this.domainName.split(".")[1] == tld.id;
    });

    if (typeof tldExists !== "undefined") {
      await this.checkIfDomainIsTransferableTask.perform(this.domainName);
      const domain = this.checkIfDomainIsTransferableTask.lastSuccessful?.value;

      let transferInformation = this.pricelist.find((tld) => {
        return domain[0].tld === tld.id;
      });

      this.domain = domain[0];
      this.transferPrice = transferInformation.regtransfer[0];
    } else {
      this.notSupportedTld = true;
      this.domain = {
        domainname: this.domainName,
      };
    }
  });

  addExistingDomainTask = task(async (domainname, createDefaultRecords) => {
    if (!domainname) return;
    let project = this.project;
    let domain = this.store.createRecord("dns-domain", { project, domainname });

    await domain
      .save({
        adapterOptions: {
          payload: {
            createrecords: createDefaultRecords,
          },
        },
      })
      .catch((error) => {
        domain.unloadRecord();
        throw error;
      });

    this.router.transitionTo("dns.domain", domain.id);
  });

  checkIfDomainIsTransferableTask = task(async (search) => {
    return this.ajax.post(`/io.php/projects/${this.project.id}/domain/available`, {
      data: {
        search,
      },
    });
  });

  transferDomainTask = task(async (domainname, addDefaultRecords, authcode) => {
    let project = this.project;
    let isNew = false;
    let domain = project
      .hasMany("dnsDomains")
      .value()
      .find((d) => {
        return d.id === domainname || d.domainname === domainname || d.displayname === domainname;
      });

    if (!domain) {
      isNew = true;
      domain = this.store.createRecord("dns-domain", { project, domainname });
      await domain.save().catch((error) => {
        domain.unloadRecord();
        throw error;
      });
    }

    let data = {
      domainname: domain.id,
      authcode,
    };

    try {
      await this.ajax.post(`/io.php/projects/${this.project.id}/domain/transfer`, { data });
    } catch (error) {
      if (isNew) {
        domain.destroyRecord();
      }
      throw error;
    }

    this.router.transitionTo("dns.domain", domain.id);
  });

  submitTask = task(async () => {
    this.domainName = this.domainNameQuery;

    if (this.addType == "transfer" && !this.canTransfer) {
      this.checkDomainTask.perform(this.domainName);
      return;
    }

    let task = this.addType === "add" ? this.addExistingDomainTask : this.transferDomainTask;
    let domainName = this.domainName;
    let addDefaultRecords = this.addType === "add" ? this.addDefaultRecords : false;
    let authCode = this.authCode;

    await task.perform(domainName, addDefaultRecords, authCode);

    this.modal.close();
  });
}

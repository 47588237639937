import { helper } from "@ember/component/helper";

export function projectColorClass(color) {
  switch (color[0]) {
    case "skyblue":
      return "bg-powder-blue";
    case "midnightblue":
      return "bg-midnight-blue";
    case "lightcoral":
      return "bg-red-light";
    case "mediumaquamarine":
      return "bg-ocean-green";
    case "sandybrown":
      return "bg-orange-light";
    case "lemonchiffon":
      return "bg-yellow-light";
    case "rebeccapurple":
      return "bg-rebeccapurple";
    case "silver":
    default:
      return "bg-gray-light";
  }
}

export default helper(projectColorClass);

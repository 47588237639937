import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";

import { action } from "@ember/object";
import { service } from "@ember/service";

import { Task, task } from "ember-concurrency";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { array, bool, instanceOf, shape, string } from "prop-types";
import Nameserver from "./nameserver";

@forbidExtraArgs
export default class ModalsDnsChangeNameservers extends Component {
  @service modal;

  @arg(
    shape({
      task: instanceOf(Task).isRequired,
      domainName: string.isRequired,
      configuredNameservers: array.isRequired,
      resolvedNameservers: array.isRequired,
      isMismatch: bool.isRequired,
    }).isRequired,
  )
  params;

  get task() {
    return this.params.task;
  }

  get domainName() {
    return this.params.domainName;
  }

  get configuredNameservers() {
    return this.params.configuredNameservers;
  }

  get resolvedNameservers() {
    return this.params.resolvedNameservers;
  }

  get isMismatch() {
    return this.params.isMismatch;
  }

  defaultNameserversList = ["", "", "", ""];
  @tracked sortedNameservers = this.configuredNameservers
    .sort()
    .concat(this.defaultNameserversList)
    .slice(0, 4)
    .map((data, index) => {
      let [nameserver, ...glueRecord] = data.split(" ");
      return new Nameserver(index + 1, this.domainName, nameserver, glueRecord.join(" "));
    });

  submitTask = task(async () => {
    await this.task.perform({
      ns1: this.transformNameserver(this.sortedNameservers[0].nameserver, this.sortedNameservers[0].glueRecord),
      ns2: this.transformNameserver(this.sortedNameservers[1].nameserver, this.sortedNameservers[1].glueRecord),
      ns3: this.transformNameserver(this.sortedNameservers[2].nameserver, this.sortedNameservers[2].glueRecord),
      ns4: this.transformNameserver(this.sortedNameservers[3].nameserver, this.sortedNameservers[3].glueRecord),
    });
    this.modal.close();
  });

  @action
  useGlesysNameservers() {
    this.sortedNameservers[0].nameserver = "ns1.namesystem.se";
    this.sortedNameservers[1].nameserver = "ns2.namesystem.se";
    this.sortedNameservers[2].nameserver = "ns3.namesystem.se";
    this.sortedNameservers[3].nameserver = "";
  }

  transformNameserver(nameserver, glueRecord) {
    return `${nameserver} ${glueRecord}`.trim();
  }
}

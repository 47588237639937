import { helper } from "@ember/component/helper";

/**
  Transform and return datacenter id to datacenter nice name
  with data from DataCenterController

  @public
  @method datacenter-nice-name
  @for helpers
  @param datacenterId {string}
  @param dataCenters {model}

  @example

  `{{data-center-nice-name 'dc-sto1' this.datacenters}}` returns `Stockholm`
*/

export function dataCenterNiceName(params) {
  const dataCenters = params[1];

  let datacenter = dataCenters.filter((datacenter) => {
    return datacenter.id == params[0];
  });

  return datacenter.length == 1 ? datacenter[0].name : params[0];
}

export default helper(dataCenterNiceName);

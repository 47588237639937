import Component from "@glimmer/component";
import EmberObject, { action } from "@ember/object";
import { tracked } from "@glimmer/tracking";
import { isEqual, isNone } from "@ember/utils";
import { next } from "@ember/runloop";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { array, bool, func, instanceOf, oneOf, oneOfType, string } from "prop-types";
import { stringable } from "glesys-controlpanel/utils/prop-types";
import { PromiseManyArray } from "@ember-data/model/-private";

@forbidExtraArgs
export default class extends Component {
  @arg(bool) searchable = false;

  @arg(bool) disabled = false;

  @arg(func) onChange;

  @arg(oneOf(["click", "hover"])) event = "click";

  get eventTrigger() {
    return this.event;
  }

  @arg(stringable) placeholder;

  @arg(string) key = "value";

  get valueKey() {
    return this.key;
  }

  @arg(oneOfType([array, instanceOf(EmberObject), instanceOf(PromiseManyArray)]).isRequired) items = [];

  @arg selected;

  get parsedItems() {
    return this.items;
  }

  get hasGroups() {
    return this.parsedItems[0]?.items !== undefined;
  }

  get allItemsFlattened() {
    return this.parsedItems?.flatMap((item) => {
      return item.items ? item.items.map((i) => i) : item;
    });
  }

  @tracked selectedItem = null;

  @tracked isOpen = false;

  isFormField(event) {
    return ["input", "textarea", "select", "button"].includes(event.target.nodeName.toLowerCase());
  }

  @action
  registerListener(element) {
    document.addEventListener("keydown", this.keyDown);

    switch (this.eventTrigger) {
      case "hover":
        element.addEventListener("mouseenter", this.open);
        element.addEventListener("mouseleave", this.close);
        break;
      case "click":
      default:
        element.addEventListener("click", this.handleClick);
    }
  }

  @action
  unregisterListener(element) {
    document.removeEventListener("keydown", this.keyDown);

    switch (this.eventTrigger) {
      case "hover":
        element.removeEventListener("mouseenter", this.open);
        element.removeEventListener("mouseleave", this.close);
        break;
      case "click":
      default:
        element.removeEventListener("click", this.handleClick);
    }
  }

  @action
  handleClick(event) {
    if (this.isFormField(event)) {
      return;
    }

    this.toggle();
  }

  @action
  keyDown(event) {
    if (event.key === "Escape") {
      this.close();
    }
  }

  @action
  updateSelected() {
    if (!isNone(this.selected)) {
      this.selectedItem = this.allItemsFlattened.find((item) =>
        isEqual(item[this.valueKey], this.selected[this.valueKey] || this.selected),
      );
    } else if (!this.placeholder) {
      next(() => {
        this.selectItem(this.hasGroups ? this.parsedItems[0].items[0] : this.parsedItems[0]);
      });
    }
  }

  @action
  open() {
    this.isOpen = true;
  }

  @action
  close() {
    this.isOpen = false;
  }

  @action
  toggle() {
    this.isOpen = !this.isOpen;
  }

  @action
  selectItem(item) {
    this.selectedItem = item;
    this.onChange?.(item);
    this.close();
  }
}

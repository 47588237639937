import { helper } from "@ember/component/helper";

export function mibToGib(mib) {
  mib = parseFloat(mib);
  let gib = mib / 1024;

  if (mib % 1024 > 0) {
    return parseFloat(gib.toFixed(1));
  }

  return gib;
}

export default helper(mibToGib);

import hbs from "htmlbars-inline-precompile";
import { action } from "@storybook/addon-actions";
import { faker } from "@faker-js/faker";

export default {
  title: "Select",
  component: "Select",
  parameters: {
    docs: {
      description: {
        component: `This component can be used like a replacement for the browsers native select,
        or like a dropdown for things like menus.`,
      },
    },
  },
  argTypes: {
    eventTrigger: {
      options: ["hover", "click"],
      control: {
        type: "select",
      },
    },
    prefersPosition: {
      options: ["top-left", "top", "top-right", "right", "bottom-right", "bottom", "bottom-left", "left"],
      control: {
        type: "select",
      },
    },
    items: {
      control: {
        type: "object",
      },
    },
  },
};

export const Default = (args) => ({
  template: hbs`
    <div class="max-w-md p-16 h-screen">
      <div class="flex flex-col justify-between h-full">
        <Select @placeholder={{this.placeholder}} @event={{this.eventTrigger}} @items={{this.items}} @selected={{this.items}} @searchable={{this.searchable}} @disabled={{this.disabled}} @onChange={{this.onChange}} />
      </div>
    </div>
  `,
  context: args,
});

Default.args = {
  onChange: action("Item selected"),
  eventTrigger: "click",
  searchable: false,
  disabled: false,
  placeholder: "Select an item",
  items: Array(20)
    .fill()
    .map(() => ({ value: `${faker.person.firstName()} ${faker.person.lastName()}` }))
    .sort(),
};

Default.parameters = {
  docs: {
    description: {
      story: `
      \`\`\`js
let items = [
  {
    value: "Item 1"
  },
  {
    value: "Item 2"
  }
];
\`\`\`
`,
    },
  },
};

export const WithInitialValue = (args) => ({
  template: hbs`
    <div class="max-w-md p-16 h-screen">
      <div class="flex flex-col justify-between h-full">
        <Select @placeholder={{this.placeholder}} @event={{this.eventTrigger}} @items={{this.items}} @selected={{this.selected}} @searchable={{this.searchable}} @onChange={{this.onChange}} />

        <Select @placeholder={{this.placeholder}} @event={{this.eventTrigger}} @items={{this.items}} @selected={{this.selected}} @searchable={{this.searchable}} @onChange={{this.onChange}} />
      </div>
    </div>
  `,
  context: args,
});

WithInitialValue.args = {
  onChange: action("Item selected"),
  eventTrigger: "click",
  searchable: false,
  selected: "Default option",
  placeholder: "Select an item",
  items: Array(20)
    .fill()
    .map(() => ({ value: `${faker.person.firstName()} ${faker.person.lastName()}` }))
    .concat({ value: `Default option` }),
};

export const WithCustomValueKey = (args) => ({
  template: hbs`
    <div class="max-w-md p-16 h-screen">
      <div class="flex flex-col justify-between h-full">
        <Select @key={{this.key}} @placeholder={{this.placeholder}} @event={{this.eventTrigger}} @items={{this.items}} @selected={{this.selected}} @searchable={{this.searchable}} @onChange={{this.onChange}} />

        <Select @key={{this.key}} @placeholder={{this.placeholder}} @event={{this.eventTrigger}} @items={{this.items}} @selected={{this.selected}} @searchable={{this.searchable}} @onChange={{this.onChange}} />
      </div>
    </div>
  `,
  context: args,
});

WithCustomValueKey.argTypes = {
  key: {
    options: ["firstname", "lastname", "fullname"],
    control: {
      type: "select",
    },
  },
};

WithCustomValueKey.args = {
  onChange: action("Item selected"),
  eventTrigger: "click",
  searchable: false,
  selected: "Default option",
  key: "fullname",
  placeholder: "Select an item",
  items: Array(20)
    .fill()
    .map(() => {
      let firstname = faker.person.firstName();
      let lastname = faker.person.lastName();
      return {
        firstname,
        lastname,
        fullname: `${firstname} ${lastname}`,
      };
    }),
};

export const WithSearch = (args) => ({
  template: hbs`
    <div class="max-w-md p-16 h-screen">
      <div class="flex flex-col justify-between h-full">
        <Select @items={{this.items}} @searchable={{this.searchable}}
        @onChange={{this.onChange}} />

        <Select @items={{this.items}} @searchable={{this.searchable}}
        @onChange={{this.onChange}} />
      </div>
    </div>
  `,
  context: args,
});

WithSearch.args = {
  onChange: action("Item selected"),
  eventTrigger: "click",
  searchable: true,
  selected: "Default option",
  items: Array(20)
    .fill()
    .map(() => ({ value: `${faker.person.firstName()} ${faker.person.lastName()}` }))
    .concat({ value: `Default option` }),
  placeholder: "Select an item",
};

export const WithBlock = (args) => ({
  template: hbs`
    <div class="max-w-md p-16">
      <Select @items={{this.items}} @searchable={{this.searchable}}
       @onChange={{this.onChange}} as |item|>
        {{item.before}} {{item.value}}
      </Select>
    </div>
  `,
  context: args,
});

WithBlock.args = {
  onChange: action("Item selected"),
  eventTrigger: "click",
  searchable: false,
  items: Array(20)
    .fill()
    .map(() => ({
      before: faker.helpers.arrayElement(["🔥", "😎", "🎉"]),
      value: `${faker.person.firstName()} ${faker.person.lastName()}`,
    }))
    .sort(),
};

export const WithGroups = (args) => ({
  template: hbs`
    <div class="max-w-md p-16">
      <Select @items={{this.items}} @selected={{this.selectedValue}} @searchable={{this.searchable}}
       @onChange={{this.onChange}} />
    </div>
  `,
  context: args,
});

WithGroups.args = {
  onChange: action("Item selected"),
  eventTrigger: "click",
  searchable: false,
  selectedValue: null,
  items: [
    {
      items: [{ value: "none" }],
    },
    {
      groupName: "Group 1",
      items: Array(20)
        .fill()
        .map(() => ({ value: `${faker.person.firstName()} ${faker.person.lastName()}` }))
        .sort(),
    },
    {
      groupName: "Group 2",
      items: Array(20)
        .fill()
        .map(() => ({ value: `${faker.person.firstName()} ${faker.person.lastName()}` }))
        .sort(),
    },
  ],
};

export const Menu = (args) => ({
  template: hbs`
    <div class="w-full py-16 h-screen flex justify-between">
      <div class="flex flex-col justify-between">
        <Select::Menu @items={{this.items}} @event={{this.eventTrigger}} @disabled={{this.disabled}} @onChange={{this.onChange}} as |Menu|>
          <Menu.Header>
            <div class="flex items-center">
              <img class="rounded-full mr-2" src="/assets/images/avatar-generic-40x40.png" alt="" role="none" />
              {{! template-lint-disable no-bare-strings }}
              John Doe
            </div>
          </Menu.Header>
          <Menu.Dropdown as |item|>
            <span class="whitespace-nowrap">{{item.before}} {{item.value}}</span>
          </Menu.Dropdown>
        </Select::Menu>

        <Select::Menu @items={{this.items}} @event={{this.eventTrigger}} @disabled={{this.disabled}} @onChange={{this.onChange}} as |Menu|>
          <Menu.Header>
            <div class="flex items-center">
              <img class="rounded-full mr-2" src="/assets/images/avatar-generic-40x40.png" alt="" role="none" />
              {{! template-lint-disable no-bare-strings }}
              John Doe
            </div>
          </Menu.Header>
          <Menu.Dropdown />
        </Select::Menu>
      </div>
      <div class="flex flex-col justify-between">
        <Select::Menu @items={{this.items}} @event={{this.eventTrigger}} @disabled={{this.disabled}} @onChange={{this.onChange}} as |Menu|>
          <Menu.Header>
            <div class="flex items-center">
              <img class="rounded-full mr-2" src="/assets/images/avatar-generic-40x40.png" alt="" role="none" />
              {{! template-lint-disable no-bare-strings }}
              John Doe
            </div>
          </Menu.Header>
          <Menu.Dropdown as |item|>
            <span class="whitespace-nowrap">{{item.before}} {{item.value}}</span>
          </Menu.Dropdown>
        </Select::Menu>

        <Select::Menu @items={{this.items}} @event={{this.eventTrigger}} @disabled={{this.disabled}} @onChange={{this.onChange}} as |Menu|>
          <Menu.Header>
            <div class="flex items-center">
              <img class="rounded-full mr-2" src="/assets/images/avatar-generic-40x40.png" alt="" role="none" />
              {{! template-lint-disable no-bare-strings }}
              John Doe
            </div>
          </Menu.Header>
          <Menu.Dropdown />
        </Select::Menu>
      </div>
    </div>
  `,
  context: args,
});

Menu.args = {
  onChange: action("item selected"),
  eventTrigger: "click",
  disabled: false,
  items: [
    {
      items: [
        {
          id: "account",
          before: "😊",
          value: "Account and other stuff",
        },
        {
          before: "⚙️",
          value: "Settings",
        },
      ],
    },
    {
      items: [
        {
          before: "🚪",
          value: "Log out",
        },
      ],
    },
  ],
};

Menu.parameters = {
  docs: {
    description: {
      story: `Good for basic dropdowns like menus`,
    },
  },
};

export const MenuWithCustomContent = (args) => ({
  template: hbs`
    <div class="max-w-md p-16 h-screen flex flex-col justify-between">
      <Select::Menu @event={{this.eventTrigger}} as |Menu|>
        <Menu.Header>
          <div class="flex items-center">
            {{! template-lint-disable no-bare-strings }}
            More
          </div>
        </Menu.Header>
        <Menu.Dropdown>
          {{#each this.items as |item|}}
            <Select::Option @disabled={{item.disabled}} {{on "click" this.onChange}}>
              <span class="whitespace-nowrap">{{item.before}} {{item.value}}</span>
            </Select::Option>
          {{/each}}
        </Menu.Dropdown>
      </Select::Menu>
    </div>
  `,
  context: args,
});

MenuWithCustomContent.args = {
  onChange: action("item selected"),
  eventTrigger: "click",
  items: [
    {
      id: "account",
      before: "😊",
      value: "Account",
    },
    {
      before: "⚙️",
      value: "Settings",
    },
    {
      before: "🔒",
      value: "Disabled Item",
      disabled: true,
    },
    {
      before: "🚪",
      value: "Log out",
    },
  ],
};

export const SelectWithPreferredPosition = (args) => ({
  template: hbs`
    <div class="max-w-md max-h-full h-screen flex flex-col justify-center items-center">
      <Select::Menu @event={{this.eventTrigger}} as |Menu|>
        <Menu.Header>
          <div class="flex items-center">
            {{! template-lint-disable no-bare-strings }}
            More
          </div>
        </Menu.Header>
        <Menu.Dropdown @prefersPosition={{this.prefersPosition}}>
          {{#each this.items as |item|}}
            <Select::Option @disabled={{item.disabled}} {{on "click" this.onChange}}>
              <span class="whitespace-nowrap">{{item.before}} {{item.value}}</span>
            </Select::Option>
          {{/each}}
        </Menu.Dropdown>
      </Select::Menu>
    </div>
  `,
  context: args,
});

SelectWithPreferredPosition.args = {
  onChange: action("item selected"),
  eventTrigger: "click",
  prefersPosition: "bottom-left",
  items: [
    {
      id: "account",
      before: "😊",
      value: "Account",
    },
    {
      before: "⚙️",
      value: "Settings",
    },
    {
      before: "🔒",
      value: "Disabled Item",
      disabled: true,
    },
    {
      before: "🚪",
      value: "Log out",
    },
  ],
};

import Component from "@glimmer/component";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { arrayOf, number, shape } from "prop-types";
import { stringable } from "glesys-controlpanel/utils/prop-types";

@forbidExtraArgs
export default class FileStorageVolumeUsageChart extends Component {
  @arg(
    arrayOf(
      shape({
        key: stringable,
        value: number,
      }),
    ),
  )
  data = [];
  @arg(stringable) unit;
  @arg(number) max;

  get formattedData() {
    let data = this.data;

    return {
      datasets: [
        {
          backgroundColor: "#9ADEBC",
          borderColor: "#63CC98",
          data: data.map((item) => item.value),
          label: false,
          lineTension: 0,
          borderWidth: 2,
          fill: true,
          pointHitRadius: 4,
        },
      ],
      labels: data.map((item) => item.key),
    };
  }

  get options() {
    return {
      plugins: {
        annotation: {
          annotations: {
            line: {
              type: "line",
              display: true,
              yMin: this.max,
              yMax: this.max,
              borderDash: [10, 30],
            },
          },
        },
      },
      scales: {
        y: {
          grid: { display: false, drawBorder: false },
        },
        x: {
          grid: {
            display: true,
            drawBorder: false,
          },
          time: {
            unit: "day",
            tooltipFormat: "YYYY-MM-DD",
          },
        },
      },
    };
  }
}

import { tracked } from "@glimmer/tracking";

export default class PaginationDto {
  @tracked page = 1;
  @tracked total;
  @tracked perPage = 10;

  constructor(page, total, perPage) {
    this.page = page;
    this.total = total;
    this.perPage = perPage;
  }

  update(page, total, perPage) {
    this.page = page || this.page;
    this.total = total || this.total;
    this.perPage = perPage || this.perPage;
  }

  get hasRange() {
    return Boolean(this.range.end - this.range.start);
  }

  get range() {
    let start = (this.page - 1) * this.perPage + 1;
    let end = start - 1 + this.perPage;
    if (end > this.total) {
      end = this.total;
    }
    return {
      start,
      end,
    };
  }

  get isPaginated() {
    return this.numberOfPages > 1;
  }

  get numberOfPages() {
    return Math.ceil(this.total / this.perPage);
  }

  get isFirstPage() {
    return this.page === 1;
  }

  get isLastPage() {
    return this.page === this.numberOfPages;
  }

  itemsForPage(items) {
    let { start, end } = this.range;

    if (this.total !== items?.length) {
      return items;
    }

    return items?.slice(start - 1, end);
  }
}

import Component from "@glimmer/component";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { oneOf } from "prop-types";
import { stringable } from "glesys-controlpanel/utils/prop-types";

@forbidExtraArgs
export default class AlertMessage extends Component {
  @arg(stringable) message;

  @arg(oneOf(["confirmation", "information", "warning", "error"])) type;

  get classes() {
    switch (this.type) {
      case "confirmation":
        return "bg-ocean-green-lightest text-ocean-green-darker border rounded border-ocean-green";
      case "information":
        return "bg-powder-blue-lightest text-powder-blue-darker border rounded border-powder-blue-light";
      case "warning":
        return "bg-orange-lightest text-orange-darker border rounded border-orange-lighter";
      case "error":
        return "bg-red-lightest text-red-dark border rounded border-red-lighter";
      default:
        return "bg-white text-gray-darker border rounded border-gray-light";
    }
  }
}

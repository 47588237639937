import Component from "@glimmer/component";
import { dropTask } from "ember-concurrency";
import { action } from "@ember/object";
import { tracked } from "@glimmer/tracking";
import { service } from "@ember/service";
import { isNone } from "@ember/utils";

export default class ModalsOrganismDelete extends Component {
  @service modal;

  //eslint-disable-next-line ember/no-tracked-properties-from-args
  @tracked userInput;
  //eslint-disable-next-line ember/no-tracked-properties-from-args
  @tracked entityOption = this.args.params.entityOption;

  get entity() {
    return this.args.params.entity;
  }

  get entityKey() {
    return this.args.params.entityKey;
  }

  get task() {
    return this.args.params.task;
  }

  get leadText() {
    return this.args.params.leadText;
  }

  get hasEntityOption() {
    return !isNone(this.entityOption);
  }

  get entityOptionCondition() {
    return this.args.params.entityOptionCondition;
  }

  get entityOptionLabel() {
    return this.args.params.entityOptionLabel;
  }

  get entityOptionText() {
    return this.args.params.entityOptionText;
  }

  get isConfirmable() {
    return this.entity == this.userInput && !this.task.isRunning;
  }

  get isDisabled() {
    return !this.isConfirmable || this.task.isRunning;
  }

  @action
  toggleEntityOption() {
    this.entityOption = !this.entityOption;
  }

  submitTask = dropTask(async () => {
    let options = this.entityOption;
    if (this.isDisabled) {
      return;
    }
    if (options === null) {
      await this.task.perform();
    } else {
      await this.task.perform(options);
    }
    this.modal.close();
  });
}

import { template } from "@ember/template-compiler";
import GButton from "./";
import mergeClasses from "glesys-controlpanel/modifiers/merge-classes";
import IconsPen from "../icons/pen";
export default template(`
  <GButton
    @type={{@type}}
    @color="outline"
    @disabled={{@disabled}}
    @loading={{@loading}}
    @display={{if @display @display "default"}}
    class="h-10"
    {{mergeClasses}}
    ...attributes
  >
    <div class="flex items-center">
      <IconsPen class="w-3 mr-2 -ml-1 -mt-px text-powder-blue" />
      <span>{{yield}}</span>
    </div>
  </GButton>
`, {
    eval () {
        return eval(arguments[0]);
    }
});

import Route from "@ember/routing/route";
import RSVP from "rsvp";
import { service } from "@ember/service";

export default class IpsOverviewRoute extends Route {
  @service store;

  buildRouteInfoMetadata() {
    return { jumpable: true };
  }

  model() {
    let { project } = this.modelFor("organization");

    return RSVP.hash({ project });
  }
}

import Controller from "@ember/controller";
import { action } from "@ember/object";
import { tracked } from "@glimmer/tracking";

export default class ServerStatisticsController extends Controller {
  queryParams = ["resolution", "resource"];

  get data() {
    return this.model.data;
  }

  get server() {
    return this.model.server;
  }

  @tracked resolution = null;
  @tracked resource = null;

  resolutionOptions = Object.freeze([
    { label: "Day", value: "day" },
    { label: "Hour", value: "hour" },
    { label: "Minute", value: "minute" },
  ]);

  get resourceOptions() {
    let server = this.server;

    if (server.get("isVMware")) {
      return [
        { label: "CPU", value: "cpuusage" },
        { label: "Memory", value: "memoryusage" },
        { label: "Disk (read + write)", value: "diskioread" },
        { label: "Transfer (Total)", value: "transfertotal" },
      ];
    }
    return [];
  }

  get chartData() {
    return {
      datasets: [
        {
          data: this.data.data.map((data) => ({
            x: new Date(data[0]),
            y: data[1],
          })),
        },
      ],
      labels: this.data.data.map((data) => new Date(data[0])),
    };
  }

  @action
  setResolution(resolution) {
    this.resolution = resolution.value;
  }

  @action
  setResource(resource) {
    this.resource = resource.value;
  }
}

import Controller from "@ember/controller";
import { service } from "@ember/service";

export default class DashboardController extends Controller {
  @service errorHandler;
  @service notification;
  @service current;
  @service router;
  @service session;
  @service modal;

  get projects() {
    return this.model.projects;
  }

  get organizations() {
    return this.model.organizations;
  }

  get organization() {
    return this.current.organization;
  }

  get user() {
    return this.model.user;
  }
}

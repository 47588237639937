import Model, { attr, belongsTo, hasMany } from "@ember-data/model";

export default class NetworkModel extends Model {
  @attr dataCenter;
  @attr description;
  @attr("yesno") isPublic;
  @attr price;

  @belongsTo("project", { async: false, inverse: "networks" }) project;
  @hasMany("network-adapter", { async: true, inverse: "network" }) networkAdapters;

  get isPrivate() {
    return !this.isPublic;
  }
}

import { template } from "@ember/template-compiler";
import GButton from "./";
export default template(`
  <GButton
    @type={{@type}}
    @color={{if @color @color "green"}}
    @disabled={{@disabled}}
    @loading={{@loading}}
    @display={{if @display @display "compact"}}
    ...attributes
  >
    <div class="flex items-center">
      {{yield}}
    </div>
  </GButton>
`, {
    eval () {
        return eval(arguments[0]);
    }
});

import Component from "@glimmer/component";
import { action } from "@ember/object";
import { tracked } from "@glimmer/tracking";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { func } from "prop-types";
import { stringable } from "glesys-controlpanel/utils/prop-types";

@forbidExtraArgs
export default class DnsGroupPaginated extends Component {
  @arg(stringable.isRequired) type;

  @arg(func.isRequired) onTemplate;

  @arg records;

  @arg servers;

  pageSize = 30;

  @tracked page = 1;

  get pages() {
    let len = this.records.length;
    let pageSize = this.pageSize;
    return Math.ceil(len / pageSize);
  }

  get paginatedRecords() {
    let { records, page, pageSize } = this;
    let index = (page - 1) * pageSize;
    if (records.length > 0) {
      return records.slice(index, index + pageSize);
    }

    return [];
  }

  @action
  changePage(diff) {
    let { page, pages } = this;
    let np = parseInt(page, 10) + parseInt(diff, 10);

    if (np > 0 && np <= pages) {
      this.page = np;
    }
  }
}

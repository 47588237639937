import Component from "@glimmer/component";
import { action } from "@ember/object";
import { tracked } from "@glimmer/tracking";
import { Task, restartableTask, timeout } from "ember-concurrency";
import ENV from "glesys-controlpanel/config/environment";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { func, instanceOf } from "prop-types";
import { stringable } from "glesys-controlpanel/utils/prop-types";

@forbidExtraArgs
export default class extends Component {
  @arg(instanceOf(Task)) searchTask;
  @arg(stringable) searchQuery = "";
  @arg(func.isRequired) onChange;

  @tracked error = false;

  @tracked query = this.searchQuery;

  get searchQueryIsTooShort() {
    return this.searchQuery.length > 0 && this.searchQuery.length < 3;
  }

  @action
  didUpdateQuery() {
    this.query = this.searchQuery;
  }

  @action
  onClick(event) {
    this.error = false;
    if (this.searchQuery.length < 3) {
      this.error = true;
      event.preventDefault();
    }
  }

  @action
  onInputChange(event) {
    this.onChange(event.target.value);
    this.debounce.perform();
  }

  debounce = restartableTask(async () => {
    if (this.searchQueryIsTooShort) {
      if (ENV.environment !== "test") {
        await timeout(1000);
      }
      this.error = true;
    } else {
      this.error = false;
    }
  });
}

import { service } from "@ember/service";
import Controller from "@ember/controller";
import { task } from "ember-concurrency";

export default class OrganizationSettingsController extends Controller {
  @service ajax;

  get organization() {
    return this.model.organization;
  }

  saveDetailsTask = task(async (properties) => {
    let organization = this.organization;
    organization.setProperties(properties);

    await organization
      .save()
      .then(() => {})
      .catch((error) => {
        organization.rollbackAttributes();
        throw error;
      });
  });
}

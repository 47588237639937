import ApplicationSerializer from "./application";

export default class PrivateNetworkSegmentSerializer extends ApplicationSerializer {
  usesProxy = true;

  attrs = {
    dataCenter: "datacenter",
    privateNetwork: "privatenetworkid",
  };
}

import Transform from "@ember-data/serializer/transform";

export default class MillisecondsAsSecondsTransform extends Transform {
  deserialize(serialized) {
    return parseInt(serialized, 10) / 1000;
  }

  serialize(deserialized) {
    return parseInt(deserialized, 10) * 1000;
  }
}

import { tracked } from "@glimmer/tracking";
import { task } from "ember-concurrency";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { action } from "@ember/object";

export default class ModalsServerOpenConsole extends Component {
  @service ajax;
  @service store;

  @tracked console;
  @tracked consoleType;

  get server() {
    return this.args.params.server;
  }

  get vncUrl() {
    let { protocol, password, host, port } = this.console;
    protocol = protocol.toLowerCase();
    return `${protocol}://:${password}@${host}:${port}`;
  }

  get showConsoleInfo() {
    return this.console;
  }

  get isVNCConsole() {
    return this.consoleType === "vnc";
  }

  get isHTMLConsole() {
    return this.consoleType === "html";
  }

  @action
  openVNC() {
    this.fetchConsoleData.perform("vnc");
  }

  @action
  openHTML() {
    this.fetchConsoleData.perform("html");
  }

  fetchConsoleData = task(async (type) => {
    let { server, store } = this;
    let serverAdapter = store.adapterFor(server.constructor.modelName);

    let data = await serverAdapter.console(server);
    this.console = data;
    this.consoleType = type;

    if (type === "html") {
      window.open(
        data.url,
        "_blank",
        "toolbar=no, scrollbars=yes, resizable=yes, top=50, left=50, width=1096, height=900",
      );
    }
  });
}

import Controller from "@ember/controller";
import { task } from "ember-concurrency";
import { service } from "@ember/service";

export default class PrivateNetworksOverviewController extends Controller {
  @service router;
  @service store;

  get project() {
    return this.model.project;
  }

  get privateNetworks() {
    return this.model.privateNetworks;
  }

  createPrivateNetworkTask = task(async (name) => {
    let properties = {
      project: this.project,
      name,
    };

    let privateNetwork = this.store.createRecord("private-network", properties);
    await privateNetwork.save();

    this.router.transitionTo("private-networks.details", privateNetwork.id);
  });
}

import { service } from "@ember/service";
import Route from "@ember/routing/route";

export default class NotFoundRoute extends Route {
  @service metrics;

  afterModel(model, transition) {
    let url = window.location.hash;
    let routeName = transition.from?.name;

    this.metrics.trackEvent("GoogleAnalytics", {
      category: "not-found",
      action: `${url}`,
      label: routeName || "no previous route, direct navigation to url",
    });
  }
}

import Component from "@glimmer/component";
import { action } from "@ember/object";
import { tracked } from "@glimmer/tracking";
import { service } from "@ember/service";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { instanceOf } from "prop-types";
import Organization from "glesys-controlpanel/models/organization";

@forbidExtraArgs
export default class SidebarOrganizationAdministration extends Component {
  @service tour;
  @service storage;

  @arg(instanceOf(Organization).isRequired) organization;

  @tracked _isOpen = null;

  get isOpen() {
    return this._isOpen;
  }

  set isOpen(value) {
    this._isOpen = value;
  }

  constructor() {
    super(...arguments);
    // JSON.parse because localStorage.getItem returns string and we want a boolean
    this.isOpen = this.organizationMenuStatus == null ? true : JSON.parse(this.organizationMenuStatus);
  }

  get classes() {
    return this.isOpen ? "pt-6 pb-6 border-gray-light" : "pt-4 pb-1.5 border-gray-lighter";
  }

  get menuClasses() {
    return this.isOpen ? "" : "hidden";
  }

  get organizationMenuStatus() {
    return this.storage.get("glesys:organization-menu-is-visible");
  }

  @action
  toggle() {
    this.isOpen = !this.isOpen;
    this.storage.set("glesys:organization-menu-is-visible", this.isOpen);
  }
}

import Route from "@ember/routing/route";

import { service } from "@ember/service";

export default class extends Route {
  @service session;
  @service abilities;
  @service router;

  model() {
    let { organization } = this.modelFor("organization");
    let user = this.session.currentUser;
    return {
      organization,
      user,
    };
  }

  redirect({ organization }) {
    if (this.abilities.cannot("access remote-hands for organization", organization)) {
      this.router.transitionTo("organization");
    }
  }
}

import { Ability } from "ember-can";
import { service } from "@ember/service";

export default class OrganizationAbility extends Ability {
  @service session;

  get organization() {
    return this.model;
  }

  get isOwnerOrStaff() {
    return this.session.currentUser?.isStaff || this.organization.isOwner;
  }

  get isIndividual() {
    return this.session.currentUser.isIndividual;
  }

  get canAccessCollaborators() {
    return this.isOwnerOrStaff;
  }

  get canAccessInvoices() {
    return this.isOwnerOrStaff || this.organization.canAccessBilling;
  }

  get canAccessSettings() {
    return this.isOwnerOrStaff;
  }

  get canCreateProject() {
    return this.isOwnerOrStaff && this.isIndividual;
  }

  get canCreate() {
    return this.isIndividual;
  }

  get hasRemoteHandsFeatureFlag() {
    return this.model.featureFlags?.includes("remote-hands");
  }

  get canAccessSupportChallenge() {
    return (
      this.model?.featureFlags?.includes("support-challenge") ||
      this.session.currentUser.username.endsWith("@glesys.se")
    );
  }

  get canAccessRemoteHands() {
    return this.hasRemoteHandsFeatureFlag && this.isIndividual;
  }

  get canAccessBetaFeatures() {
    return this.model.featureFlags?.includes("beta") || this.session.currentUser.id.endsWith("@glesys.se");
  }

  get canAccessNetworkCircuits() {
    return this.model.featureFlags?.includes("network-circuits");
  }
}

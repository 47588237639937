import { Ability } from "ember-can";

export default class NetworkAdapterAbility extends Ability {
  get networkAdapter() {
    return this.model;
  }

  get server() {
    return this.networkAdapter.server;
  }

  get project() {
    return this.server?.get("project");
  }

  get canDelete() {
    return this.canEdit && this.server?.get("isVMware");
  }

  get canEdit() {
    return this.project?.get("hasWriteAccess") && this.model.belongsTo("network").value();
  }

  get canEditNetwork() {
    return this.canDelete;
  }
}

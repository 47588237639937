import { service } from "@ember/service";
import Route from "@ember/routing/route";

export default class OrganizationNextInvoiceRoute extends Route {
  @service ajax;

  buildRouteInfoMetadata() {
    return { jumpable: true };
  }

  model() {
    let {
      organization: { id: organizationID },
    } = this.modelFor("organization");
    return this.ajax.request(`/io.php/organizations/${organizationID}/invoice/next`);
  }
}

import Helper from "@ember/component/helper";
import { getOwner } from "@ember/application";
import { assert } from "@ember/debug";

export default class ServiceHelper extends Helper {
  compute([serviceName, propertyName]) {
    let service = getOwner(this).lookup(`service:${serviceName}`);
    assert(`The service '${serviceName}' does not exist`, service);

    if (!propertyName) {
      return service;
    }

    let property = service[propertyName];

    assert(`'${propertyName}' does not exist on '${serviceName}'`, property !== undefined);

    if (typeof property === "function") {
      return property.bind(service);
    }

    return property;
  }
}

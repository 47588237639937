import { helper } from "@ember/component/helper";

export function colors(color) {
  switch (color[0]) {
    case "green":
      return "bg-ocean-green-lightest text-ocean-green border border-ocean-green-lightest";
    case "powder":
      return "bg-powder-blue-lightest text-powder-blue-darker border border-powder-blue-lightest";
    case "blue":
      return "bg-glesys-blue text-white border border-glesys-blue";
    case "orange":
      return "bg-orange-lighter text-orange-dark border border-orange-lighter";
    case "red":
      return "bg-red-lightest text-red border border-red-lightest";
    case "pink":
      return "bg-pink-lightest text-pink-dark border border-pink-lightest";
    case "gray":
    default:
      return "bg-gray-lighter text-gray-dark border border-gray-lighter";
  }
}

export default helper(colors);

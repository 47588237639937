import ApplicationSerializer from "./application";

export default class ArchiveArgumentSerializer extends ApplicationSerializer {
  attrs = {
    size: "archivesize",
  };

  normalize(modelClass, resourceHash, prop) {
    resourceHash.id = crypto.randomUUID();

    return super.normalize(modelClass, resourceHash, prop);
  }
}

import Component from "@glimmer/component";
import { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";
import { task } from "ember-concurrency";

export default class ModalsKvmNetworkAdapterEdit extends Component {
  @service modal;

  @tracked name = this.networkAdapter.name;

  get networkAdapter() {
    return this.args.params.networkAdapter;
  }

  get task() {
    return this.args.params.task;
  }

  submitTask = task(async () => {
    let task = this.task;
    let name = this.name;

    await task.perform({ name });

    this.modal.close();
  });
}

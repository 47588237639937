import Controller from "@ember/controller";
import { task } from "ember-concurrency";

export default class LoadBalancerErrorsController extends Controller {
  get loadBalancer() {
    return this.model.loadBalancer;
  }

  loadData = task(async () => {
    let errors = await this.loadBalancer.get("loadBalancerErrors");
    return errors.slice().sort((a, b) => b.id.localeCompare(a.id));
  });
}

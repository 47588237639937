import ApplicationSerializer from "./application";

export default class UserSerializer extends ApplicationSerializer {
  fakeRestFormat = false;
  primaryKey = "username";

  attrs = {
    createdAt: "createdat",
    twoFactorMethod: "twofactormethod",
  };

  normalize(modelClass, resourceHash, prop) {
    resourceHash.links = {
      organizations: "/io.php/organizations",
    };

    return super.normalize(modelClass, resourceHash, prop);
  }
}

import Model, { attr, belongsTo, hasMany } from "@ember-data/model";
import { tracked } from "@glimmer/tracking";

export default class LoadBalancerListenerModel extends Model {
  @attr mode;
  @attr name;
  @attr port;
  @attr status;
  @attr("yesno") stickysessions;
  @attr certificate;
  @attr("number") maxConnections;
  @attr("milliseconds-as-seconds") clientTimeout;
  @attr("milliseconds-as-seconds") connectTimeout;
  @attr("milliseconds-as-seconds") responseTimeout;

  @belongsTo("load-balancer", { async: false, inverse: "listeners" }) loadBalancer;
  @hasMany("load-balancer-target", { async: false, inverse: "listener" }) targets;

  @tracked metrics;

  fetchTargetStatus() {
    let { store } = this;
    let adapter = store.adapterFor(this.constructor.modelName);

    return adapter.fetchTargetStatus(this).then((loadBalancerTargets) => {
      this.store.pushPayload({ loadBalancerTargets });
      return this;
    });
  }

  get dirtyTargets() {
    return this.hasMany("targets")
      .value()
      ?.filter((target) => target.isDirty);
  }

  get hasDirtyTargets() {
    return this.dirtyTargets.length > 0;
  }

  get isDirty() {
    return this.hasDirtyAttributes || this.isDeleted || this.hasDirtyTargets;
  }
}

import Service, { service } from "@ember/service";

export default class AjaxService extends Service {
  @service http;

  request(url) {
    return this.http.request({ method: "GET", url }).then((response) => response.content);
  }

  post(url, data) {
    let request = { method: "POST", url };
    if (data?.data) {
      request.body = data.data;
    }
    return this.http.request(request).then((response) => response.content);
  }

  put(url, data) {
    let request = { method: "PUT", url };
    if (data?.data) {
      request.body = data.data;
    }
    return this.http.request(request).then((response) => response.content);
  }

  del(url) {
    return this.http.request({ method: "DELETE", url }).then((response) => response.content);
  }
}

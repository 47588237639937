import ApplicationSerializer from "./application";

export default class ApiKeySerializer extends ApplicationSerializer {
  primaryKey = "apikey";

  normalize(modelClass, resourceHash, prop) {
    resourceHash.allowedhosts = resourceHash.allowedhosts ?? [];

    return super.normalize(modelClass, resourceHash, prop);
  }
}

import { hash } from "rsvp";
import Route from "@ember/routing/route";

export default class ServersRoute extends Route {
  buildRouteInfoMetadata() {
    return { jumpable: true };
  }

  model() {
    let { project } = this.modelFor("organization");
    return hash({
      servers: project.get("servers"),
      project,
    });
  }
}

import { service } from "@ember/service";
import { action } from "@ember/object";
import Component from "@glimmer/component";

export default class HelpDropdown extends Component {
  @service help;
  @service modal;
  @service metrics;
  @service intl;

  get topics() {
    let topics = this.help.topicsForCurrentRoute;
    return topics.length === 0
      ? []
      : [
          {
            groupName: this.intl.t("component.help-dropdown.header"),
            items: topics,
          },
        ];
  }

  get disabled() {
    return this.topics.length === 0;
  }

  @action
  showTopic(topic) {
    this.metrics.trackEvent("GoogleAnalytics", {
      category: "open-help",
      action: topic.key,
      label: topic.title,
    });

    this.modal.open("modals/help-topic", { topic });
  }
}

import Ember from "ember";
import Service, { service } from "@ember/service";
import { action } from "@ember/object";
import { isPresent } from "@ember/utils";
import { registerDestructor } from "@ember/destroyable";

import AdapterError, { AbortError, TimeoutError, UnauthorizedError } from "@ember-data/adapter/error";

export class SilentError extends Error {}

export default class ErrorHandlerService extends Service {
  @service session;
  @service intl;
  @service notification;

  originalOnError = null;

  add(error) {
    this.notification.add("error", "There was an error.", error);
  }

  throw(error) {
    throw new SilentError(error);
  }

  handleError(error) {
    let isBadRequestError = error.response?.status >= 400 && error.response?.status < 500;
    let isServerError = error.response?.status >= 500 && error.response?.status < 600;
    let isUnauthorizedError = error instanceof UnauthorizedError || error.response?.status === 401;
    let isAbortError = error instanceof AbortError || (error instanceof DOMException && error.name === "AbortError");
    let isAdapterError = error instanceof AdapterError;
    let isTimeoutError = error instanceof TimeoutError;
    let isNetworkRequestFailedError =
      error instanceof TypeError && (error.message === "Network request failed" || error.message === "Failed to fetch");
    let isSilentError = error instanceof SilentError;
    let isForbiddenError = error.response?.status === 403;

    switch (true) {
      case isUnauthorizedError: {
        // eslint-disable-next-line no-console
        console.log("AJAX request unauthorized. Logging out.");
        this.session.close.perform();
        break;
      }
      case isTimeoutError: {
        // eslint-disable-next-line no-console
        console.log("AJAX request timed out. Logging out.");
        this.session.close.perform("logout.timeout");
        break;
      }
      case isAbortError: {
        // eslint-disable-next-line no-console
        console.log("AJAX request aborted");
        break;
      }
      case isNetworkRequestFailedError: {
        // eslint-disable-next-line no-console
        console.log("Network request failed");
        break;
      }
      case isForbiddenError: {
        this.add(
          error?.content?.error?.message ||
            error?.payload?.error?.message ||
            this.intl.t("component.error-handler.forbidden").toString(),
        );
        break;
      }
      case isAdapterError: {
        // NOTE: A '0' status is usually returned when an Ember Data request
        // is being aborted – when the user reloads the page for example. If
        // we encounter such an error, ignore it.

        let detail = error?.errors?.at(0)?.detail || error?.errors?.at(0)?.title;
        let status = error?.errors?.at(0)?.status;

        if (detail && status != 404) {
          this.add(detail);
        }

        break;
      }
      case isBadRequestError || isServerError: {
        const errorMessage = [error?.content?.error?.message, error?.payload?.error?.message, error?.message].find(
          (message) => isPresent(message),
        );

        if (errorMessage) {
          this.add(errorMessage);
        }
        break;
      }
      case isSilentError: {
        let errorMessage = error?.message;
        if (isPresent(errorMessage)) {
          this.add(errorMessage);
        }
        break;
      }
      default: {
        if (this.originalOnError) {
          this.originalOnError(error);

          // eslint-disable-next-line no-console
          console.error(error);
        } else {
          throw error;
        }
      }
    }
  }

  @action
  handleRejection(error) {
    error.preventDefault();
    this.handleError(error.reason);
  }

  setupErrorHandling() {
    this.originalOnError = this.originalOnError || Ember.onerror;
    Ember.onerror = (error) => this.handleError(error);
    window.addEventListener("unhandledrejection", this.handleRejection);

    registerDestructor(this, () => {
      window.removeEventListener("unhandledrejection", this.handleRejection);
    });
  }
}

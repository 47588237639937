import Controller from "@ember/controller";
import { task } from "ember-concurrency";
import { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";

export default class PrivateNetworksDetailsController extends Controller {
  @service store;
  @service http;

  @tracked billing;

  get privateNetwork() {
    return this.model.privateNetwork;
  }

  get dataCenters() {
    return this.store.peekAll("data-center").filter((dataCenter) => dataCenter.hasPrivateNetworkSupport);
  }

  loadCost = task(async () => {
    this.billing = await this.http.request({
      url: `/io.php/privatenetwork/estimatedcost`,
      method: "POST",
      body: {
        projectkey: this.privateNetwork.project.id,
        privatenetworkid: this.privateNetwork.id,
      },
    });
  });

  createSegmentTask = task(async (properties) => {
    let segment = this.store.createRecord("private-network-segment", {
      privateNetwork: this.privateNetwork,
      ...properties,
      platform: properties.platform.toLowerCase(),
    });

    try {
      await segment.save({ adapterOptions: { context: this.privateNetwork.project.id } });
    } catch (error) {
      segment.unloadRecord();
      throw error;
    }
  });
}

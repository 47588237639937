import { action } from "@ember/object";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { restartableTask, task, timeout } from "ember-concurrency";
import { htmlSafe } from "@ember/template";
import { service } from "@ember/service";
import ENV from "glesys-controlpanel/config/environment";

export default class DnsHostnameSelectorComponent extends Component {
  @service intl;
  @service router;

  get project() {
    return this.args.project;
  }

  get default() {
    return this.args.default;
  }

  @tracked domains = [];
  @tracked host;
  @tracked state = "unknown";
  @tracked domain;
  @tracked enableRootDomain = this.args.enableRootDomain || false;
  @tracked enableSubdomain = this.args.enableSubdomain || false;

  @action
  didInsert() {
    this.fetchDomains.perform();

    if (this.default) {
      this.host = this.default;
      this.checkIfHostIsAvailableForCurrentDomain.perform();
    }
  }

  get isRootDomain() {
    return this.enableRootDomain && !this.enableSubdomain;
  }

  get shouldShowSubDomainInput() {
    return !this.enableRootDomain || this.enableSubdomain;
  }

  @action
  hostChanged(event) {
    this.host = event.currentTarget.value;
    this.checkIfHostIsAvailableForCurrentDomain.perform();
  }

  @action
  domainChanged(domain) {
    this.domain = domain;
    this.checkIfHostIsAvailableForCurrentDomain.perform();
  }

  @action
  enableSubdomainChange(event) {
    this.enableSubdomain = event.target.checked;
    this.domainChanged(this.domain);
  }

  fetchDomains = task(async () => {
    this.domains = await this.project.hasMany("dnsDomains").reload();
  });

  checkIfHostIsAvailableForCurrentDomain = restartableTask(async () => {
    this.state = "unknown";
    let host = [this.host];

    if (ENV.environment !== "test") {
      await timeout(1000);
    }

    if (this.domain) {
      let records = await this.domain.hasMany("records").reload();
      if (this.isRootDomain) {
        host = ["@", "www"];
      }

      let existingRecords = records.filter((record) => {
        return ["A", "AAAA", "CNAME", "URL"].includes(record.type) && host.includes(record.host);
      });

      this.state = existingRecords.length ? "exists" : "ok";
    }

    if (this.args.onChange) {
      if (this.state === "ok") {
        this.args.onChange(this.isRootDomain ? null : this.host, this.domain);
      } else {
        this.args.onChange(null, null);
      }
    }
  });

  get linkToDns() {
    if (!this.domain) {
      return "";
    }

    let url = this.router.urlFor("dns.domain", this.domain);
    let linkText = this.intl.t("component.dns-hostname-selector.dns-editor");

    return htmlSafe(`<a href="${url}" target="_blank" rel="noopener noreferrer">${linkText}</a>`);
  }

  get url() {
    let url = this.isRootDomain ? this.domain?.id : `${this.host}.${this.domain?.id}`;
    return `https://${url}`;
  }
}

import { service } from "@ember/service";
import Route from "@ember/routing/route";
import RSVP from "rsvp";
import { action } from "@ember/object";
import { restartableTask, timeout } from "ember-concurrency";
import ENV from "glesys-controlpanel/config/environment";

export default class DnsDomainRoute extends Route {
  @service ajax;
  @service router;
  @service store;

  model(params) {
    let { project, domains } = this.modelFor("dns");
    let projectId = project.get("id");
    let domainId = params.dns_domain_id;
    let domain = domains.find((domain) => domain.id === domainId);

    let prices = this.ajax.request(`/io.php/projects/${projectId}/domain/pricelist`);

    return RSVP.hash({
      project,
      domain,
      organization: project.get("organization"),
      prices,
      servers: project.get("servers"),
    });
  }

  setupController() {
    super.setupController(...arguments);
    this.fetchDetailsLoopTask.perform();
  }

  resetController() {
    super.resetController(...arguments);
    this.fetchDetailsLoopTask.cancelAll();
  }

  fetchDetails(model) {
    let { project, domain } = model;
    let url = `/io.php/projects/${project.id}/dns/domains/${domain.id}`;

    this.ajax
      .request(url)
      .then((data) => {
        this.store.pushPayload("dns-domain", { "dns-domain": { id: domain.id, ...data } });
      })
      .catch((error) => {
        if (error.status === 404) {
          this.router.transitionTo("dns.overview");
        }
      });
  }

  @action
  reloadDetails() {
    let model = this.modelFor(this.routeName);
    this.fetchDetails(model);
  }

  fetchDetailsLoopTask = restartableTask(async () => {
    // eslint-disable-next-line no-constant-condition
    while (true) {
      this.reloadDetails();

      if (ENV.environment === "test") {
        return;
      }

      await timeout(5 * 60 * 1000);
    }
  });
}

import Component from "@glimmer/component";
import { task } from "ember-concurrency";
import { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";

export default class ModalsProjectRename extends Component {
  @service modal;

  get project() {
    return this.args.params.project;
  }

  get task() {
    return this.args.params.task;
  }

  name = this.project.name;
  @tracked access = this.apiKey.access;

  submitTask = task(async () => {
    await this.task.perform(this.name);

    this.modal.close();
  });
}

import Service from "@ember/service";
import ENV from "glesys-controlpanel/config/environment";

export default class StorageService extends Service {
  #fakeCache = new Map();

  shouldUseFake = ENV.environment === "test";

  clear() {
    this.#fakeCache = new Map();
  }

  get(key) {
    try {
      return this.shouldUseFake ? this.#fakeCache.get(key) : localStorage.getItem(key);
    } catch (_) {
      return null;
    }
  }

  set(key, value) {
    try {
      this.shouldUseFake ? this.#fakeCache.set(key, value) : localStorage.setItem(key, value);
    } catch (_) {
      // Fail silently
    }
  }

  remove(key) {
    try {
      this.shouldUseFake ? this.#fakeCache.delete(key) : localStorage.removeItem(key);
    } catch (_) {
      // Fail silently
    }
  }
}

import Component from "@glimmer/component";
import { action } from "@ember/object";
import { task } from "ember-concurrency";
import { service } from "@ember/service";
import dayjs from "dayjs";

export default class ModalsKvmConfirmRestore extends Component {
  @service modal;
  @service ajax;

  dataCenter = this.server.datacenter;

  get availableDataCenters() {
    return this.args.params.dataCenters
      .filter((dataCenter) => {
        return dataCenter.hasKvmSupport;
      })
      .map((dataCenter) => {
        return { value: dataCenter.name };
      });
  }

  get server() {
    return this.args.params.server;
  }

  get project() {
    return this.args.params.project;
  }

  get backup() {
    return this.args.params.backup;
  }

  get restoreBackupTask() {
    return this.args.params.restoreBackupTask;
  }

  get backupCreatedAtFormatted() {
    return dayjs(this.backup.createdAt).format("YYYY-MM-DD HH:mm");
  }

  @action
  restoreServer() {
    this.restoreServerTask.perform();
  }

  @action
  selectDataCenter(dataCenter) {
    this.dataCenter = dataCenter.value;
  }

  getKVMServerCostTask = task(async () => {
    let url = `/io.php/projects/${this.project.id}/vps/servers/server/costs`;

    let data = {
      config: {
        cpucores: this.server.cpucores,
        disksize: this.server.disksize,
        memorysize: this.server.memorysize,
        bandwidth: this.server.bandwidth,
        numberofbackupimagestokeep: 0, // backup won't be activated on new server
      },
    };

    try {
      let response = await this.ajax.post(url, { data });
      return {
        price: response.billing.price,
        total: response.billing.total,
      };
    } catch (_) {
      return {
        price: null,
      };
    }
  });

  restoreServerTask = task(async () => {
    await this.restoreBackupTask.perform(this.backup, this.dataCenter);
    this.modal.close();
  });
}

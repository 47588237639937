import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";

import { action } from "@ember/object";
import { service } from "@ember/service";

import { Task, task } from "ember-concurrency";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { array, shape, instanceOf } from "prop-types";
import dnsDomain from "glesys-controlpanel/models/dns-domain";

@forbidExtraArgs
export default class ModalsDnsRenewDomain extends Component {
  @service modal;

  @arg(
    shape({
      task: instanceOf(Task).isRequired,
      domain: instanceOf(dnsDomain).isRequired,
      prices: array.isRequired,
    }).isRequired,
  )
  params;

  get task() {
    return this.params.task;
  }

  get domain() {
    return this.params.domain;
  }

  get prices() {
    return this.params.prices;
  }

  @tracked selectedYear = 1;

  @action
  selectYear(year) {
    this.selectedYear = year.years;
  }

  submitTask = task(async () => {
    await this.task.perform(this.selectedYear);
    this.modal.close();
  });
}

import { template } from "@ember/template-compiler";
import styles from "./styles.css";
export default template(`
  <div data-test-button-group ...attributes>
    <div class="{{styles.root}} inline-flex items-stretch rounded border border-gray-dark">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});

import Route from "@ember/routing/route";

export default class JitsiRoute extends Route {
  model() {
    return this.modelFor("organization");
  }

  buildRouteInfoMetadata() {
    return { jumpable: true };
  }

  setupController(controller) {
    super.setupController(...arguments);

    controller.submit.cancelAll({ resetState: true });
    controller.resetProperties();
  }
}

import { isHTMLSafe } from "@ember/template";
import { isNone, typeOf } from "@ember/utils";
import { checkPropTypes } from "prop-types";

export default function wrapValidator(validator, typeName, typeChecker = null) {
  return Object.assign(validator.bind(), {
    typeName,
    typeChecker,
    isRequired: Object.assign(validator.isRequired.bind(), {
      typeName,
      typeChecker,
      typeRequired: true,
    }),
  });
}

const isRequired = function (validator) {
  return function (props, propName, componentName, ...rest) {
    let propValue = props[propName];
    if (isNone(propValue)) {
      return new Error(
        `The prop \`${propName}\` is marked as required in \`${componentName}\`, but its value is \`${propValue}\``,
      );
    }

    return validator(props, propName, componentName, ...rest);
  };
};

const PropTypeError = (props, propName, componentName, expectedType) =>
  new Error(
    `Invalid prop \`${propName}\` of type \`${typeOf(
      props[propName],
    )}\` supplied to \`${componentName}\`, expected \`${expectedType}\``,
  );

export const stringable = (function () {
  const validator = function (props, propName, componentName) {
    let propValue = props[propName];
    if (isNone(propValue) || typeOf(propValue) === "string" || isHTMLSafe(propValue)) {
      return null;
    }

    return PropTypeError(props, propName, componentName, "stringable");
  };

  validator.isRequired = isRequired(validator);

  return wrapValidator(validator, "stringable");
})();

export const emberDataArrayOf = function (options) {
  let check = options;

  const validator = function (props, propName, componentName) {
    let propValue = props[propName];

    propValue.forEach((val) => {
      checkPropTypes({ [propName]: check }, { [propName]: val }, "prop", componentName);
    });
  };

  validator.isRequired = isRequired(validator);

  return wrapValidator(validator, "emberDataArrayOf", options);
};

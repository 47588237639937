import hbs from "htmlbars-inline-precompile";

export default {
  title: "GravatarImage",
  component: "GravatarImage",
};

export const Default = (args) => ({
  template: hbs`
    <div class="p-16">
      <GravatarImage
        @email={{this.email}}
        @fallback={{this.fallback}}
        @size={{this.size}}
      />
    </div>
  `,
  context: args,
});

Default.args = {
  email: "",
  fallback: "blank",
  size: 128,
};
